import DetalleEditorial from "components/Interfester/DetalleEditorial"
import VideoTop from "components/Interfester/VideoTop"
import user1 from "../../assets/images/user_without_pic.png"
import { Fragment } from "react"
import { MetaTags } from "react-meta-tags"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import proxInterfester from "../../assets/images/prox_interfester.svg"
import Breadcrumbs from "../../components/Common/Breadcrumb2"

const Editoriales = () => {
  return (
    <Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Editoriales | La Academia Fester</title>
        </MetaTags>
        <Container fluid>
          <Card>
            <CardBody>
              <Row>
                <Col sm={4}>
                  <h4>Editoriales</h4>
                </Col>
                <Col sm={8}>
                  <Breadcrumbs
                    breadcrumbItems={[
                      {
                        key: "start",
                        title: "La Academia Fester",
                        link: "/Start",
                      },
                      { key: "editoriales", title: "Editoriales" },
                    ]}
                  />
                </Col>
                <Col md={12}>
                  <img
                    src={proxInterfester}
                    alt="Próximamente"
                    className="img-fluid"
                  />
                </Col>
              </Row>
              {/*<h1 className="mb-3">La editorial</h1>
              <VideoTop />
              <Container>
                <DetalleEditorial />
                <div className="rounded mt-4 mb-4">
                  <h3>Editoriales que te pueden interesar</h3>
                  <Row>
                    <Col md={4}>
                      <hr />
                      <Row className="element">
                        <Col xs={3}>
                          <img
                            className="rounded-circle img-fluid"
                            src={user1}
                            alt="Header Avatar"
                          />
                        </Col>
                        <Col xs={9}>
                          <h4>Verónica García</h4>
                          <p className="main">
                            La nueva plataforma web revoluciona la forma en que
                            interactuamos en línea, fusionando innovación y
                            accessibilidad. Su diseño intuitivo y
                            características avanzadas prometen redefinir las
                            expectativas de los usuarios en el mundo digital.
                          </p>
                          <p className="info">Seguir @VeronicaFester</p>
                          <p className="date">Sep 1, 2023</p>
                          <div>
                            <button
                              type="button"
                              className="btn btn-rounded btn-secondary"
                            >
                              <i className="fa fa-heart"></i> 5.3k
                            </button>
                            &nbsp;&nbsp;
                            <button
                              type="button"
                              className="btn btn-primary btn-rounded pt-2"
                            >
                              <i className="fa fa-play-circle"></i> Reproducir
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={4}>
                      <hr />
                      <Row className="element">
                        <Col xs={3}>
                          <img
                            className="rounded-circle img-fluid"
                            src={user1}
                            alt="Header Avatar"
                          />
                        </Col>
                        <Col xs={9}>
                          <h4>Atzín Rogel</h4>
                          <p>
                            La digitalización educativa no solo ha traspasado
                            fronteras geográficas, sino también pedagógicas. Las
                            plataformas educativas actuales empoderan a
                            estudiantes y docentes, permitiendo un aprendizaje
                            personalizado y a demanda.
                          </p>
                          <p className="info">Seguir @AtzinRogel</p>
                          <p className="date">Sep 1, 2023</p>
                          <div>
                            <button
                              type="button"
                              className="btn btn-rounded btn-secondary"
                            >
                              <i className="fa fa-heart"></i> 5.3k
                            </button>
                            &nbsp;&nbsp;
                            <button
                              type="button"
                              className="btn btn-primary btn-rounded pt-2"
                            >
                              <i className="fa fa-play-circle"></i> Reproducir
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={4}>
                      <hr />
                      <Row className="element">
                        <Col xs={3}>
                          <img
                            className="rounded-circle img-fluid"
                            src={user1}
                            alt="Header Avatar"
                          />
                        </Col>
                        <Col xs={9}>
                          <h4>Odilón de la Paz</h4>
                          <p>
                            El auge de los vehículos eléctricos señala el inicio
                            de una era más sostenible en movilidad. Con
                            tecnologías avanzadas y un menor imácto ambiental,
                            estamos presenciando la reinvención del transporte
                            moderno.
                          </p>
                          <p className="info">Seguir @OdilondelaPaz</p>
                          <p className="date">Ago 30, 2023</p>
                          <div>
                            <button
                              type="button"
                              className="btn btn-rounded btn-secondary"
                            >
                              <i className="fa fa-heart"></i> 5.3k
                            </button>
                            &nbsp;&nbsp;
                            <button
                              type="button"
                              className="btn btn-primary btn-rounded pt-2"
                            >
                              <i className="fa fa-play-circle"></i> Reproducir
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
  </Container>*/}
            </CardBody>
          </Card>
        </Container>
      </div>
    </Fragment>
  )
}

export default Editoriales
