import React, { useEffect, useState, Fragment } from "react"
import classnames from "classnames"
import { useSelector, useDispatch } from "react-redux"
import StackedColumnChart from "../../pages/Dashboard/StackedColumnChart"
import { getChartsData as onGetChartsData } from "../../store/actions"
import { Row, Col, Card, CardBody } from "reactstrap"

import WelcomeN8 from "../../pages/Dashboard/WelcomeN8"

import {
  getCourseKnowledges,
  getSkills,
  getCoursesByUser,
} from "../../store/actions"
import CoursesByStatus from "./CoursesByStatus"
import moment from "common/moment_i18n"

const N8 = props => {
  const [activeTabHab, setActiveTabHab] = useState("1")
  const [completedAvg, setCompletedAvg] = useState(0)

  const dispatch = useDispatch()

  const { knowledges, skills, user, courses, loading } = useSelector(store => ({
    knowledges: store.CourseKnowledgesList.courseKnowledges,
    skills: store.SkillsList.skills,
    user: store.Profile.user,
    courses: store.CoursesList.courses,
    loading: store.CoursesList.loading,
  }))

  const idUser = user?.idUsuario ?? 0

  useEffect(() => {
    dispatch(getCourseKnowledges())
    dispatch(getSkills())
    dispatch(getCoursesByUser(idUser))
  }, [idUser])

  const getCompleteCourses = () => {
    const completed =
      courses !== undefined
        ? courses.filter(
            course =>
              course.estatusCurso === 2 && course.CourseParticipant?.aproved
          )
        : []

    return completed.length
  }

  useEffect(() => {
    const allCourses =
      courses !== undefined
        ? courses.filter(course => course.estatusCurso === 2)
        : []
    const completedCourses = getCompleteCourses()
    if (allCourses.length <= 0 || completedCourses <= 0) {
      setCompletedAvg(0)
    }
    setCompletedAvg((completedCourses / allCourses.length) * 100)

    return () => {
      setCompletedAvg(0)
    }
  }, [courses])

  return (
    <Fragment>
      <Row className="mb-2">
        <Col md={12}>
          {/* <h4 className="mb-4">
            Tus avances en {moment(Date()).format("MMMM YYYY")}
          </h4> */}
          <Row>
            <Col md={3}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <h3>Cursos completados</h3>
                    {/* <div>
                      <div>
                        <span
                          className="badge bg-pill bg-success"
                          style={{ fontSize: "16px", borderRadius: "14px" }}
                        >
                          <i className="fa fa-arrow-up"></i> 19%
                        </span>
                      </div>
                    </div> */}
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="mt-3">
                      <span style={{ fontSize: "32px", fontWeight: "bold" }}>
                        {getCompleteCourses()}
                      </span>{" "}
                      <span style={{ fontSize: "18px" }}>cursos</span>
                    </div>
                    {/* <small
                      className="text-right"
                      style={{ textAlign: "right" }}
                    >
                      Por arriba del promedio
                      <br />
                      de participantes
                    </small> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <h3>Insignias ganadas</h3>
                    <div>
                      <div>
                        <span
                          className="badge bg-pill bg-success"
                          style={{ fontSize: "16px", borderRadius: "14px" }}
                        >
                          0
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <div className="mt-4">
                      <span
                        className="badge bg-pill bg-warning"
                        style={{
                          fontSize: "14px",
                          borderRadius: "14px",
                          padding: "6px",
                        }}
                      >
                        <i className="fa fa-trophy"></i> No se han ganado
                        insignias
                      </span>
                    </div>
                    {/* <small
                      className="text-right"
                      style={{ textAlign: "right" }}
                    >
                      Más que el mes anterior
                    </small> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <h3>Tiempo dedicado</h3>
                    {/* <div>
                      <div>
                        <span
                          className="badge bg-pill bg-success"
                          style={{ fontSize: "16px", borderRadius: "14px" }}
                        >
                          <i className="fa fa-arrow-up"></i> 2.1%
                        </span>
                      </div>
                    </div> */}
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="mt-3">
                      <span style={{ fontSize: "32px", fontWeight: "bold" }}>
                        0 hr. 0 min.
                      </span>
                    </div>
                    {/* <small
                      className="text-right"
                      style={{ textAlign: "right" }}
                    >
                      Mayor que el
                      <br />
                      mes anterior
                    </small> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <h3>Promedios en capacitación</h3>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span
                      className="badge bg-pill bg-warning"
                      style={{ fontSize: "14px", borderRadius: "14px" }}
                    >
                      <i className="fa fa-arrow-up"></i> 0 min/curso
                    </span>
                    <span className="pt-1">Sin iniciar</span>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <span
                      className="badge bg-pill bg-warning"
                      style={{ fontSize: "14px", borderRadius: "14px" }}
                    >
                      <i className="fa fa-minus"></i> 0 veces cursado/curso
                    </span>
                    <span className="pt-1">Sin iniciar</span>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <WelcomeN8 completedAvg={completedAvg} />
          {/* <Card>
            <CardBody>
              <Nav className="icon-tab nav-justified">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTabHab === "1",
                    })}
                    onClick={() => {
                      toggleIconCustomHab("1")
                    }}
                  >
                    <span className="d-sm-block">Habilidades adquiridas</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTabHab === "2",
                    })}
                    onClick={() => {
                      toggleIconCustomHab("2")
                    }}
                  >
                    <span className="d-sm-block">Conocimientos adquiridos</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTabHab} className="text-muted mt-4">
                <TabPane tabId="1">
                  <div className="">
                    {skills.map((skill, index) =>
                      index % 3 == 0 ? (
                        <span
                          key={`skill-${skill.idAbility}`}
                          className={`badge bg-pill bg-${getColor(
                            index
                          )} pt-2 m-1`}
                          style={{ fontSize: "16px", borderRadius: "14px" }}
                        >
                          {skill.ability}
                        </span>
                      ) : (
                        <span></span>
                      )
                    )}
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="">
                    {knowledges.map((knowledge, index) =>
                      index % 3 == 0 ? (
                        <span
                          key={`knowledge-${knowledge.idKnowledge}`}
                          className={`badge bg-pill bg-${getColor(
                            index
                          )} pt-2 m-1`}
                          style={{ fontSize: "16px", borderRadius: "14px" }}
                        >
                          {knowledge.knowledge}
                        </span>
                      ) : (
                        <span></span>
                      )
                    )}
                  </div>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card> */}
        </Col>
        <Col md={8}>
          <CoursesByStatus courses={courses} loading={loading} />
        </Col>
      </Row>
    </Fragment>
  )
}

export default N8
