import React from "react"
import { Card, CardBody, CardTitle, Col } from "reactstrap"

import { Link } from "react-router-dom"

// import images
import img1 from "../../assets/images/small/img-7.jpg"
import img2 from "../../assets/images/small/img-4.jpg"
import img3 from "../../assets/images/small/img-6.jpg"

const TopCities = () => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Comentarios populares</CardTitle>

          <div className="list-group list-group-flush">
            <Link to="#" className="list-group-item text-muted py-3 px-2">
              <div className="media align-items-center">
                <div className="me-3">
                  <img
                    src={img1}
                    alt=""
                    className="avatar-md h-auto d-block rounded"
                  />
                </div>
                <div className="media-body overflow-hidden">
                  <h5 className="font-size-13 text-truncate">
                    Impermeabilización en lluvias
                  </h5>
                  <p className="mb-0 text-truncate">10 de sep. 2021</p>
                </div>
              </div>
            </Link>

            <Link to="#" className="list-group-item text-muted py-3 px-2">
              <div className="media align-items-center">
                <div className="me-3">
                  <img
                    src={img2}
                    alt=""
                    className="avatar-md h-auto d-block rounded"
                  />
                </div>
                <div className="media-body overflow-hidden">
                  <h5 className="font-size-13 text-truncate">
                    Autonivelado y pisos
                  </h5>
                  <p className="mb-0 text-truncate">2 de oct 2021</p>
                </div>
              </div>
            </Link>

            <Link to="#" className="list-group-item text-muted py-3 px-2">
              <div className="media align-items-center">
                <div className="me-3">
                  <img
                    src={img3}
                    alt=""
                    className="avatar-md h-auto d-block rounded"
                  />
                </div>
                <div className="media-body overflow-hidden">
                  <h5 className="font-size-13 text-truncate">
                    Proyectos en equipos diferentes
                  </h5>
                  <p className="mb-0 text-truncate">23 de jul 2020</p>
                </div>
              </div>
            </Link>
          </div>

          <hr className="my-4" />

          <div>
            <p className="text-muted mb-1">Tags</p>

            <ul className="list-inline widget-tag">
              <li className="list-inline-item">
                <Link to="#" className="badge bg-light font-size-12 mt-2">
                  Pisos
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="#" className="badge bg-light font-size-12 mt-2">
                  Autonivelante
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="#" className="badge bg-light font-size-12 mt-2">
                  Sellador
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="#" className="badge bg-light font-size-12 mt-2">
                  Proyecto
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="#" className="badge bg-light font-size-12 mt-2">
                  Obras
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="#" className="badge bg-light font-size-12 mt-2">
                  Ventas
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="#" className="badge bg-light font-size-12 mt-2">
                  Certificados
                </Link>
              </li>
            </ul>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default TopCities
