import React, { Component, Fragment, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb2"
import SoftskillsCoursesForm from "components/SoftSkills/Courses/SoftskillsCoursesForm"
import { useDispatch, useSelector } from "react-redux"
import {
  editSoftskillCourseClean,
  getSoftskillsCourse,
  getSoftskillsCourseClean,
} from "store/actions"
import { useParams } from "react-router-dom/cjs/react-router-dom"

const SoftskillsEditCourse = props => {
  const { courseId } = useParams()

  const [course, setCourse] = useState(undefined)

  const { error, loading, done, coursePrev } = useSelector(state => ({
    error: state.SoftskillCourseGet.error,
    loading: state.SoftskillCourseGet.loading,
    coursePrev: state.SoftskillCourseGet.course,
    done: state.SoftskillCourseGet.done,
  }))

  const dispatch = useDispatch()

  useEffect(() => {
    if (courseId !== undefined) {
      dispatch(getSoftskillsCourse(courseId))
    }
    return () => {
      setCourse(undefined)
    }
  }, [courseId])

  useEffect(() => {
    if (coursePrev !== undefined) {
      setCourse(coursePrev)
      dispatch(getSoftskillsCourseClean())
    }
  }, [coursePrev])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Editar curso | Soft Skills</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Editar curso</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  { key: "dashboard", title: "Inicio", link: "/soft-skills" },
                  {
                    key: "courses",
                    title: "Cursos",
                    link: "/soft-skills/adm-courses",
                  },
                  { key: "add", title: "Nuevo curso" },
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {course !== undefined && (
                    <SoftskillsCoursesForm
                      onDone={() => {
                        dispatch(editSoftskillCourseClean())
                        props.history.push("/soft-skills/adm-courses")
                      }}
                      onCancel={() => {
                        dispatch(editSoftskillCourseClean())
                        props.history.push("/soft-skills/adm-courses")
                      }}
                      insert={false}
                      course={course}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

SoftskillsEditCourse.propTypes = {
  history: PropTypes.object,
  courseId: PropTypes.number,
}

export default withRouter(SoftskillsEditCourse)
