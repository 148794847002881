/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { ADD_IMAGE } from "./actionTypes"
import { apiError, addImageSuccess } from "./actions"

//Include Both Helper File with needed methods
import { addDocumentNewApi } from "../../../helpers/backend_helper"

function* add(action) {
  try {
    const response = yield call(addDocumentNewApi, action.payload.form)

    if (response === undefined)
      throw new Error("No se realizó la carga correctamente")
    if (response.length < 1)
      throw new Error("No se guardó el archivo correctamente")
    yield put(addImageSuccess(response.path))
  } catch (error) {
    console.log(error.message)
    yield put(apiError(error))
  }
}

function* imageAddSaga() {
  yield takeEvery(ADD_IMAGE, add)
}

export default imageAddSaga
