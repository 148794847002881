/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { GET_SOFTSKILLS_ABILITY } from "./actionTypes"
import {
  getSoftskillsAbilityError,
  getSoftskillsAbilitySuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { getSoftskillsAbility } from "../../../helpers/backend_helper"

function* get(action) {
  try {
    const response = yield call(getSoftskillsAbility, action.payload.id)
    if (response == undefined) throw new Error("API error")
    if (!response.success) throw new Error("Error al guardar al cliente")
    yield put(getSoftskillsAbilitySuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(getSoftskillsAbilityError(error.message))
  }
}

function* softskillAbilityGetSaga() {
  yield takeEvery(GET_SOFTSKILLS_ABILITY, get)
}

export default softskillAbilityGetSaga
