import {
  LIST_ALL_EVENTS,
  LIST_ALL_EVENTS_SUCCESS,
  LIST_ALL_EVENTS_ERROR,
  LIST_ALL_EVENTS_CLEAN,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  events: [],
}

const AllEventsList = (state = initialState, action) => {
  switch (action.type) {
    case LIST_ALL_EVENTS:
      state = {
        ...state,
        loading: true,
      }
      break
    case LIST_ALL_EVENTS_SUCCESS:
      state = {
        ...state,
        events: action.payload.events,
        loading: false,
        done: true,
      }
      break
    case LIST_ALL_EVENTS_ERROR:
      state = {
        ...state,
        error: action.payload,
        events: [],
        loading: false,
        done: false,
      }
      break
    case LIST_ALL_EVENTS_CLEAN:
      state = initialState
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default AllEventsList
