import DetalleEditorial from "components/Interfester/DetalleEditorial"
import VideoTop from "components/Interfester/VideoTop"
import user1 from "../../assets/images/user_without_pic.png"
import { Fragment, useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import proxInterfester from "../../assets/images/prox_interfester.svg"
import proxCursos from "../../assets/images/prox_cursos_presenciales.jpg"
import proxWebinar from "../../assets/images/prox_webinar.svg"
import { useDispatch, useSelector } from "react-redux"
import { getUsersByRole, listAllEvents, listLocations } from "store/actions"
import moment from "moment"
import EventsTree from "components/Interfester/EventsTree"

const Eventos = () => {
  const dispatch = useDispatch()
  const [availableEvents, setAvailableEvents] = useState([])

  const {
    error,
    loading,
    done,
    events,
    whoIAm,
    trashDone,
    usersByRole,
    locations,
  } = useSelector(state => ({
    error: state.AllEventsList.error,
    loading: state.AllEventsList.loading,
    events: state.AllEventsList.events,
    done: state.AllEventsList.done,
    whoIAm: state.Profile.user,
    trashDone: state.EventDelete.done,
    usersByRole: state.UsersList.usersByRole,
    locations: state.LocationsList.locations,
  }))

  const userId = whoIAm?.idUsuario ?? 0
  const profileId = whoIAm?.idRol ?? 0

  useEffect(() => {
    dispatch(getUsersByRole(2))
    dispatch(listLocations())
  }, [])

  useEffect(() => {
    if (usersByRole.length > 0 && locations.length > 0) {
      dispatch(listAllEvents())
    }
  }, [usersByRole, locations])

  useEffect(() => {
    if (done) {
      events.sort((a, b) => {
        return new Date(a.fechaInicio) - new Date(b.fechaInicio)
      })

      events.forEach(event => {
        const location = locations.find(
          location => location.locationId === event.locationId
        )

        const user = usersByRole.find(
          user => user.userId === event.responsibleUserId
        )

        event.locationName = location?.name ?? event.locationName
        event.responsibleUserName = user
          ? `${user.firstName} ${user.lastName}`
          : ""
      })

      setAvailableEvents(
        events.filter(event => {
          const now = moment(new Date())
          const endDate = moment(new Date(event.endDate))
          const diff = endDate.diff(now, "days")
          return event.status === 1 && diff >= -1
        })
      )
    }
  }, [events, done])

  return (
    <Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Eventos | La Academia Fester</title>
        </MetaTags>
        <Container fluid>
          <Card>
            <CardBody>
              <Row>
                <Col sm={4}>
                  <h3>Próximos eventos</h3>
                </Col>
                <Col sm={8}>
                  <Breadcrumbs
                    breadcrumbItems={[
                      {
                        key: "start",
                        title: "La Academia Fester",
                        link: "/Start",
                      },
                      { key: "eventos", title: "Eventos" },
                    ]}
                  />
                </Col>
                <Col sm={12}>
                  <p>
                    Cada mes, se celebran grandes eventos de Fester y sus
                    productos en las diferentes regiones del pais. Plantéese
                    asistir a alguno cerca suyo para conocer a más gente en la
                    comunidad, aprender sobre especificaciones y desarrollar
                    nuevas habilidades. También puede tomarlos en línea.
                  </p>
                </Col>
              </Row>
              <EventsTree events={availableEvents} />
            </CardBody>
          </Card>
        </Container>
      </div>
    </Fragment>
  )
}

export default Eventos
