import {
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_CLEAN,
  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_CLEAN,
  API_ERROR,
  ACTIVATE_DEACTIVATE_USER,
  ACTIVATE_DEACTIVATE_USER_SUCCESS,
  ACTIVATE_DEACTIVATE_USER_ERROR,
  ACTIVATE_DEACTIVATE_USER_CLEAN,
  UPDATE_ABOUT_ME,
  UPDATE_ABOUT_ME_SUCCESS,
  UPDATE_ABOUT_ME_ERROR,
  UPDATE_ABOUT_ME_CLEAN,
  UPDATE_PROFILE_PICTURE,
  UPDATE_PROFILE_PICTURE_SUCCESS,
  UPDATE_PROFILE_PICTURE_ERROR,
  UPDATE_PROFILE_PICTURE_CLEAN,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  UPDATE_PASSWORD_CLEAN,
  UPDATE_POSITION,
  UPDATE_POSITION_SUCCESS,
  UPDATE_POSITION_ERROR,
  UPDATE_POSITION_CLEAN,
} from "./actionTypes"

export const getUser = id => {
  return {
    type: GET_USER,
    payload: { id },
  }
}

export const getUserSuccess = user => {
  return {
    type: GET_USER_SUCCESS,
    payload: { user },
  }
}

export const getUserClean = () => {
  return {
    type: GET_USER_CLEAN,
    payload: {},
  }
}

export const editUser = form => ({
  type: EDIT_USER,
  payload: { form },
})

export const editUserSuccess = user => {
  return {
    type: EDIT_USER_SUCCESS,
    payload: { user },
  }
}

export const editUserClean = () => {
  return {
    type: EDIT_USER_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: { error },
  }
}

export const activateDeactivate = (userId, status, registerUserId) => {
  return {
    type: ACTIVATE_DEACTIVATE_USER,
    payload: { userId, registerUserId, status },
  }
}

export const activateDeactivateSuccess = () => {
  return {
    type: ACTIVATE_DEACTIVATE_USER_SUCCESS,
    payload: {},
  }
}

export const activateDeactivateError = error => {
  return {
    type: ACTIVATE_DEACTIVATE_USER_ERROR,
    payload: error,
  }
}

export const activateDeactivateClean = () => {
  return {
    type: ACTIVATE_DEACTIVATE_USER_CLEAN,
    payload: {},
  }
}

export const updateProfilePicture = (idUsuario, form) => {
  return {
    type: UPDATE_PROFILE_PICTURE,
    payload: { idUsuario, form },
  }
}

export const updateProfilePictureSuccess = () => {
  return {
    type: UPDATE_PROFILE_PICTURE_SUCCESS,
    payload: {},
  }
}

export const updateProfilePictureError = error => {
  return {
    type: UPDATE_PROFILE_PICTURE_ERROR,
    payload: error,
  }
}

export const updateProfilePictureClean = () => {
  return {
    type: UPDATE_PROFILE_PICTURE_CLEAN,
    payload: {},
  }
}

export const updatePassword = (
  password,
  newPassword,
  registerUserId,
  userId
) => {
  return {
    type: UPDATE_PASSWORD,
    payload: { password, newPassword, registerUserId, userId },
  }
}

export const updatePasswordSuccess = () => {
  return {
    type: UPDATE_PASSWORD_SUCCESS,
    payload: {},
  }
}

export const updatePasswordError = error => {
  return {
    type: UPDATE_PASSWORD_ERROR,
    payload: error,
  }
}

export const updatePasswordClean = () => {
  return {
    type: UPDATE_PASSWORD_CLEAN,
    payload: {},
  }
}

export const updatePosition = (positionId, userId) => {
  return {
    type: UPDATE_POSITION,
    payload: { positionId, userId },
  }
}

export const updatePositionSuccess = () => {
  return {
    type: UPDATE_POSITION_SUCCESS,
    payload: {},
  }
}

export const updatePositionError = error => {
  return {
    type: UPDATE_POSITION_ERROR,
    payload: error,
  }
}

export const updatePositionClean = () => {
  return {
    type: UPDATE_POSITION_CLEAN,
    payload: {},
  }
}

export const updateAboutMe = (text, userId) => {
  return {
    type: UPDATE_ABOUT_ME,
    payload: { text, userId },
  }
}

export const updateAboutMeSuccess = () => {
  return {
    type: UPDATE_ABOUT_ME_SUCCESS,
    payload: {},
  }
}

export const updateAboutMeError = error => {
  return {
    type: UPDATE_ABOUT_ME_ERROR,
    payload: error,
  }
}

export const updateAboutMeClean = () => {
  return {
    type: UPDATE_ABOUT_ME_CLEAN,
    payload: {},
  }
}
