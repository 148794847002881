import {
  GET_SOFTSKILLS_USER_PROGRESS,
  GET_SOFTSKILLS_USER_PROGRESS_SUCCESS,
  GET_SOFTSKILLS_USER_PROGRESS_ERROR,
  GET_SOFTSKILLS_USER_PROGRESS_CLEAN,
} from "./actionTypes"

export const getSoftskillsUserProgress = userId => ({
  type: GET_SOFTSKILLS_USER_PROGRESS,
  payload: { userId },
})

export const getSoftskillsUserProgressSuccess = progress => {
  return {
    type: GET_SOFTSKILLS_USER_PROGRESS_SUCCESS,
    payload: { progress },
  }
}

export const getSoftskillsUserProgressClean = () => {
  return {
    type: GET_SOFTSKILLS_USER_PROGRESS_CLEAN,
    payload: {},
  }
}

export const getSoftskillsUserProgressError = error => {
  return {
    type: GET_SOFTSKILLS_USER_PROGRESS_ERROR,
    payload: error,
  }
}
