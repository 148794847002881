import {
  EDIT_SOFTSKILL_COURSE,
  EDIT_SOFTSKILL_COURSE_SUCCESS,
  EDIT_SOFTSKILL_COURSE_CLEAN,
  EDIT_SOFTSKILL_COURSE_ERROR,
} from "./actionTypes"

export const editSoftskillCourse = form => ({
  type: EDIT_SOFTSKILL_COURSE,
  payload: { form },
})

export const editSoftskillCourseSuccess = course => {
  return {
    type: EDIT_SOFTSKILL_COURSE_SUCCESS,
    payload: { course },
  }
}

export const editSoftskillCourseClean = () => {
  return {
    type: EDIT_SOFTSKILL_COURSE_CLEAN,
    payload: {},
  }
}

export const editSoftskillCourseError = error => {
  return {
    type: EDIT_SOFTSKILL_COURSE_ERROR,
    payload: error,
  }
}
