/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LIST_LOCATIONS } from "./actionTypes"
import { listLocationsSuccess, listLocationsError } from "./actions"

//Include Both Helper File with needed methods
import { getLocations } from "../../../helpers/backend_helper"

function* locations() {
  try {
    const response = yield call(getLocations)
    if (response.statusCode) throw new Error(response.message)
    yield put(listLocationsSuccess(response))
  } catch (error) {
    yield put(listLocationsError(error))
  }
}

function* locationsSaga() {
  yield takeEvery(LIST_LOCATIONS, locations)
}

export default locationsSaga
