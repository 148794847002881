import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import { Modal, Input, Row, Col, FormGroup, Label, Card } from "reactstrap"
import Dropzone from "react-dropzone"
import { updateProfilePicture, updateProfilePictureClean } from "store/actions"

const ChangeAvatarModal = props => {
  const [formError, setFormError] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])

  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      setFormError(false)
      setSelectedFiles([])
    }
  }, [])

  const { loading, error, done } = useSelector(store => ({
    loading: store.UserEdit.updateProfilePictureLoading,
    error: store.UserEdit.updateProfilePictureError,
    done: store.UserEdit.updateProfilePictureSuccess,
  }))

  const handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )

    setSelectedFiles(files)
  }

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const handleSubmitFile = async (event, errors, values) => {
    if (selectedFiles.length < 1) {
      setFormError(true)
      return
    }
    setFormError(false)

    const formData = new FormData()
    formData.append("file", selectedFiles[0])

    dispatch(updateProfilePicture(props.idUsuario, formData))
  }

  if (done) {
    dispatch(updateProfilePictureClean())
    props.onUpdate()
  }

  return (
    <Modal isOpen={props.show} backdrop={"static"} id="staticBackdrop">
      <AvForm className="form-horizontal" onSubmit={handleSubmitFile}>
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Actualizar foto de perfil
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              props.onCancel()
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          {formError && (
            <div className="alert alert-danger">
              Debe elegir una imagen para realizar la carga.
            </div>
          )}
          {error !== "" && <div className="alert alert-danger">{error}</div>}
          <Dropzone
            accept="image/*"
            maxFiles={1}
            onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone">
                <div className="dz-message needsclick" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="mb-3">
                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                  </div>
                  <h4>
                    Arrastra aquí tu imagen, o da clic para seleccionarla.
                  </h4>
                </div>
              </div>
            )}
          </Dropzone>
          {selectedFiles.map((f, i) => {
            return (
              <Card
                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                key={i + "-file"}
              >
                <div className="p-2">
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <img
                        data-dz-thumbnail=""
                        height="80"
                        className="avatar-sm rounded bg-light"
                        alt={f.name}
                        src={f.preview}
                      />
                    </Col>
                    <Col>
                      <Link
                        to="#"
                        className="text-muted font-weight-bold"
                        draggable="false"
                      >
                        {f.name}
                      </Link>
                      <p className="mb-0">
                        <strong>{f.formattedSize}</strong>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Card>
            )
          })}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              props.onCancel()
            }}
          >
            Cancelar
          </button>
          <button
            type="submit"
            className={`btn btn-secondary ${loading ? "disabled" : ""}`}
          >
            {loading ? "Cargando, por favor espera..." : "Asignar"}
          </button>
        </div>
      </AvForm>
    </Modal>
  )
}

ChangeAvatarModal.propTypes = {
  show: PropTypes.bool,
  onUpdate: PropTypes.func,
  onCancel: PropTypes.func,
  idUsuario: PropTypes.number,
}

export default ChangeAvatarModal
