import { getIndicator } from "pages/SoftSkills/Helpers/Helpers"
import { Fragment } from "react"
import { Col, Row } from "reactstrap"
import PropTypes from "prop-types"

const AbilityProgress = ({ abilityProgress }) => {
  return (
    <Fragment>
      <Row>
        <Col sm={6} xs={12}>
          {(abilityProgress || []).map((ability, index) => {
            const half = abilityProgress.length / 2
            if (index > half) {
              return <Fragment key={`frg-${ability.abilityId}`}></Fragment>
            }
            return (
              <div key={`hab-${ability.abilityId}`} className="mb-2">
                {getIndicator(ability.statusId)}
                {index + 1}. {ability.ability.abilityName}
              </div>
            )
          })}
        </Col>
        <Col sm={6} xs={12}>
          {(abilityProgress || []).map((ability, index) => {
            const half = abilityProgress.length / 2
            if (index <= half) {
              return <Fragment key={`frg-${ability.abilityId}`}></Fragment>
            }
            return (
              <div key={`hab-${ability.abilityId}`} className="mb-2">
                {getIndicator(ability.statusId)}
                {index + 1}. {ability.ability.abilityName}
              </div>
            )
          })}
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <hr />
          <Row>
            <Col md={4}>{getIndicator(1)} Habilidad pendiente</Col>
            <Col md={4}>{getIndicator(2)} Habilidad en revisión</Col>
            <Col md={4}>{getIndicator(3)} Habilidad completa</Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  )
}

AbilityProgress.propTypes = {
  abilityProgress: PropTypes.array,
}

export default AbilityProgress
