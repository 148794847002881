/* eslint-disable no-undef */
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { GET_SOFTSKILLS_NOTIFICATIONS } from "./actionTypes"
import {
  getSoftskillsNotificationsError,
  getSoftskillsNotificationsSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { getNotifications } from "../../../helpers/backend_helper"

function* listSoftskillsCourses(action) {
  try {
    const response = yield call(getNotifications, action.payload)
    yield put(getSoftskillsNotificationsSuccess(response))
  } catch (error) {
    yield put(getSoftskillsNotificationsError(error))
  }
}

function* softskillsNotificationsListSaga() {
  yield takeEvery(GET_SOFTSKILLS_NOTIFICATIONS, listSoftskillsCourses)
}

export default softskillsNotificationsListSaga
