/* eslint-disable no-undef */
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  CHANGE_PASSWORD,
  GET_USERS,
  GET_USERS_BY_ROLE,
  SEND_USER_TRASH,
} from "./actionTypes"
import {
  usersListApiError,
  getUsersSuccess,
  getUsersByRoleSuccess,
  changePasswordSuccess,
  changePasswordError,
  sendUserToTrashError,
  sendUserToTrashSuccess,
} from "./actions"

import {
  getUsers,
  getUsersNewApi,
  getUsersByRole,
  getUsersByRoleNewApi,
  changeUserPassword,
  changeUserPasswordNewApi,
  sendUserToTrash,
} from "../../../helpers/backend_helper"

function* users(action) {
  try {
    const response =
      action.payload.userId === undefined
        ? yield call(getUsers)
        : yield call(getUsersNewApi, action.payload.userId)
    yield put(getUsersSuccess(response == null ? [] : response))
  } catch (error) {
    yield put(usersListApiError(error))
  }
}

function* usersByRole(action) {
  try {
    const response = yield call(getUsersByRoleNewApi, action.payload.idRol)
    yield put(getUsersByRoleSuccess(response == null ? [] : response))
  } catch (error) {
    yield put(usersListApiError(error))
  }
}

function* changePassword({ payload: { id, password, registerUserId } }) {
  try {
    const response = yield call(changeUserPasswordNewApi, {
      userId: id,
      newPassword: password,
      registerUserId: registerUserId,
    })
    if (response.success) {
      yield put(changePasswordSuccess(response.sucess))
    } else {
      yield put(changePasswordError(response.error))
    }
  } catch (error) {
    yield put(changePasswordError(error))
  }
}

function* trash(request) {
  try {
    const response = yield call(sendUserToTrash, request.payload.id)
    if (response.message) throw new Error(response)
    yield put(sendUserToTrashSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(sendUserToTrashError(error.message))
  }
}

function* usersListSaga() {
  yield takeEvery(GET_USERS, users)
  yield takeEvery(GET_USERS_BY_ROLE, usersByRole)
  yield takeEvery(CHANGE_PASSWORD, changePassword)
  yield takeEvery(SEND_USER_TRASH, trash)
}

export default usersListSaga
