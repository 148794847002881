import {
  GET_SOFTSKILLS_COURSE,
  GET_SOFTSKILLS_COURSE_SUCCESS,
  GET_SOFTSKILLS_COURSE_CLEAN,
  GET_SOFTSKILLS_COURSE_ERROR,
} from "./actionTypes"

export const getSoftskillsCourse = courseId => ({
  type: GET_SOFTSKILLS_COURSE,
  payload: { courseId },
})

export const getSoftskillsCourseSuccess = course => {
  return {
    type: GET_SOFTSKILLS_COURSE_SUCCESS,
    payload: { course },
  }
}

export const getSoftskillsCourseClean = () => {
  return {
    type: GET_SOFTSKILLS_COURSE_CLEAN,
    payload: {},
  }
}

export const getSoftskillsCourseError = error => {
  return {
    type: GET_SOFTSKILLS_COURSE_ERROR,
    payload: error,
  }
}
