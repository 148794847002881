/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { ADD_SOFTSKILL_ABILITY } from "./actionTypes"
import { addSoftskillAbilityError, addSoftskillAbilitySuccess } from "./actions"

//Include Both Helper File with needed methods
import { addSoftskillsAbility } from "../../../helpers/backend_helper"

function* add(action) {
  try {
    const response = yield call(addSoftskillsAbility, action.payload.form)
    if (response == undefined) throw new Error("API error")
    if (response.message) throw new Error(response.message)
    yield put(addSoftskillAbilitySuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(addSoftskillAbilityError(error.message))
  }
}

function* softskillAbilityAddSaga() {
  yield takeEvery(ADD_SOFTSKILL_ABILITY, add)
}

export default softskillAbilityAddSaga
