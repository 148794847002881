/* eslint-disable no-undef */
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { GET_SOFTSKILLS_COURSES } from "./actionTypes"
import {
  getSoftskillsCoursesError,
  getSoftskillsCoursesSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { getSoftskillsCourses } from "../../../helpers/backend_helper"

function* listSoftskillsCourses(action) {
  try {
    const response = yield call(getSoftskillsCourses, action.payload.active)
    yield put(getSoftskillsCoursesSuccess(response))
  } catch (error) {
    yield put(getSoftskillsCoursesError(error))
  }
}

function* softskillsCoursesListSaga() {
  yield takeEvery(GET_SOFTSKILLS_COURSES, listSoftskillsCourses)
}

export default softskillsCoursesListSaga
