import {
  DELETE_EVENT,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_ERROR,
  DELETE_EVENT_CLEAN,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  event: undefined,
}

const EventDelete = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_EVENT:
      state = {
        ...state,
        loading: true,
      }
      break
    case DELETE_EVENT_SUCCESS:
      state = {
        ...state,
        event: action.payload.event,
        loading: false,
        done: true,
      }
      break
    case DELETE_EVENT_ERROR:
      state = {
        ...state,
        error: action.payload,
        event: undefined,
        loading: false,
        done: false,
      }
      break
    case DELETE_EVENT_CLEAN:
      state = initialState
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default EventDelete
