import React from "react"

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"

import ApexRadial from "./ApexRadial"
import Guage from "components/Charts/Guage"

const MonthlyEarning = () => {
  return (
    <React.Fragment>
      {" "}
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Avance total personal</CardTitle>
          <Row>
            <Col sm="4">
              <p className="text-muted">Este mes has completado</p>
              <h3>3 cursos</h3>
              <p className="text-muted">
                <span className="text-success me-2">
                  {" "}
                  12% <i className="mdi mdi-arrow-up"></i>{" "}
                </span>{" "}
                arriba del promedio
              </p>
              <div className="mt-4">
                <Link to="/analytics" className="btn btn-primary  btn-sm">
                  Más analítica <i className="mdi mdi-arrow-right ms-1"></i>
                </Link>
              </div>
            </Col>
            <Col sm="8">
              <div className="">
                <div id="gauge-chart" className="e-chart">
                  <Guage />
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default MonthlyEarning
