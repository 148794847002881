import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Input,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { Link, useParams } from "react-router-dom"
import UsersForm from "components/Users/UsersForm"

const Catalog = props => {
  const { id } = useParams()

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Catálogos | Capacitación en Línea Fester</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Catálogos</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  { key: "dashboard", title: "Dashboard", link: "/dashboard" },
                  { key: "catalog", title: "Catálogos" },
                ]}
              />
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col sm={4} md={4} xs={2} className="mb-4 pb-4 text-center">
                      <Link to="/catalog/positions">
                        <i className="fa fa-catalog fa-list"></i>
                        <h4 className="text-center">Ocupaciones</h4>
                      </Link>
                    </Col>
                    <Col sm={4} md={4} xs={2} className="mb-4 pb-4 text-center">
                      <Link to="/catalog/knowledge-categories">
                        <i className="fa fa-catalog fa-list"></i>
                        <h4 className="text-center">
                          Categorías de conocimiento
                        </h4>
                      </Link>
                    </Col>
                    <Col sm={4} md={4} xs={2} className="mb-4 pb-4 text-center">
                      <Link to="/catalog/levels">
                        <i className="fa fa-catalog fa-list"></i>
                        <h4 className="text-center">Niveles de curso</h4>
                      </Link>
                    </Col>
                    <Col sm={4} md={4} xs={2} className="mb-4 pb-4 text-center">
                      <Link to="/catalog/skills">
                        <i className="fa fa-catalog fa-list"></i>
                        <h4 className="text-center">Habilidades</h4>
                      </Link>
                    </Col>
                    <Col sm={4} md={4} xs={2} className="mb-4 pb-4 text-center">
                      <Link to="/catalog/shopping-areas">
                        <i className="fa fa-catalog fa-list"></i>
                        <h4 className="text-center">Zonas comerciales</h4>
                      </Link>
                    </Col>
                    <Col sm={4} md={4} xs={2} className="mb-4 pb-4 text-center">
                      <Link to="/catalog/businesses">
                        <i className="fa fa-catalog fa-list"></i>
                        <h4 className="text-center">Negocios</h4>
                      </Link>
                    </Col>
                    <Col sm={4} md={4} xs={2} className="mb-4 pb-4 text-center">
                      <Link to="/catalog/badges">
                        <i className="fa fa-catalog fa-list"></i>
                        <h4 className="text-center">Insignias</h4>
                      </Link>
                    </Col>
                    <Col sm={4} md={4} xs={2} className="mb-4 pb-4 text-center">
                      <Link to="/catalog/knowledges">
                        <i className="fa fa-catalog fa-list"></i>
                        <h4 className="text-center">Conocimientos</h4>
                      </Link>
                    </Col>
                    <Col sm={4} md={4} xs={2} className="mb-4 pb-4 text-center">
                      <Link to="/catalog/support-material-types">
                        <i className="fa fa-catalog fa-list"></i>
                        <h4 className="text-center">
                          Tipos de material de apoyo
                        </h4>
                      </Link>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <style jsx="true">{`
        .fa-catalog {
          font-size: 3rem;
          margin-bottom: 1rem;
        }
      `}</style>
    </React.Fragment>
  )
}

export default Catalog
