import { useState, useEffect, useRef } from "react"
import { Editor } from "@tinymce/tinymce-react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { addImage } from "../../helpers/backend_helper"

const CoursesLesonnsFormWysiwyg = props => {
  const block = props.block

  return (
    <Editor
      apiKey={"9p7bo3vgmi2do9q72hi0eoj0e2rmvk8t84q83qyd66hu2qa3"}
      initialValue={block.value}
      init={{
        menubar: "file edit view insert format table help",
        selector: "textarea#full-featured",
        plugins:
          "print preview importcss tinydrive searchreplace autolink autosave save \
          directionality visualblocks visualchars fullscreen image link media template \
          codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists \
          wordcount imagetools textpattern noneditable help \
          charmap quickbars emoticons",
        toolbar:
          "undo redo | fontselect fontsizeselect formatselect | bold italic underline strikethrough | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist checklist outdent indent | \
            forecolor backcolor casechange permanentpen formatpainter removeformat | \
            pagebreak | charmap emoticons | fullscreen  preview save print | \
            image media pageembed link anchor codesample | \
            a11ycheck | help",
        toolbar_mode: "sliding",
        font_formats:
          "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Gill Sans=gill sans; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
        content_style:
          ".mymention{ color: gray; };@import url('https://capacitacionenlinea-fester.com/gill-sans.css');",
        contextmenu: "link image imagetools table configurepermanentpen",
        a11y_advanced_options: true,
        image_uploadtab: true,
        skin: "oxide",
        content_css: "default",
        template_cdate_format: "[Creado el (CDATE): %d/%m/%Y : %H:%M:%S]",
        template_mdate_format: "[Editado el (MDATE): %d/%m/%Y : %H:%M:%S]",
        height: 600,
        images_upload_handler: async function (blobInfo, success, failure) {
          const formData = new FormData()
          formData.append("file", blobInfo.blob())
          try {
            const response = await addImage(formData)
            success(response[0])
          } catch (error) {
            return
          }
        },
      }}
      onEditorChange={e => {
        props.setEditor(e)
      }}
    />
  )
}

CoursesLesonnsFormWysiwyg.propTypes = {
  block: PropTypes.object,
  setEditor: PropTypes.func,
}

export default CoursesLesonnsFormWysiwyg
