import { GET_GROUPS, GET_GROUPS_SUCCESS, API_ERROR } from "./actionTypes"

export const getGroups = () => ({
  type: GET_GROUPS,
  payload: {},
})

export const getGroupsSuccess = groups => {
  return {
    type: GET_GROUPS_SUCCESS,
    payload: { groups },
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
