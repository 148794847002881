import React, { useState, Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import { Row, Col, Modal } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import moment from "moment"

import {
  getCustomers,
  editCustomerClean,
  addCustomerClean,
  getShoppingAreas,
} from "../../store/actions"
import "../../assets/scss/datatables.scss"
import { useDispatch, useSelector } from "react-redux"
import CustomersForm from "./CustomersForm"

const CustomersListTable = props => {
  const dispatch = useDispatch()
  const { SearchBar } = Search

  const [insertModal, setInsertModal] = useState(false)
  const [updateModal, setUpdateModal] = useState(false)
  const [page, setPage] = useState(undefined)
  const [sizePerPage, setSizePerPage] = useState(undefined)
  const [customer, setCustomer] = useState({
    customerId: 0,
  })

  const { error, loading, done, customers, shoppingAreas } = useSelector(
    state => ({
      error: state.CustomersList.error,
      loading: state.CustomersList.loading,
      customers: state.CustomersList.customers,
      done: state.CustomersList.done,
      shoppingAreas: state.ShoppingAreasList.shoppingAreas,
    })
  )

  if (customers !== undefined) {
    customers.forEach(element => {
      element.idh = parseInt(element.idh)
    })
  }

  useEffect(() => {
    dispatch(getShoppingAreas())
    dispatch(getCustomers("all"))
  }, [])

  const handleEdit = customer => {
    setCustomer(customer)
    setUpdateModal(true)
  }

  const buttonActions = (cell, row, rowIndex, extraData) => {
    return (
      <button
        key={`button-${row.customerId}`}
        onClick={() => handleEdit(row)}
        className="btn btn-primary"
      >
        Editar
      </button>
    )
  }

  const columns = [
    {
      dataField: "idh",
      text: "IDH",
      sort: true,
    },
    {
      dataField: "name",
      text: "Nombre",
      sort: true,
    },
    {
      dataField: "businessName",
      text: "Nombre comercial",
      sort: true,
      hidden: true,
    },
    {
      dataField: "shoppingAreaId",
      text: "Zona comercial",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        const shoppingArea = shoppingAreas.find(
          element => row.shoppingAreaId === element.idZonaComercial
        )
        return (
          <Fragment>
            {shoppingArea === undefined
              ? "Sin definir"
              : shoppingArea.zonaComercial}
          </Fragment>
        )
      },
    },
    {
      dataField: "statusId",
      text: "Estatus",
      sort: true,
      align: "center",
      headerAlign: "center",
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <h4 style={{ marginBottom: 0 }} key={`status-${row.customerId}`}>
            <span
              className={`pt-2 badge bg-${
                row.statusId ? "success" : "warning"
              }`}
            >
              {row.statusId ? "Activo" : "Inactivo"}
            </span>
          </h4>
        )
      },
    },
    {
      dataField: "actions",
      text: "Acciones",
      sort: false,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        width: "150px",
      },
      formatter: buttonActions,
    },
  ]

  const pageOptions = {
    sizePerPage: sizePerPage === undefined ? 10 : sizePerPage,
    totalSize: customers?.length,
    custom: true,
    showTotal: true,
    page: page === undefined ? 1 : page,
    onPageChange: page => {
      setPage(page)
    },
    onSizePerPageChange: sizePerPage => {
      setSizePerPage(sizePerPage)
    },
  }

  const defaultSorted = [{ dataField: "idh", order: "asc" }]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
  }

  return loading || customers === undefined ? (
    <div className="mt-3 mb-3">
      <div className="spinner-chase">
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
      </div>
    </div>
  ) : (
    <Fragment>
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="customerId"
        columns={columns}
        data={customers}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="customerId"
            columns={columns}
            data={customers}
            search
          >
            {toolkitProps => (
              <React.Fragment>
                <Row className="mb-2 pt-3">
                  <Col lg={2} md={3} sm={4} xs={6}>
                    <div className="d-grid">
                      <button
                        className="btn btn-primary btn-rounded"
                        onClick={() => setInsertModal(true)}
                      >
                        <i className="fa fa-plus"></i> Agregar cliente
                      </button>
                    </div>
                  </Col>
                  <Col
                    lg={{ size: 2, offset: 8 }}
                    md={{ size: 3, offset: 6 }}
                    sm={{ size: 4, offset: 4 }}
                    xs={6}
                  >
                    <div className="search-box me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <SearchBar
                          {...toolkitProps.searchProps}
                          onSearch={value => {
                            setPage(1)
                            toolkitProps.searchProps.onSearch(value)
                          }}
                          placeholder="Buscar"
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl="12">
                    <Fragment>
                      <BootstrapTable
                        keyField={"customerId"}
                        bordered={false}
                        striped={true}
                        defaultSorted={defaultSorted}
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={"thead-light"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                      />
                      {customers?.length <= 0 && (
                        <Row>
                          <Col md={12} className="text-center">
                            -- No hay registros --
                          </Col>
                        </Row>
                      )}
                    </Fragment>
                  </Col>
                </Row>

                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                    <div className="text-md-right ms-auto">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
      <Modal
        isOpen={insertModal}
        backdrop={"static"}
        size="lg"
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title">Registrar cliente</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setInsertModal(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <CustomersForm
            onDone={() => {
              dispatch(addCustomerClean())
              dispatch(getCustomers("all"))
              setInsertModal(false)
            }}
            onCancel={() => {
              dispatch(addCustomerClean())
              setInsertModal(false)
            }}
            insert={true}
            customer={{
              customerId: 0,
              name: "",
              businessName: "",
              shoppingAreaId: 0,
              statusId: true,
            }}
          />
        </div>
      </Modal>
      <Modal
        isOpen={updateModal}
        backdrop={"static"}
        size="lg"
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title">Editar cliente</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setUpdateModal(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <CustomersForm
            onDone={() => {
              dispatch(editCustomerClean())
              dispatch(getCustomers("all"))
              setUpdateModal(false)
            }}
            onCancel={() => {
              dispatch(editCustomerClean())
              setUpdateModal(false)
            }}
            insert={false}
            customer={customer}
          />
        </div>
      </Modal>
    </Fragment>
  )
}

CustomersListTable.propTypes = {
  customers: PropTypes.array,
}

export default CustomersListTable
