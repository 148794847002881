/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { START_COURSE } from "./actionTypes"
import { startCourseError, startCourseSuccess } from "./actions"

//Include Both Helper File with needed methods
import { startCourse } from "../../../helpers/backend_helper"

function* start(action) {
  try {
    const response = yield call(startCourse, action.payload.form)
    if (response == undefined) throw new Error("API error")
    if (!response.success) throw new Error("Error al iniciar el curso")
    yield put(startCourseSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(startCourseError(error.message))
  }
}

function* courseStartSaga() {
  yield takeEvery(START_COURSE, start)
}

export default courseStartSaga
