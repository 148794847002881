import { Row, Col } from "reactstrap"

const SessionLogo = () => {
  return (
    <div className="celf-content-login">
      <div className="fester-logo-login"></div>
    </div>
  )
}

export default SessionLogo
