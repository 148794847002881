import {
  CREATE_EVENT,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_ERROR,
  CREATE_EVENT_CLEAN,
} from "./actionTypes"

export const createEvent = event => ({
  type: CREATE_EVENT,
  payload: { event },
})

export const createEventSuccess = event => {
  return {
    type: CREATE_EVENT_SUCCESS,
    payload: { event },
  }
}

export const createEventClean = () => {
  return {
    type: CREATE_EVENT_CLEAN,
    payload: {},
  }
}

export const createEventError = error => {
  return {
    type: CREATE_EVENT_ERROR,
    payload: error,
  }
}
