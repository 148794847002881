import React, { Component, Fragment, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import MetaTags from "react-meta-tags"
import { Container, Row, Col, Card, CardBody } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb2"
import ConfigurationBox from "components/Interfester/ConfigurationBox"

const ConfigurationInterfester = props => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Configuración | La Academia Fester</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Configuración</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  { key: "home", title: "La Academia Fester", link: "/home" },
                  { key: "config", title: "Configuración" },
                ]}
              />
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col
                      lg={{ size: 4, offset: 2 }}
                      md={{ size: 6, offset: 0 }}
                      sm={{ size: 6, offset: 0 }}
                    >
                      <ConfigurationBox
                        title="Editoriales"
                        description="Gestiona las editoriales, asigna nuevas y activa o desactiva"
                        icon="fa fa-book"
                        link="/start/adm-editoriales"
                      />
                    </Col>
                    <Col
                      lg={{ size: 4, offset: 0 }}
                      md={{ size: 6, offset: 0 }}
                      sm={{ size: 6, offset: 0 }}
                    >
                      <ConfigurationBox
                        title="Eventos"
                        description="Publica nuevos eventos, elimina pasados o edita otros"
                        icon="fa fa-compass"
                        link="/start/adm-eventos"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      lg={{ size: 4, offset: 2 }}
                      md={{ size: 6, offset: 0 }}
                      sm={{ size: 6, offset: 0 }}
                    >
                      <ConfigurationBox
                        title="Webinars"
                        description="Sube nuevos webinars, actualiza información"
                        icon="fa fa-file-audio"
                        link="/start/adm-webinars"
                      />
                    </Col>
                    <Col
                      lg={{ size: 4, offset: 0 }}
                      md={{ size: 6, offset: 0 }}
                      sm={{ size: 6, offset: 0 }}
                    >
                      <ConfigurationBox
                        title="Carrusel"
                        description="Administra los anuncios que ven los usuarios"
                        icon="fa fa-image"
                        link="/start/adm-carousel"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      lg={{ size: 4, offset: 4 }}
                      md={{ size: 12, offset: 0 }}
                      sm={{ size: 12, offset: 0 }}
                    >
                      <ConfigurationBox
                        title="Usuarios"
                        description="Revisa los usuarios que están registrados en el portal La Academia Fester y qué aplicaciones tienen disponibles"
                        icon="fa fa-users"
                        link="/start/adm-usuarios"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ConfigurationInterfester
