import React from "react"
import PropTypes from "prop-types"
import ReactApexChart from "react-apexcharts"

const Report2Chart = ({ data }) => {
  const categories = [
    "Del 0%",
    "Mayor a 0% y hasta 30%",
    "Mayor a 30% y hasta 60%",
    "Mayor a 60% y menor a 100%",
    "Al 100%",
  ]

  let avance0 = 0
  let avance30 = 0
  let avance60 = 0
  let avance99 = 0
  let avance100 = 0

  if (data !== undefined)
    data.forEach(element => {
      avance0 += element.num_usuarios_pctavance_0
      avance30 += element.num_usuarios_pctavance_0_30
      avance60 += element.total_usuarios_porcentaje_avance_30_60
      avance99 += element.total_usuarios_porcentaje_avance_60_99
      avance100 += element.total_usuarios_porcentaje_avance_100
    })

  const series = [
    {
      type: "column",
      name: "Cursos",
      data: [avance0, avance30, avance60, avance99, avance100],
    },
  ]

  const options = {
    title: {
      text: "Gráfica 2: Cursos de usuarios por porcentaje de avance",
    },
    chart: {
      stacked: !0,
      toolbar: {
        show: 1,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: "15%",
        // endingShape: "rounded"
      },
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [1],
    },
    xaxis: {
      show: true,
      categories: categories,
      labels: {
        show: true,
        style: {
          fontSize: "14px",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: "14px",
        },
      },
    },
    colors: ["#34c38f", "#f46a6a"],
    legend: {
      position: "top",
    },
    fill: {
      opacity: 1,
    },
  }

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={[...series]}
        type="bar"
        height="420"
        className="apex-charts"
      />
    </React.Fragment>
  )
}

Report2Chart.propTypes = {
  data: PropTypes.array,
}
export default Report2Chart
