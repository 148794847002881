import React, { useState, Fragment, useEffect } from "react"
import { Row, Col, Modal } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import moment from "moment"

import "../../../assets/scss/datatables.scss"
import { useDispatch, useSelector } from "react-redux"
//import SkillsForm from "./SkillsForm"
import {
  addSoftskillAbilityClean,
  editSoftskillAbilityClean,
  getSoftskillsAbilities,
} from "store/actions"
import SoftskillsAbilitiesForm from "./SoftskillsAbilitiesForm"

const SoftskillsAbilitiesListTable = props => {
  const dispatch = useDispatch()
  const { SearchBar } = Search

  const [insertModal, setInsertModal] = useState(false)
  const [updateModal, setUpdateModal] = useState(false)
  const [ability, setAbility] = useState({
    abilityId: 0,
  })

  const { error, loading, done, abilities } = useSelector(state => ({
    error: state.SoftskillsAbilitiesList.error,
    loading: state.SoftskillsAbilitiesList.loading,
    abilities: state.SoftskillsAbilitiesList.abilities,
    done: state.SoftskillsAbilitiesList.done,
  }))

  useEffect(() => {
    dispatch(getSoftskillsAbilities())
  }, [])

  const handleEdit = ability => {
    setAbility(ability)
    setUpdateModal(true)
  }

  const buttonActions = (cell, row, rowIndex, extraData) => {
    const active = extraData?.active ?? 0
    return (
      <button
        className="btn btn-primary"
        onClick={() => handleEdit(row)}
        key={`button-${row.abilityId}`}
      >
        Editar
      </button>
    )
  }

  const columns = [
    {
      dataField: "abilityId",
      text: "Id",
      sort: false,
      hidden: true,
    },
    {
      dataField: "abilityName",
      text: "Nombre de la habilidad",
      sort: true,
    },
    {
      dataField: "status",
      text: "Estatus",
      sort: true,
      align: "center",
      headerAlign: "center",
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <h4 style={{ marginBottom: 0 }} key={`status-${row.abilityId}`}>
            <span
              className={`pt-2 badge bg-${row.status ? "success" : "warning"}`}
            >
              {row.status ? "Activo" : "Inactivo"}
            </span>
          </h4>
        )
      },
    },
    {
      dataField: "lastActivity",
      text: "Última actualización",
      sort: true,
      align: "center",
      headerAlign: "center",
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <span key={row.abilityId}>
            {moment(row.lastActivity)
              .locale("es")
              .format("DD/MM/YYYY HH:mm [hrs.]")}
          </span>
        )
      },
    },
    {
      dataField: "actions",
      text: "Acciones",
      sort: false,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        width: "150px",
      },
      formatter: buttonActions,
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: abilities?.length,
    custom: true,
    showTotal: true,
  }

  const defaultSorted = [{ dataField: "abilityName", order: "asc" }]

  return loading || abilities === undefined ? (
    <div className="mt-3 mb-3">
      <div className="spinner-chase">
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
      </div>
    </div>
  ) : (
    <Fragment>
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="skillId"
        columns={columns}
        data={abilities}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="skillId"
            columns={columns}
            data={abilities}
            search
          >
            {toolkitProps => (
              <React.Fragment>
                <Row className="mb-2 pt-3">
                  <Col lg={2} md={3} sm={4} xs={6}>
                    <div className="d-grid">
                      <button
                        className="btn btn-primary btn-rounded"
                        onClick={() => setInsertModal(true)}
                      >
                        <i className="fa fa-plus"></i> Agregar habilidad
                      </button>
                    </div>
                  </Col>
                  <Col
                    lg={{ size: 2, offset: 8 }}
                    md={{ size: 3, offset: 6 }}
                    sm={{ size: 4, offset: 4 }}
                    xs={6}
                  >
                    <div className="search-box me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <SearchBar
                          {...toolkitProps.searchProps}
                          placeholder="Buscar"
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl="12">
                    <Fragment>
                      <BootstrapTable
                        keyField={"abilityId"}
                        bordered={false}
                        striped={true}
                        defaultSorted={defaultSorted}
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={"thead-light"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                      />
                      {abilities?.length <= 0 && (
                        <Row>
                          <Col md={12} className="text-center">
                            -- No hay registros --
                          </Col>
                        </Row>
                      )}
                    </Fragment>
                  </Col>
                </Row>

                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                    <div className="text-md-right ms-auto">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
      <Modal
        isOpen={insertModal}
        backdrop={"static"}
        size="lg"
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title">Registrar habilidad</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setInsertModal(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <SoftskillsAbilitiesForm
            onDone={() => {
              dispatch(addSoftskillAbilityClean())
              dispatch(getSoftskillsAbilities())
              setInsertModal(false)
            }}
            onCancel={() => {
              dispatch(addSoftskillAbilityClean())
              setInsertModal(false)
            }}
            insert={true}
            ability={{
              abilityId: 0,
              abilityName: "",
              status: true,
              dependientAbilities: [],
            }}
          />
        </div>
      </Modal>
      <Modal
        isOpen={updateModal}
        backdrop={"static"}
        size="lg"
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title">Editar habilidad</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setUpdateModal(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <SoftskillsAbilitiesForm
            onDone={() => {
              dispatch(editSoftskillAbilityClean())
              dispatch(getSoftskillsAbilities())
              setUpdateModal(false)
            }}
            onCancel={() => {
              dispatch(editSoftskillAbilityClean())
              setUpdateModal(false)
            }}
            insert={false}
            ability={ability}
          />
        </div>
      </Modal>
    </Fragment>
  )
}

SoftskillsAbilitiesListTable.propTypes = {}

export default SoftskillsAbilitiesListTable
