import {
  GET_SOFTSKILLS_NOTIFICATIONS,
  GET_SOFTSKILLS_NOTIFICATIONS_SUCCESS,
  GET_SOFTSKILLS_NOTIFICATIONS_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  notifications: [],
}

const SoftskillsNotificationsList = (state = initialState, action) => {
  switch (action.type) {
    case GET_SOFTSKILLS_NOTIFICATIONS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_SOFTSKILLS_NOTIFICATIONS_SUCCESS:
      state = {
        ...state,
        notifications: action.payload.notifications,
        loading: false,
        done: true,
      }
      break
    case GET_SOFTSKILLS_NOTIFICATIONS_ERROR:
      state = { ...state, error: action.payload, loading: false, done: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default SoftskillsNotificationsList
