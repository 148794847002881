import React, { useEffect, useState } from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import { PropTypes } from "prop-types"

import avatar1 from "../../assets/images/user_without_pic.png"
import { useSelector, useDispatch } from "react-redux"
import {
  getGroups,
  getAllPositions,
  getPositions,
  getProfiles,
  me,
  updatePassword,
  updatePasswordClean,
  updateAboutMe,
} from "../../store/actions"
import ChangeAvatarModal from "components/Profile/ChangeAvatarModal"
import moment from "moment"
import ChangePasswordModal from "components/Profile/ChangePasswordModal"
import ChangePositionModal from "components/Profile/ChangePositionModal"

const ProfileBox = props => {
  const dispatch = useDispatch()

  const [groupName, setGroupName] = useState("")
  const [position, setPosition] = useState("")
  const [showChangeAvatarModal, setShowChangeAvatarModal] = useState(false)
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
  const [showChangePositionModal, setShowChangePositionModal] = useState(false)
  const [availablePositions, setAvailablePositions] = useState([])
  const [aboutMe, setAboutMe] = useState("")

  const {
    whoIAm,
    groups,
    updatePasswordLoading,
    updatePasswordError,
    updatePasswordSuccess,
    positions,
  } = useSelector(state => ({
    whoIAm: state.Profile.user,
    groups: state.GroupsList.groups,
    updatePasswordLoading: state.UserEdit.updatePasswordLoading,
    updatePasswordError: state.UserEdit.updatePasswordError,
    updatePasswordSuccess: state.UserEdit.updatePasswordSuccess,
    positions: state.PositionsList.positions,
  }))

  const user =
    whoIAm === undefined
      ? {
          idUsuario: 0,
          nombre: "",
          apellido: "",
          idRol: 0,
          profile: "",
          idGroup: 0,
          idPosicion: 0,
          aboutMe: null,
        }
      : whoIAm

  const avg = props.completedAvg

  useEffect(() => {
    dispatch(getGroups())
    dispatch(getPositions())
    dispatch(getProfiles())
  }, [])

  useEffect(() => {
    setAboutMe(user.aboutMe === null ? "" : user.aboutMe)
  }, [user.aboutMe])

  useEffect(() => {
    if (positions !== undefined) {
      const listPositions = positions ?? []
      const userPosition = listPositions.find(
        position => position.idPosicion === user.idPosicion
      )
      setPosition(userPosition === undefined ? "" : userPosition.posicion)
      const filteredPositions = listPositions.filter(
        position => position.profileId === user.profileId
      )
      setAvailablePositions(filteredPositions)
    }
  }, [positions])

  useEffect(() => {
    const listGroups = groups ?? []
    const myGroup = listGroups.find(group => group.idGrupo === user.idGroup)
    if (myGroup !== undefined) {
      setGroupName(myGroup.nombreGrupo)
    }
  }, [groups])

  const saveAboutMe = () => {
    dispatch(updateAboutMe(aboutMe, user.idUsuario))
  }

  if (updatePasswordSuccess) {
    dispatch(updatePasswordClean())
    setShowChangePasswordModal(false)
  }

  return (
    <React.Fragment>
      <Row>
        <Col
          lg={{ size: 4, offset: 0 }}
          md={{ size: 4, offset: 0 }}
          sm={{ size: 8, offset: 2 }}
          xs={{ size: 10, offset: 1 }}
        >
          <div className="pt-2">
            <div
              className="mt-2"
              style={{
                position: "relative",
                overflow: "hidden",
                paddingBottom: "100%",
              }}
            >
              <div
                style={{
                  backgroundImage:
                    user.avatar == null
                      ? `url(${avatar1})`
                      : `url(${user.avatar})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  borderRadius: "50%",
                }}
              >
                &nbsp;
              </div>
            </div>
          </div>
          <div className="text-center mt-2">
            <small>
              <div className="mb-0">Último ingreso:</div>
              <div>
                {moment(user.lastActivity)
                  .locale("es")
                  .format("DD/MM/YYYY HH:mm [hrs.]")}
              </div>
            </small>
          </div>
        </Col>
        <Col lg={{ size: 8, offset: 0 }} md={{ size: 8, offset: 0 }}>
          <div className="p-2 pt-4">
            <p className="mb-0">Nombre</p>
            <h3 className="mb-3 mt-0">
              <strong>
                {user.nombre} {user.apellid}
              </strong>
            </h3>
            <p className="mb-0">Perfil</p>
            <h3 className="mb-3 mt-0">
              <strong>N{user.idRol}</strong>
            </h3>
            <p className="mb-0">Correo</p>
            <h3 className="mb-3 mt-0">
              <strong>{user.email}</strong>
            </h3>
            <p className="mb-0">Grupo:</p>
            <h3 className="mb-3 mt-0">
              <strong>{groupName}</strong>
            </h3>
            <p className="mb-0">Ocupación:</p>
            <h3 className="mb-3 mt-0">
              <strong>{position}</strong>
            </h3>
          </div>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <Row className="d-flex align-items-center">
            <Col md={{ size: 12 }} className="mb-3 mt-3">
              <h3>Foto de perfil</h3>
            </Col>
            <Col
              className="mb-2"
              xl={{ size: 9 }}
              lg={{ size: 8 }}
              md={{ size: 8 }}
            >
              Esto ayudará a tus compañeros a reconocerte en Henkel Academy
            </Col>
            <Col
              xl={{ size: 3 }}
              lg={{ size: 4 }}
              md={{ size: 4 }}
              className="d-flex justify-content-end"
            >
              <button
                className="btn btn-primary btn-block  d-flex align-items-center"
                onClick={() => setShowChangeAvatarModal(true)}
              >
                <i className="fa fa-image"></i>
                <span className="flex-grow-1 text-center">Actualizar foto</span>
              </button>
            </Col>
            <Col md={{ size: 12 }}>
              <hr />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="d-flex align-items-center">
            <Col md={{ size: 12 }} className="mb-3 mt-3">
              <h3>Contraseña</h3>
            </Col>
            <Col
              className="mb-2"
              xl={{ size: 9 }}
              lg={{ size: 8 }}
              md={{ size: 8 }}
            >
              Te recomendamos cambiar tu contraseña cada 90 días
            </Col>
            <Col
              xl={{ size: 3 }}
              lg={{ size: 4 }}
              md={{ size: 4 }}
              className="d-flex justify-content-end"
            >
              <button
                className="btn btn-primary btn-block d-flex align-items-center"
                onClick={() => setShowChangePasswordModal(true)}
              >
                <i className="fa fa-asterisk"></i>
                <span className="flex-grow-1 text-center">
                  Cambiar contraseña
                </span>
              </button>
            </Col>
            <Col md={{ size: 12 }}>
              <hr />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="d-flex align-items-center">
            <Col md={{ size: 12 }} className="mb-3 mt-3">
              <h3>Ocupación</h3>
            </Col>
            <Col
              className="mb-2"
              xl={{ size: 9 }}
              lg={{ size: 8 }}
              md={{ size: 8 }}
            >
              Si tu ocupación ha cambiado, puedes modificarla según tu perfil
            </Col>
            <Col
              xl={{ size: 3 }}
              lg={{ size: 4 }}
              md={{ size: 4 }}
              className="d-flex justify-content-end"
            >
              <button
                onClick={() => setShowChangePositionModal(true)}
                className="btn btn-primary btn-block d-flex align-items-center"
              >
                <i className="fa fa-address-card"></i>
                <span className="flex-grow-1 text-center">
                  Cambiar ocupación
                </span>
              </button>
            </Col>
            <Col md={{ size: 12 }}>
              <hr />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="d-flex align-items-center">
            <Col md={{ size: 12 }} className="mb-3 mt-3">
              <h3>Acerca de mi</h3>
            </Col>
            <Col md={{ size: 12 }} className="mb-2">
              <textarea
                className="form-control"
                name="txtAboutMe"
                id="txtAboutMe"
                value={aboutMe}
                rows="6"
                onChange={({ target }) => setAboutMe(target.value)}
              ></textarea>
            </Col>
            <Col
              xl={{ size: 9 }}
              lg={{ size: 8 }}
              md={{ size: 8 }}
              className="mt-3"
            >
              <p>
                Si deseas, escribe una breve descripción de tu perfil para este
                portal
              </p>
            </Col>
            <Col xl={{ size: 3 }} lg={{ size: 4 }} md={{ size: 4 }}>
              <button
                className="btn btn-primary btn-block d-flex align-items-center"
                onClick={() => saveAboutMe()}
              >
                <i className="fa fa-user"></i>
                <span className="flex-grow-1 text-center">
                  Actualizar perfil
                </span>
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
      <ChangeAvatarModal
        show={showChangeAvatarModal}
        idUsuario={user.idUsuario}
        onCancel={() => setShowChangeAvatarModal(false)}
        onUpdate={() => {
          dispatch(me())
          setShowChangeAvatarModal(false)
        }}
      />
      <ChangePasswordModal
        show={showChangePasswordModal}
        idUsuario={user.idUsuario}
        error={updatePasswordError}
        onCancel={() => {
          setShowChangePasswordModal(false)
        }}
        onConfirm={(password, newPassword) => {
          dispatch(
            updatePassword(
              password,
              newPassword,
              whoIAm.idUsuario,
              whoIAm.idUsuario
            )
          )
        }}
      />
      <ChangePositionModal
        show={showChangePositionModal}
        idUsuario={user.idUsuario}
        onCancel={() => setShowChangePositionModal(false)}
        onConfirm={() => {
          dispatch(me())
          setShowChangePositionModal(false)
        }}
        positionId={user.idPosicion}
        availablePositions={availablePositions}
      />
    </React.Fragment>
  )
}

ProfileBox.propTypes = {
  completedAvg: PropTypes.number,
}

export default ProfileBox
