import { Fragment, useState, useEffect } from "react"
import { Row, Col, Container, Input, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import MetaTags from "react-meta-tags"
//import Charts
import StackedColumnChart from "../Dashboard/StackedColumnChart"
import BarChart from "../Dashboard/barchart"
import { getChartsData as onGetChartsData } from "../../store/actions"
//redux
import { useSelector, useDispatch } from "react-redux"
import classnames from "classnames"
import LineColumnArea from "pages/Dashboard/lineColumnArea"

const ShowAnalytics = () => {
  const dispatch = useDispatch()
  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
  }))

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])

  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch])

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
    dispatch(onGetChartsData(pType))
  }

  return (
    <Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Capacitación en Línea Fester</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">
                      Estatus de cursos por usuario
                    </h4>
                    <div className="ms-auto">
                      <ul className="nav nav-pills">
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classnames(
                              { active: periodType === "weekly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("weekly")
                            }}
                            id="one_month"
                          >
                            Semanal
                          </Link>{" "}
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classnames(
                              { active: periodType === "monthly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("monthly")
                            }}
                            id="one_month"
                          >
                            Mensual
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classnames(
                              { active: periodType === "yearly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("yearly")
                            }}
                            id="one_month"
                          >
                            Anual
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <Row className="mt-2 mb-2">
                    <Col md={4}>
                      Por perfil:
                      <Input type="select">
                        <option value="">Todos los perfiles</option>
                        <option value="N1">N1</option>
                        <option value="N2">N2</option>
                        <option value="N3">N3</option>
                        <option value="N4">N4</option>
                        <option value="N5">N5</option>
                        <option value="N6">N6</option>
                        <option value="N7">N7</option>
                        <option value="N8">N8</option>
                      </Input>
                    </Col>
                    <Col md={4}>
                      Zona comercial:
                      <Input type="select">
                        <option value="">Todas las zonas</option>
                        <option value="Norte">Norte</option>
                        <option value="Centro">Centro</option>
                        <option value="Sur">Sur</option>
                      </Input>
                    </Col>
                    <Col md={4}>
                      Tipo usuario:
                      <Input type="select">
                        <option value="">Todos los tipos</option>
                        <option value="Interno">Interno</option>
                        <option value="Externo">Externo</option>
                      </Input>
                    </Col>
                  </Row>
                  <div className="clearfix"></div>
                  <StackedColumnChart periodData={periodData} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">
                      Estatus de cursos por usuario
                    </h4>
                  </div>
                  <Row className="mt-2 mb-2">
                    <Col md={4}>
                      Por perfil:
                      <Input type="select">
                        <option value="">Todos los perfiles</option>
                        <option value="N1">N1</option>
                        <option value="N2">N2</option>
                        <option value="N3">N3</option>
                        <option value="N4">N4</option>
                        <option value="N5">N5</option>
                        <option value="N6">N6</option>
                        <option value="N7">N7</option>
                        <option value="N8">N8</option>
                      </Input>
                    </Col>
                    <Col md={4}>
                      Zona comercial:
                      <Input type="select">
                        <option value="">Todas las zonas</option>
                        <option value="Norte">Norte</option>
                        <option value="Centro">Centro</option>
                        <option value="Sur">Sur</option>
                      </Input>
                    </Col>
                    <Col md={4}>
                      Tipo usuario:
                      <Input type="select">
                        <option value="">Todos los tipos</option>
                        <option value="Interno">Interno</option>
                        <option value="Externo">Externo</option>
                      </Input>
                    </Col>
                  </Row>
                  <div className="clearfix"></div>
                  <BarChart />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">
                      Tiempo de aprobación promedio y veces cursado promedio por
                      curso
                    </h4>
                  </div>
                  <Row className="mt-2 mb-2">
                    <Col md={4}>
                      Por perfil:
                      <Input type="select">
                        <option value="">Todos los perfiles</option>
                        <option value="N1">N1</option>
                        <option value="N2">N2</option>
                        <option value="N3">N3</option>
                        <option value="N4">N4</option>
                        <option value="N5">N5</option>
                        <option value="N6">N6</option>
                        <option value="N7">N7</option>
                        <option value="N8">N8</option>
                      </Input>
                    </Col>
                    <Col md={4}>
                      Zona comercial:
                      <Input type="select">
                        <option value="">Todas las zonas</option>
                        <option value="Norte">Norte</option>
                        <option value="Centro">Centro</option>
                        <option value="Sur">Sur</option>
                      </Input>
                    </Col>
                    <Col md={4}>
                      Tipo usuario:
                      <Input type="select">
                        <option value="">Todos los tipos</option>
                        <option value="Interno">Interno</option>
                        <option value="Externo">Externo</option>
                      </Input>
                    </Col>
                  </Row>
                  <div className="clearfix"></div>
                  <LineColumnArea />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default ShowAnalytics
