import {
  GET_SOFTSKILLS_COURSES,
  GET_SOFTSKILLS_COURSES_SUCCESS,
  GET_SOFTSKILLS_COURSES_ERROR,
} from "./actionTypes"

export const getSoftskillsCourses = active => ({
  type: GET_SOFTSKILLS_COURSES,
  payload: { active: active == undefined },
})

export const getSoftskillsCoursesSuccess = courses => {
  return {
    type: GET_SOFTSKILLS_COURSES_SUCCESS,
    payload: { courses },
  }
}

export const getSoftskillsCoursesError = error => {
  return {
    type: GET_SOFTSKILLS_COURSES_ERROR,
    payload: error,
  }
}
