import { useTimer } from "use-timer"
import { useDispatch } from "react-redux"
import { extendSession } from "store/actions"

const SessionTimer = () => {
  const dispatch = useDispatch()

  const { start, reset, status } = useTimer({
    autostart: true,
    initialTime: 15 * 60,
    endTime: 0,
    timerType: "DECREMENTAL",
    onTimeOver: () => {
      dispatch(extendSession())
      reset()
      start()
    },
  })

  return <div></div>
}

export default SessionTimer
