import {
  EDIT_SOFTSKILL_ABILITY_USER_POSITION,
  EDIT_SOFTSKILL_ABILITY_USER_POSITION_SUCCESS,
  EDIT_SOFTSKILL_ABILITY_USER_POSITION_CLEAN,
  EDIT_SOFTSKILL_ABILITY_USER_POSITION_ERROR,
} from "./actionTypes"

export const editSoftskillAbilityUserPosition = form => ({
  type: EDIT_SOFTSKILL_ABILITY_USER_POSITION,
  payload: { form },
})

export const editSoftskillAbilityUserPositionSuccess = ability => {
  return {
    type: EDIT_SOFTSKILL_ABILITY_USER_POSITION_SUCCESS,
    payload: { ability },
  }
}

export const editSoftskillAbilityUserPositionClean = () => {
  return {
    type: EDIT_SOFTSKILL_ABILITY_USER_POSITION_CLEAN,
    payload: {},
  }
}

export const editSoftskillAbilityUserPositionError = error => {
  return {
    type: EDIT_SOFTSKILL_ABILITY_USER_POSITION_ERROR,
    payload: error,
  }
}
