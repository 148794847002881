import React from "react"
import PropTypes from "prop-types"
import ReactApexChart from "react-apexcharts"

const ReportSurveryChart = ({ data }) => {
  const categories = [
    "Respuesta 1",
    "Respuesta 2",
    "Respuesta 3",
    "Respuesta 4",
    "Respuesta 5",
    "Respuesta 6",
    "Respuesta 7",
    "Respuesta 8",
  ]

  const promedios = [0, 0, 0, 0, 0, 0, 0, 0]

  if (data !== undefined) {
    for (let i = 0; i < data.length; i++) {
      promedios[0] += data[i].respuesta_01
      promedios[1] += data[i].respuesta_02
      promedios[2] += data[i].respuesta_03
      promedios[3] += data[i].respuesta_04
      promedios[4] += data[i].respuesta_05
      promedios[5] += data[i].respuesta_06
      promedios[6] += data[i].respuesta_07
      promedios[7] += data[i].respuesta_08
    }
  }

  const series = [
    {
      name: "Promedio",
      data: promedios.map(
        value => Math.round((value / data.length + Number.EPSILON) * 100) / 100
      ),
    },
  ]

  const options = {
    title: {
      text: "Reporte encuesta",
    },
    chart: {
      stacked: !0,
      toolbar: {
        show: 1,
      },
      zoom: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      show: false,
      categories: categories,
      labels: {
        show: true,
        style: {
          fontSize: "14px",
        },
      },
    },
    plotOptions: {
      radar: {
        size: 200,
        polygons: {
          strokeColors: "#e9e9e9",
          fill: {
            colors: ["#f8f8f8", "#fff"],
          },
        },
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    markers: {
      size: 5,
      colors: ["#fff"],
      strokeColor: "#FF4560",
      strokeWidth: 1,
    },
    colors: ["#34c38f"],
    legend: {
      position: "top",
    },
    fill: {
      opacity: 0.3,
    },
  }

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="radar"
        height="480"
        className="apex-charts"
      />
    </React.Fragment>
  )
}

ReportSurveryChart.propTypes = {
  data: PropTypes.array,
}
export default ReportSurveryChart
