import { useState } from "react"
import { PropTypes } from "prop-types"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap"
import classnames from "classnames"
import CoursesWidget from "components/Dashboard/CoursesWidget"
import { Link } from "react-router-dom"

const CoursesByStatus = props => {
  const myCourses = props.courses ?? []
  const showAll = props.all ?? false

  const coursesInProgress =
    myCourses !== undefined
      ? myCourses.filter(
          course =>
            course.estatusCurso === 2 &&
            !course.CourseParticipant?.aproved &&
            course.CourseParticipant?.advancePercentage >= 0
        )
      : []

  const coursesCompleted = myCourses.filter(
    course => course.estatusCurso === 2 && course.CourseParticipant?.aproved
  )
  const coursesFav = myCourses.filter(
    course =>
      course.estatusCurso === 2 && course.CourseParticipant?.favourite === true
  )
  const [activeTab, setActiveTab] = useState(props.initialTab ?? "1")

  const toggleIconCustom = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return props.loading ? (
    <Card>
      <CardBody>
        <div className="mt-3">
          <h2 className="text-center">
            <i className="fa fa-spin fa-spinner"></i> Cargando información...
          </h2>
        </div>
      </CardBody>
    </Card>
  ) : (
    <Card>
      <CardBody>
        <Nav className="icon-tab nav-justified">
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "1",
              })}
              onClick={() => {
                toggleIconCustom("1")
              }}
            >
              <span className="d-none d-sm-block">
                Cursos en progreso ({coursesInProgress.length})
              </span>
              <span className="d-block d-sm-none">
                <i className="fas fa-cogs"></i>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "3",
              })}
              onClick={() => {
                toggleIconCustom("3")
              }}
            >
              <span className="d-none d-sm-block">
                Cursos completados ({coursesCompleted.length})
              </span>
              <span className="d-block d-sm-none">
                <i className="fas fa-cogs"></i>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "4",
              })}
              onClick={() => {
                toggleIconCustom("4")
              }}
            >
              <span className="d-none d-sm-block">
                Cursos favoritos ({coursesFav.length})
              </span>
              <span className="d-block d-sm-none">
                <i className="fas fa-cogs"></i>
              </span>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="p-3 text-muted mt-4">
          <TabPane tabId="1">
            <Row>
              {coursesInProgress.length <= 0 && (
                <Col lg={12} className="text-center">
                  <h5 className="text-center">Sin cursos en progreso...</h5>
                </Col>
              )}
              {coursesInProgress
                .sort((a, b) => {
                  const claveCursoA = isNaN(a.claveCurso)
                    ? 100000
                    : parseInt(a.claveCurso)
                  const claveCursoB = isNaN(b.claveCurso)
                    ? 100000
                    : parseInt(b.claveCurso)
                  return claveCursoA > claveCursoB ? 1 : -1
                })
                .map((course, index) =>
                  index >= 3 && !showAll ? (
                    <div key={`course-progress-${course.idCurso}`}></div>
                  ) : (
                    <Col
                      xl={6}
                      lg={12}
                      md={12}
                      sm={12}
                      key={`course-progress-${course.idCurso}`}
                      className="mb-4"
                    >
                      <CoursesWidget
                        id={course.idCurso}
                        title={course.nombreCurso}
                        category={course.knowledgeCategories
                          .map(category => category.knowledgeCategory.trim())
                          .join(", ")}
                        percentage={course.CourseParticipant.advancePercentage}
                        minutes={course.CourseParticipant.dedicatedTime}
                        image={course.coverImage ?? ""}
                        fav={course.CourseParticipant.favourite}
                        started={course.CourseParticipant.advancePercentage > 0}
                        finished={
                          course.CourseParticipant.advancePercentage >= 100
                        }
                        dateStart={course.CourseParticipant.courseStart}
                        dateFinish={course.CourseParticipant.courseEnd}
                      />
                    </Col>
                  )
                )}
            </Row>
            {coursesInProgress.length > 3 && !showAll && (
              <Row>
                <Col lg={12} className="text-center">
                  <Link to={`my-courses/1`}>Ver más...</Link>
                </Col>
              </Row>
            )}
          </TabPane>
          <TabPane tabId="3">
            <Col lg={12}>
              <Row>
                {coursesCompleted.length <= 0 && (
                  <Col lg={12} className="text-center">
                    <h5 className="text-center">Sin cursos completados...</h5>
                  </Col>
                )}
                {coursesCompleted
                  .sort((a, b) => {
                    const claveCursoA = isNaN(a.claveCurso)
                      ? 100000
                      : parseInt(a.claveCurso)
                    const claveCursoB = isNaN(b.claveCurso)
                      ? 100000
                      : parseInt(b.claveCurso)
                    return claveCursoA > claveCursoB ? 1 : -1
                  })
                  .map((course, index) =>
                    index >= 3 && !showAll ? (
                      <div key={`course-completed-${course.idCurso}`}></div>
                    ) : (
                      <Col
                        xl={6}
                        lg={12}
                        md={12}
                        sm={12}
                        key={`course-completed-${course.idCurso}`}
                        className="mb-4"
                      >
                        <CoursesWidget
                          id={course.idCurso}
                          title={course.nombreCurso}
                          category={course.knowledgeCategories
                            .map(category => category.knowledgeCategory.trim())
                            .join(", ")}
                          percentage={
                            course.CourseParticipant.advancePercentage
                          }
                          minutes={course.tiempoDedicacion}
                          image={course.coverImage ?? ""}
                          fav={course.CourseParticipant.favourite}
                          started={
                            course.CourseParticipant.advancePercentage > 0
                          }
                          finished={
                            course.CourseParticipant.advancePercentage >= 100
                          }
                          dateStart={course.CourseParticipant.courseStart}
                          dateFinish={course.CourseParticipant.courseEnd}
                        />
                      </Col>
                    )
                  )}
              </Row>
              {coursesCompleted.length > 3 && !showAll && (
                <Row>
                  <Col lg={12} className="text-center">
                    <Link to={`my-courses/3`}>Ver más...</Link>
                  </Col>
                </Row>
              )}
            </Col>
          </TabPane>
          <TabPane tabId="4">
            <Row>
              {coursesFav.length <= 0 && (
                <Col lg={12} className="text-center">
                  <h5 className="text-center">Sin cursos favoritos...</h5>
                </Col>
              )}
              {coursesFav
                .sort((a, b) => {
                  const claveCursoA = isNaN(a.claveCurso)
                    ? 100000
                    : parseInt(a.claveCurso)
                  const claveCursoB = isNaN(b.claveCurso)
                    ? 100000
                    : parseInt(b.claveCurso)
                  return claveCursoA > claveCursoB ? 1 : -1
                })
                .map((course, index) =>
                  index >= 3 && !showAll ? (
                    <div key={`course-fav-${course.idCurso}`}></div>
                  ) : (
                    <Col
                      xl={6}
                      lg={12}
                      md={12}
                      sm={12}
                      key={`course-fav-${course.idCurso}`}
                      className="mb-4"
                    >
                      <CoursesWidget
                        id={course.idCurso}
                        title={course.nombreCurso}
                        category={course.knowledgeCategories
                          .map(category => category.knowledgeCategory.trim())
                          .join(", ")}
                        percentage={course.CourseParticipant.advancePercentage}
                        minutes={course.tiempoDedicacion}
                        image={course.coverImage ?? ""}
                        fav={course.CourseParticipant.favourite}
                        started={course.CourseParticipant.advancePercentage > 0}
                        finished={
                          course.CourseParticipant.advancePercentage >= 100
                        }
                        dateStart={course.CourseParticipant.courseStart}
                        dateFinish={course.CourseParticipant.courseEnd}
                      />
                    </Col>
                  )
                )}
            </Row>
            {coursesFav.length > 3 && !showAll && (
              <Row>
                <Col lg={12} className="text-center">
                  <Link to={`my-courses/4`}>Ver más...</Link>
                </Col>
              </Row>
            )}
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  )
}

CoursesByStatus.propTypes = {
  courses: PropTypes.array,
  loading: PropTypes.bool,
  all: PropTypes.bool,
  initialTab: PropTypes.string,
}

export default CoursesByStatus
