import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  MY_PROFILE,
  MY_PROFILE_SUCCESS,
  MY_PROFILE_ERROR,
  MY_PROFILE_RESET,
  ACCEPT_PRIVACY_TYC,
  ACCEPT_PRIVACY_TYC_SUCCESS,
  ACCEPT_PRIVACY_TYC_ERROR,
  ACCEPT_PRIVACY_TYC_RESET,
} from "./actionTypes"

export const me = () => {
  return {
    type: MY_PROFILE,
    payload: {},
  }
}

export const meSuccess = user => {
  return {
    type: MY_PROFILE_SUCCESS,
    payload: { user },
  }
}

export const meError = msg => {
  return {
    type: MY_PROFILE_ERROR,
    payload: { msg },
  }
}

export const meReset = () => {
  return {
    type: MY_PROFILE_RESET,
    payload: {},
  }
}

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}

export const acceptPrivacyTyc = idUsuario => {
  return {
    type: ACCEPT_PRIVACY_TYC,
    payload: {
      idUser: idUsuario,
      tyc: true,
      privacity: true,
    },
  }
}

export const acceptPrivacyTycSuccess = msg => {
  return {
    type: ACCEPT_PRIVACY_TYC_SUCCESS,
    payload: msg,
  }
}

export const acceptPrivacyTycError = error => {
  return {
    type: ACCEPT_PRIVACY_TYC_ERROR,
    payload: error,
  }
}

export const acceptPrivacyTycReset = () => {
  return {
    type: ACCEPT_PRIVACY_TYC_RESET,
    payload: {},
  }
}