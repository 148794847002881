/* eslint-disable no-undef */
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { GET_PROFILES } from "./actionTypes"
import { apiError, getProfilesSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getProfiles } from "../../helpers/backend_helper"

function* profiles() {
  try {
    const response = yield call(getProfiles)
    yield put(getProfilesSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* profilesListSaga() {
  yield takeEvery(GET_PROFILES, profiles)
}

export default profilesListSaga
