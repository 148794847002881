import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Modal, Input, Row, Col, Card } from "reactstrap"
import { Link } from "react-router-dom"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import PropTypes from "prop-types"
import Dropzone from "react-dropzone"
import { addImage, addImageClean } from "store/actions"

const UploadCourseImageModal = props => {
  const [formError, setFormError] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])

  const dispatch = useDispatch()

  const onConfirm = props.onConfirm

  useEffect(() => {
    return () => {
      setFormError(false)
      setSelectedFiles([])
    }
  }, [])

  const { loading, error, done, image } = useSelector(store => ({
    loading: store.ImageAdd.loading,
    error: store.ImageAdd.error,
    done: store.ImageAdd.done,
    image: store.ImageAdd.image,
  }))

  const handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )

    setSelectedFiles(files)
  }

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const handleSubmitFile = async (event, errors, values) => {
    if (selectedFiles.length < 1) {
      setFormError(true)
      return
    }
    setFormError(false)

    const formData = new FormData()
    formData.append("file", selectedFiles[0])

    dispatch(addImage(formData))
  }

  if (done && image != "") {
    onConfirm(image)
    dispatch(addImageClean())
  }

  return (
    <Modal isOpen={props.show} backdrop={"static"} id="staticBackdrop">
      <AvForm
        className="form-horizontal"
        id="file-form"
        onSubmit={handleSubmitFile}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Evidencia de curso finalizado
          </h5>
          {!loading && (
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setFormError(false)
                setSelectedFiles([])
                props.onCancel()
              }}
              aria-label="Close"
            ></button>
          )}
        </div>
        <div className="modal-body">
          {formError && (
            <div className="alert alert-danger">
              Debe elegir un archivo para realizar la carga.
            </div>
          )}
          {error !== "" && <div className="alert alert-danger">{error}</div>}
          <p>
            <strong>
              Por favor, suba la evidencia de finalización del curso
            </strong>
          </p>
          <p>
            La evidencia puede ser una impresión de pantalla donde se muestre
            que ha finalizado el curso correctamente, el archivo de
            certificación del curso (PDF), también puede ser la imagen del libro
            finalizado.
          </p>
          <p>
            Puede subir en formato imagen (PNG y JPG) o un documento (PDF). No
            más de 5 MB de tamaño.
          </p>
          <Dropzone
            accept={["image/*", ".pdf"]}
            maxFiles={1}
            onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone">
                <div className="dz-message needsclick" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="mb-3">
                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                  </div>
                  <h4>
                    Arrastra aquí tu archivo, o da clic para seleccionarlo.
                  </h4>
                </div>
              </div>
            )}
          </Dropzone>
          {selectedFiles.map((f, i) => {
            return (
              <Card
                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                key={i + "-file"}
              >
                <div className="p-2">
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      {image ? (
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={f.name}
                          src={f.preview}
                        />
                      ) : (
                        <i className="fa fa-file-pdf fa-2x"></i>
                      )}
                    </Col>
                    <Col>
                      <Link to="#" className="text-muted font-weight-bold">
                        {f.name}
                      </Link>
                      <p className="mb-0">
                        <strong>{f.formattedSize}</strong>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Card>
            )
          })}
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            className={`btn btn-secondary ${loading ? "disabled" : ""}`}
          >
            {loading ? "Cargando, por favor espera..." : "Sí, completado"}
          </button>
          {!loading && (
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setFormError(false)
                setSelectedFiles([])
                props.onCancel()
              }}
            >
              No, cancelar
            </button>
          )}
        </div>
      </AvForm>
    </Modal>
  )
}

UploadCourseImageModal.propTypes = {
  show: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  image: PropTypes.bool,
}

export default UploadCourseImageModal
