import PropTypes from "prop-types"
import { Fragment } from "react"

const EventProcessBox = props => {
  const getProcess = (number, text) => {
    return (
      <div className={`row ${props.active == number ? "active" : "inactive"}`}>
        <div className="col-md-3">
          <div className="event-process-number d-flex justify-content-center align-items-center align-content-center">
            {number}
          </div>
        </div>
        <div className="col-md-9">
          <p className="mb-0">
            {props.active == number ? <strong>{text}</strong> : text}
          </p>
          <small>Paso {number}</small>
        </div>
      </div>
    )
  }
  return (
    <Fragment>
      <p>{props.legend}</p>
      <br />
      <div className="row event-process-box">
        <div className="col-md-4">{getProcess(1, "Define evento")}</div>
        <div className="col-md-4">{getProcess(2, "Ingresa promoción")}</div>
        <div className="col-md-4">{getProcess(3, "Revisa y publica")}</div>
      </div>
      <style jsx="true">{`
        .event-process-number {
          border: 1px solid rgb(0, 85, 150);
          border-radius: 50%;
          width: 40px;
          height: 40px;
        }
        div.active p,
        div.active small,
        div.active .event-process-number {
          color: rgb(0, 85, 150);
        }
        div.inactive p,
        div.inactive small,
        div.inactive .event-process-number {
          font-weight: thin;
          color: #cccccc;
        }
      `}</style>
    </Fragment>
  )
}

EventProcessBox.propTypes = {
  legend: PropTypes.string,
  active: PropTypes.number,
}

export default EventProcessBox
