import React from "react"
import PropTypes from "prop-types"
import ReactApexChart from "react-apexcharts"

const Report1Chart = ({ data }) => {
  const categories =
    data !== undefined
      ? data.map(
          element => `C.${element.IdHenkelCurso} - ${element.nombre_curso}`
        )
      : []

  let vecesCursado = []
  let tiempoPromedio = []

  if (data !== undefined)
    data.forEach(element => {
      vecesCursado.push(element.total_veces_tomaron_curso_para_aprobar)
      tiempoPromedio.push(
        Math.round((element.tiempo_dedicado_promedio + Number.EPSILON) * 100)
      )
    })

  const series = [
    { type: "column", name: "Veces cursado", data: vecesCursado },
    {
      type: "line",
      name: "Tiempo de dedicación promedio",
      data: tiempoPromedio,
    },
  ]

  const options = {
    title: {
      text: "Gráfica 1: Veces cursado / Tiempo aprobación",
    },
    chart: {
      stacked: !0,
      toolbar: {
        show: 1,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "15%",
        // endingShape: "rounded"
      },
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [1],
    },
    xaxis: {
      show: true,
      categories: categories,
      labels: {
        show: false,
        style: {
          fontSize: "14px",
        },
      },
    },
    labels: ["Curso"],
    yaxis: [
      {
        title: {
          text: "Veces cursado",
          style: {
            fontSize: "14px",
          },
        },
      },
      {
        opposite: true,
        title: {
          text: "Tiempo de dedicación promedio",
          style: {
            fontSize: "14px",
          },
        },
      },
    ],
    colors: ["#34c38f", "#f46a6a"],
    legend: {
      position: "top",
    },
    fill: {
      opacity: 1,
    },
  }

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={[...series]}
        type="line"
        height="420"
        className="apex-charts"
      />
    </React.Fragment>
  )
}

Report1Chart.propTypes = {
  data: PropTypes.array,
}
export default Report1Chart
