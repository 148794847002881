import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import SweetAlert from "react-bootstrap-sweetalert"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Row, Col, Card, CardBody, CardTitle, Modal, Input } from "reactstrap"
import Switch from "react-switch"
import Select from "react-select"
import {
  editCourseClean,
  getGroups,
  listGroupCourses,
  listGroupCoursesClean,
  editCourseStatus,
  addGroupCourse,
  removeGroupCourse,
  listCourseGroups,
} from "store/actions"
import { Link, withRouter } from "react-router-dom"

const CoursePublish = props => {
  const course = props.course
  const courseId = props.course.courseId

  const dispatch = useDispatch()

  const {
    completeCourse,
    groups,
    groupCourses,
    updatedCourseError,
    updatedCourseDone,
    updatedCourse,
  } = useSelector(store => ({
    completeCourse: store.CourseGet.course,
    groups: store.GroupsList.groups,
    groupCourses: store.GroupCoursesList.groupCourses,
    updatedCourseError: store.CourseEdit.error,
    updatedCourseDone: store.CourseEdit.done,
    updatedCourse: store.CourseEdit.course,
  }))

  const [status, setStatus] = useState()
  const [selectedMulti, setSelectedMulti] = useState([])
  const [formError, setFormError] = useState(false)

  useEffect(() => {
    dispatch(listCourseGroups(courseId))
    dispatch(getGroups())
    return () => {
      dispatch(listGroupCoursesClean())
    }
  }, [courseId])

  useEffect(() => {
    setStatus(course.status === 2 ? true : false)
    return () => {}
  }, [course.status])

  useEffect(() => {
    if (groupCourses !== undefined && groups !== undefined) {
      let gpo = []
      groups.forEach((value, index) => {
        const group = groupCourses.find(g => {
          return g.groupId === value.idGrupo
        })
        if (group !== undefined)
          gpo.push({
            label: `${value.nombreGrupo}`,
            value: value.idGrupo,
          })
      })
      setSelectedMulti(gpo)
    }
  }, [groups, groupCourses])

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    )
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Sí
      </div>
    )
  }

  const handleMulti = event => {
    event.forEach(evt => {
      const id = evt.value
      const exists = selectedMulti.some(selected => id === selected.value)
      if (!exists) handleAddGroup(id)
    })
    selectedMulti.forEach(sel => {
      const id = sel.value
      const exists = event.some(selected => id === selected.value)
      if (!exists) handleRemoveGroup(id)
    })
    setSelectedMulti(event)
  }

  const groupOptions =
    groups !== undefined
      ? groups.map(group => {
          return {
            label: `${group.nombreGrupo}`,
            value: group.idGrupo,
          }
        })
      : []

  const optionGroup = [
    {
      label: "Grupos",
      options: groupOptions,
    },
  ]

  const handleSubmit = (event, errors, values) => {
    if (errors.length > 0) {
      setFormError(true)
      return
    }
    setFormError(false)

    dispatch(editCourseStatus(course.courseId, status ? 2 : 1))
  }

  const handleAddGroup = idGroup => {
    const request = {
      idGroup: idGroup,
      idCourse: props.course.courseId,
    }

    dispatch(addGroupCourse(request))
  }

  const handleRemoveGroup = idGroup => {
    const request = {
      idGroup: idGroup,
      idCourse: props.course.courseId,
    }

    dispatch(removeGroupCourse(request))
  }

  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody>
            <AvForm className="form-horizontal" onSubmit={handleSubmit}>
              <Row className="mt-4">
                <Col md={10} className="">
                  <h4 className="pb-3">¿Desea publicar el curso?</h4>
                  <p>
                    Si el curso es publicado, podrá ser elegible para agregarlo
                    a grupos para que los participantes lo puedan tomar.
                  </p>
                  <p>
                    Si no desea publicar el curso ahora, podrá seguir editándolo
                    y más adelante lo podrá publicar
                  </p>
                </Col>
                <Col md={2} className="d-flex align-content-center">
                  <Switch
                    uncheckedIcon={<Offsymbol />}
                    checkedIcon={<OnSymbol />}
                    className="me-1 mb-sm-8 mb-2"
                    onColor={"#ed1c2e"}
                    onChange={() => {
                      setStatus(!status)
                    }}
                    checked={status}
                  />
                </Col>
              </Row>
              <Row className="mb-3 mt-4">
                <Col className="users-group" md={{ size: 11 }}>
                  <h4>Agregar curso a grupos</h4>
                  <Select
                    value={selectedMulti}
                    isMulti={true}
                    onChange={event => {
                      handleMulti(event)
                    }}
                    options={optionGroup}
                    classNamePrefix="select2-selection"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={9}></Col>
                <Col>
                  <button type="submit" className="btn btn-secondary btn-block">
                    Finalizar <i className="fa fa-arrow-right"></i>
                  </button>
                </Col>
                <Col>
                  <Link to="/courses" className="btn btn-light btn-block">
                    <i className="fa fa-undo"></i> Salir
                  </Link>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>
      </Col>
      {updatedCourseDone && (
        <SweetAlert
          title="Curso finalizado exitosamente."
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            dispatch(editCourseClean())
            props.history.push("/courses")
          }}
        >
          <p>
            El estatus del curso es {!status ? '"En edición"' : '"Publicado"'}.
          </p>
        </SweetAlert>
      )}
    </Row>
  )
}

CoursePublish.propTypes = {
  course: PropTypes.object,
  history: PropTypes.object,
}

export default withRouter(CoursePublish)
