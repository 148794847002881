/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { REVIEW_SOFTSKILL_COURSE_USER } from "./actionTypes"
import {
  reviewSoftskillCourseUserError,
  reviewSoftskillCourseUserSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { reviewSoftskillsCourseUser } from "../../../helpers/backend_helper"

function* review(action) {
  try {
    const response = yield call(reviewSoftskillsCourseUser, action.payload.form)
    if (response == undefined) throw new Error("API error")
    if (!response.courseId) throw new Error("Error al guardar al cliente")
    yield put(reviewSoftskillCourseUserSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(reviewSoftskillCourseUserError(error.message))
  }
}

function* softskillCourseUserReviewSaga() {
  yield takeEvery(REVIEW_SOFTSKILL_COURSE_USER, review)
}

export default softskillCourseUserReviewSaga
