import React, { Component, Fragment, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb2"
import SoftskillsCoursesListTable from "components/SoftSkills/Courses/SoftskillsCoursesListTable"
import SoftskillsCoursesForm from "components/SoftSkills/Courses/SoftskillsCoursesForm"
import { useDispatch } from "react-redux"
import { addSoftskillCourseClean } from "store/actions"
import SweetAlert from "react-bootstrap-sweetalert"

const SoftskillsAddCourse = props => {
  const dispatch = useDispatch()

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Nuevo curso | Soft Skills</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Nuevo curso</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  { key: "dashboard", title: "Inicio", link: "/soft-skills" },
                  {
                    key: "courses",
                    title: "Cursos",
                    link: "/soft-skills/adm-courses",
                  },
                  { key: "add", title: "Nuevo curso" },
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <SoftskillsCoursesForm
                    onDone={() => {
                      dispatch(addSoftskillCourseClean())
                      props.history.push("/soft-skills/adm-courses")
                    }}
                    onCancel={() => {
                      dispatch(addSoftskillCourseClean())
                      props.history.push("/soft-skills/adm-courses")
                    }}
                    insert={true}
                    course={{
                      courseId: 0,
                      courseName: "",
                      status: true,
                      abilityId: 0,
                      modalityId: 0,
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

SoftskillsAddCourse.propTypes = {
  history: PropTypes.object,
}

export default withRouter(SoftskillsAddCourse)
