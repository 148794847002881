import { Fragment } from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import PropTypes from "prop-types"
import EventDetail from "./EventDetail"
import moment from "moment"

const EventsTree = props => {
  return (
    <Fragment>
      <Row>
        {props.events.length <= 0 && (
          <Col xs="12">
            <h4>No hay eventos disponibles</h4>
          </Col>
        )}
        {props.events
          .sort((a, b) => {
            const startDateA = moment(new Date(a.startDate))
            const startDateB = moment(new Date(b.startDate))
            return startDateA.isAfter(startDateB) ? 1 : -1
          })
          .map((singleEvent, key) => {
            return (
              <Col sm={6} xs={12} md={6} lg={4} xl={3} key={key}>
                <EventDetail singleEvent={singleEvent} />
              </Col>
            )
          })}
      </Row>

      <style jsx>{`
        .line-left {
          width: 100%;
          height: 1px;
          background-color: #000;
        }
        .line-right {
          width: 100%;
          height: 1px;
          background-color: #000;
        }
        .line-center {
          width: 1px;
          height: 100%;
          background-color: #000;
        }
        .row-eventos {
          display: table;
        }
        .row-eventos [class*="col-"] {
          float: none;
          display: table-cell;
          vertical-align: top;
        }
      `}</style>
    </Fragment>
  )
}

EventsTree.propTypes = {
  events: PropTypes.array,
}

export default EventsTree
