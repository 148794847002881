import React, { useState, useEffect, Fragment } from "react"
import PropTypes from "prop-types"
import { Row, Col, Button, Input, Label } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { removeDiacritics } from "common/strings"

import {
  getProfiles,
  getCustomers,
  addUser,
  addUserClean,
  editUser,
  getGroups,
  getPositions,
  getUser,
  getUsers,
  getUsersByRole,
  editUserClean,
} from "store/actions"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  AvForm,
  AvField,
  AvInput,
  AvGroup,
} from "availity-reactstrap-validation"

const HubUsersCmo = props => {
  const insert = props.insert ?? true
  const id = props.id ?? 0

  const [record, setRecord] = useState(false)

  const [formError, setFormError] = useState(false)
  const [ocupationField, setOcupationField] = useState(false)
  const [n4Field, setN4Field] = useState(false)
  const [n5Field, setN5Field] = useState(false)
  const [n6Field, setN6Field] = useState(false)
  const [n7Field, setN7Field] = useState(false)
  const [profile, setProfile] = useState(0)
  const [filteredGroups, setFilteredGroups] = useState([])
  const [group, setGroup] = useState(0)
  const [userByRoleTag, setUserByRoleTag] = useState("")

  const dispatch = useDispatch()

  const {
    users,
    usersByRole,
    profiles,
    groups,
    positions,
    apiClients,
    loading,
    done,
    user,
    insertedUser,
    insertedUserError,
    getUserLoading,
    getUserDone,
    editUserLoading,
    editUserError,
    editUserDone,
    whoIAm,
  } = useSelector(state => ({
    users: state.UsersList.users,
    usersByRole: state.UsersList.usersByRole,
    profiles: state.ProfilesList.profiles,
    groups: state.GroupsList.groups,
    positions: state.PositionsList.positions,
    apiClients: state.CustomersList.customers,
    loading: state.UserAdd.loading,
    done: state.UserAdd.done,
    insertedUser: state.UserAdd.user,
    insertedUserError: state.UserAdd.error,
    user: state.UserEdit.user,
    getUserLoading: state.UserEdit.loading,
    getUserDone: state.UserEdit.done,
    editUserLoading: state.UserEdit.updateLoading,
    editUserDone: state.UserEdit.updateDone,
    editUserError: state.UserEdit.error,
    whoIAm: state.Profile.user,
  }))

  const userId = whoIAm?.idUsuario ?? 0
  const profileId = whoIAm?.idRol ?? 0

  const userInfo =
    !insert && user !== undefined
      ? { ...user, name: user.nombreCliente }
      : {
          firstName: "",
          lastName: "",
          email: "",
          positionId: 0,
          groupId: group,
          profileId: 0,
          idh: 0,
          clientName: "",
          n4Assigned: 0,
          n5Assigned: 0,
          n6Assigned: 0,
        }

  const idRol = userInfo.profileId

  let clients = []
  if (profileId === 7 && apiClients.length > 0) {
    const idh = whoIAm?.idhCliente
    clients = apiClients.filter(client => client.idh === idh)
  } else {
    clients = apiClients
  }

  const changeProfile = evt => {
    const profileId = parseInt(evt.target.value)
    setVisibleFields(profileId)
  }

  const setVisibleFields = profileId => {
    setProfile(profileId)
    switch (profileId) {
      case 1:
        setOcupationField(false)
        setN4Field(false)
        setN5Field(false)
        setN6Field(false)
        setN7Field(false)
        break
      case 2:
        setOcupationField(false)
        setN4Field(false)
        setN5Field(false)
        setN6Field(false)
        setN7Field(false)
        break
      case 3:
        setOcupationField(true)
        setN4Field(false)
        setN5Field(false)
        setN6Field(false)
        setN7Field(false)
        dispatch(getPositions())
        break
      case 4:
        setOcupationField(true)
        setN4Field(false)
        setN5Field(false)
        setN6Field(false)
        setN7Field(false)
        dispatch(getPositions())
        break
      case 5:
        setOcupationField(true)
        setN4Field(true)
        setN5Field(false)
        setN6Field(false)
        setN7Field(false)
        setUserByRoleTag("Director N4")
        dispatch(getPositions())
        dispatch(getUsersByRole(4))
        break
      case 6:
        setOcupationField(true)
        setN4Field(false)
        setN5Field(true)
        setN6Field(false)
        setN7Field(false)
        setUserByRoleTag("Director N5")
        dispatch(getPositions())
        dispatch(getUsersByRole(5))
        break
      case 7:
        setOcupationField(true)
        setN4Field(false)
        setN5Field(false)
        setN6Field(true)
        setN7Field(false)
        setUserByRoleTag("Interno N6")
        dispatch(getPositions())
        dispatch(getCustomers())
        dispatch(getUsersByRole(6))
        break
      case 8:
        setOcupationField(true)
        setN4Field(false)
        setN5Field(false)
        setN6Field(false)
        setN7Field(true)
        dispatch(getPositions())
        dispatch(getCustomers())
        break
    }
  }

  useEffect(() => {
    if (groups === undefined) {
      return
    }
    let filtered = []
    switch (profile) {
      case 6:
        filtered = groups.filter(element => {
          return element.nombreGrupo.toLowerCase().includes("fuerza de ventas")
        })
        setFilteredGroups(filtered)
        break
      case 7:
      case 8:
        filtered = groups.filter(element => {
          return (
            element.nombreGrupo
              .toLowerCase()
              .includes("red de distribuidores") ||
            element.nombreGrupo.toLowerCase().includes("ferreteros") ||
            element.nombreGrupo.toLowerCase().includes("team fester thd") ||
            element.nombreGrupo.toLowerCase().includes("agencias")
          )
        })
        setFilteredGroups(filtered)
        break
      default:
        filtered = groups.filter(element => {
          return (
            !element.nombreGrupo.toLowerCase().includes("fuerza de ventas") &&
            !element.nombreGrupo
              .toLowerCase()
              .includes("red de distribuidores") &&
            !element.nombreGrupo.toLowerCase().includes("ferreteros") &&
            !element.nombreGrupo.toLowerCase().includes("team fester thd") &&
            !element.nombreGrupo.toLowerCase().includes("agencias")
          )
        })
        setFilteredGroups(filtered)
        break
    }
  }, [profile, groups])

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    profile: 0,
    occupation: 0,
    managerId: 0,
    customerId: 0,
    customerName: "",
  })

  useEffect(() => {
    if (userId !== 0) {
      dispatch(getUsers(userId))
    }
  }, [userId])

  useEffect(() => {
    dispatch(getProfiles())
    dispatch(getGroups())
    if (!insert) {
      dispatch(getUser(id))
      setVisibleFields(idRol)
    }
    return () => {
      dispatch(addUserClean())
    }
  }, [id, idRol])

  useEffect(() => {
    if (userInfo !== undefined && userInfo.groupId !== undefined) {
      setGroup(userInfo.groupId)
    }
  }, [userInfo.groupId])

  const validateGroup = event => {
    if (profile === 7) {
      const selectedUser = users.find(
        user => parseInt(user.userId) === parseInt(event.target.value)
      )
      setGroup(selectedUser.groupId)
    }
  }

  const handleSubmit = (event, errors, values) => {
    if (!record) {
      props.onConfirm({})
      return
    }

    //dispatch(editUserClean())
    if (errors.length > 0) {
      setFormError(true)
      return
    }
    setFormError(false)
    const profileId = parseInt(values.profile)
    const occupation =
      profileId != 1 && profileId != 2 ? parseInt(values.occupation) : null
    const userByRole =
      profileId == 5 || profileId == 6 || profileId == 7
        ? parseInt(values.userByRole)
        : null
    //const idh = profileId == 7 ? values.idh : null
    let customerName = ""
    let idhCustomer = values.idhCustomer

    if (profileId == 7 || profileId == 8) {
      const selectedClient = clients.filter(
        element => element.idh === idhCustomer
      )
      customerName = selectedClient[0].name
    }

    let request = {
      idRol: profileId,
      idGrupo: parseInt(values.group),
      returnUri: `${process.env.REACT_APP_URL}/restore-password`,
      userId: userId,
    }

    if (occupation !== null) {
      request = { ...request, idPosicion: occupation }
    }
    if (userByRole !== null && profileId == 5) {
      request = { ...request, idUserN4assigned: userByRole }
    }
    if (userByRole !== null && profileId == 6) {
      request = { ...request, idUserN5assigned: userByRole }
    }
    if (userByRole !== null && profileId == 7) {
      request = { ...request, idUserN6assigned: userByRole }
    }
    if (idhCustomer !== null) {
      request = { ...request, idhCliente: parseInt(idhCustomer) }
    }
    if (customerName !== null) {
      request = { ...request, nombreCliente: customerName }
    }

    if (!insert) {
      request = {
        ...request,
        idUsuario: id,
        idStatus: userInfo.idStatus,
      }
    }

    props.onConfirm(request)
  }

  return (
    <AvForm className="form-horizontal" onSubmit={handleSubmit}>
      {insert && insertedUserError !== "" && (
        <div className="alert alert-danger">
          Ocurrió un error al registrar usuario: {insertedUserError.error}
        </div>
      )}
      {!insert && editUserError !== "" && (
        <div className="alert alert-danger">
          Ocurrió un error al editar usuario: {editUserError.error}
        </div>
      )}
      <Row>
        <Col>
          <h3 className="mb-4">Certificación de Mano de Obra Fester</h3>
        </Col>
      </Row>
      <Row>
        <Col size={{ span: 4, offset: 4 }} className="mb-4">
          <AvGroup check>
            <AvInput
              name="celf"
              type="checkbox"
              onChange={value => setRecord(value.target.checked)}
            />
            <Label for="celf">
              El usuario será participante en esta aplicación.{" "}
              <a
                href="https://www.henkel.mx/declaracion-de-proteccion-de-datos#pageID=560414"
                target="_blank"
                rel="noreferrer"
              >
                Leer TyC
              </a>
              .
            </Label>
          </AvGroup>
        </Col>
      </Row>
      {false && (
        <Fragment>
          <Row className="mb-3">
            <Col md={12}>
              {usersByRole !== undefined && (
                <AvField
                  name="profile"
                  value={userInfo.profileId}
                  label="* Perfil:"
                  className="form-control"
                  placeholder=""
                  type="select"
                  onChange={event => {
                    changeProfile(event)
                  }}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Este campo es requerido",
                    },
                  }}
                >
                  <option key={0} value="">
                    -- Seleccione perfil --
                  </option>
                  {profiles.map((element, index) => (
                    <option value={element.idRol} key={element.idRol}>
                      {element.perfil}
                    </option>
                  ))}
                </AvField>
              )}
            </Col>
          </Row>
          {(n4Field || n5Field || n6Field) && (
            <Row className="mb-3">
              <Col md={12}>
                {usersByRole !== undefined && (
                  <AvField
                    onChange={value => validateGroup(value)}
                    name="userByRole"
                    value={
                      idRol === 5
                        ? userInfo.n4Assigned
                        : idRol === 6
                        ? userInfo.n5Assigned
                        : userInfo.n6Assigned
                    }
                    label={`* ${userByRoleTag}`}
                    className="form-control"
                    placeholder=""
                    type="select"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Este campo es requerido",
                      },
                    }}
                  >
                    <option>-- Seleccione usuario --</option>
                    {usersByRole
                      .sort((a, b) =>
                        removeDiacritics(a.firstName.toLowerCase()) >
                        removeDiacritics(b.firstName.toLowerCase())
                          ? 1
                          : -1
                      )
                      .map((element, index) => (
                        <option value={element.userId} key={element.userId}>
                          {element.firstName} {element.lastName}
                        </option>
                      ))}
                  </AvField>
                )}
              </Col>
            </Row>
          )}
          {(n6Field || n7Field) && (
            <Row className="mb-3">
              <Col md={12}>
                {clients !== undefined && (
                  <AvField
                    name="idhCustomer"
                    value={userInfo.idh}
                    label={`* Razón social`}
                    className="form-control"
                    placeholder=""
                    type="select"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Este campo es requerido",
                      },
                    }}
                  >
                    <option>-- Seleccione razón social del cliente --</option>
                    {clients
                      .sort((a, b) =>
                        removeDiacritics(a.name.toLowerCase()) >
                        removeDiacritics(b.name.toLowerCase())
                          ? 1
                          : -1
                      )
                      .map((element, index) => (
                        <option value={element.idh} key={element.idh}>
                          {element.name}
                        </option>
                      ))}
                  </AvField>
                )}
              </Col>
            </Row>
          )}
          {ocupationField && (
            <Row className="mb-3">
              <Col md={12}>
                {positions !== undefined && (
                  <AvField
                    name="occupation"
                    value={userInfo.positionId}
                    label="* Ocupación:"
                    className="form-control"
                    placeholder=""
                    type="select"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Este campo es requerido",
                      },
                    }}
                  >
                    <option>-- Seleccione ocupación --</option>
                    {positions
                      .sort((a, b) =>
                        removeDiacritics(a.posicion.toLowerCase()) >
                        removeDiacritics(b.posicion.toLowerCase())
                          ? 1
                          : -1
                      )
                      .map((element, index) => (
                        <option
                          value={element.idPosicion}
                          key={element.idPosicion}
                        >
                          {element.posicion}
                        </option>
                      ))}
                  </AvField>
                )}
              </Col>
            </Row>
          )}
          <Row className="mb-3">
            <Col md={6}>
              {groups !== undefined && (
                <AvField
                  name="group"
                  value={group}
                  defaultValue={group}
                  label="* Grupo:"
                  className="form-control"
                  placeholder=""
                  type="select"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Este campo es requerido",
                    },
                  }}
                >
                  <option value="">-- Seleccione grupo --</option>
                  <option value={0} key={0}>
                    Ninguno
                  </option>
                  {filteredGroups
                    .sort((a, b) =>
                      removeDiacritics(a.nombreGrupo.toLowerCase()) >
                      removeDiacritics(b.nombreGrupo.toLowerCase())
                        ? 1
                        : -1
                    )
                    .map((element, index) => (
                      <option value={element.idGrupo} key={element.idGrupo}>
                        {element.nombreGrupo}
                      </option>
                    ))}
                </AvField>
              )}
            </Col>
          </Row>
        </Fragment>
      )}
      <Row className="mb-3">
        <Col xl={8} lg={8} md={8} sm={8} xs={0}></Col>
        <Col xl={2} lg={2} md={2} sm={2} xs={6}>
          <Button
            className={`float-right ${loading ? "disabled" : ""} btn-block`}
            type="submit"
          >
            {!loading && <span>Siguiente</span>}
            {loading && <span>Guardando...</span>}
          </Button>
        </Col>
        <Col xl={2} lg={2} md={2} sm={2} xs={6}>
          <button
            type="button"
            onClick={() => props.onCancel()}
            className="btn btn-warning btn-block"
          >
            Regresar
          </button>
        </Col>
      </Row>
      {done && insert && insertedUserError === "" && (
        <SweetAlert
          title="Usuario registrado exitosamente"
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            dispatch(addUserClean())
            props.history.push("/users")
          }}
        >
          <p>
            Hemos enviado un correo electrónico al usuario con las instrucciones
            para acceder a la plataforma.
          </p>
        </SweetAlert>
      )}

      {editUserDone && !insert && editUserError === "" && (
        <SweetAlert
          title="Usuario modificado exitosamente"
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            dispatch(editUserClean())
            props.history.push("/users")
          }}
        >
          <p>El usuario ha sido modificado exitosamente.</p>
        </SweetAlert>
      )}
    </AvForm>
  )
}

HubUsersCmo.propTypes = {
  id: PropTypes.number,
  insert: PropTypes.bool,
  history: PropTypes.object,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default withRouter(HubUsersCmo)
