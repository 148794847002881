import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { Row, Col, Card, CardBody, CardTitle, Modal, Input } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import {
  getLessons,
  getLessonsClean,
  removeLesson,
  removeLessonClean,
  getLevels,
  getUsers,
} from "../../store/actions"
import CoursesLessonsListTable from "./CoursesLessonsListTable"
import CoursesLessonsForm from "./CoursesLessonsForm"
import DeleteLessonModal from "./DeleteLessonModal"
import SweetAlert from "react-bootstrap-sweetalert"
import CourseLessonsPreview from "./CourseLessonsPreview"

const CoursesLessons = props => {
  const course = props.course
  const courseId = props.course.courseId

  const dispatch = useDispatch()

  const [selectedLesson, setSelectedLesson] = useState(undefined)
  const [showPreview, setShowPreview] = useState(false)
  const [addLessonForm, setAddLessonForm] = useState(false)
  const [deleteLesson, setDeleteLesson] = useState(0)

  const { lessons, removeLessonDone, removeLessonError, levels, users } =
    useSelector(store => ({
      lessons: store.LessonsList.lessons,
      removeLessonDone: store.LessonRemove.done,
      removeLessonError: store.LessonRemove.error,
      levels: store.LevelsList.levels,
      users: store.UsersList.users,
    }))

  useEffect(() => {
    dispatch(getLessons(courseId))
    dispatch(getLevels())
    dispatch(getUsers())
    return () => {
      dispatch(getLessonsClean())
    }
  }, [courseId])

  const showDeleteAlert = lesson => {
    setDeleteLesson(lesson.idLesson)
  }

  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody>
            <CardTitle className="mb-3">Lecciones</CardTitle>
            {selectedLesson === undefined && !addLessonForm && !showPreview && (
              <CoursesLessonsListTable
                course={course}
                lessons={lessons}
                onAdd={() => setAddLessonForm(true)}
                onDelete={lesson => showDeleteAlert(lesson)}
                onEdit={lesson => {
                  setSelectedLesson(lesson)
                }}
                onPreview={() => setShowPreview(true)}
              />
            )}

            {showPreview && (
              <Row>
                <div className="d-flex justify-content-end">
                  <button
                    className="btn btn-light"
                    onClick={() => setShowPreview(false)}
                  >
                    <i className="fa fa-times"></i> Cerrar vista previa
                  </button>
                </div>
                <CourseLessonsPreview
                  responsibles={users}
                  levels={levels}
                  lessons={lessons}
                  course={course}
                />
              </Row>
            )}

            {(addLessonForm || selectedLesson !== undefined) && (
              <>
                <CoursesLessonsForm
                  insert={addLessonForm}
                  lessonCount={lessons === undefined ? 0 : lessons.length}
                  course={props.course}
                  lesson={
                    selectedLesson === undefined
                      ? {
                          title: "",
                          minutes: 0,
                        }
                      : selectedLesson
                  }
                  onCancel={() => {
                    dispatch(getLessons(course.courseId))
                    setAddLessonForm(false)
                    setSelectedLesson(undefined)
                  }}
                />
              </>
            )}
          </CardBody>
        </Card>
      </Col>
      <DeleteLessonModal
        show={deleteLesson !== 0}
        error={removeLessonError}
        onCancel={() => {
          dispatch(removeLessonClean())
          setDeleteLesson(0)
        }}
        onConfirm={() => dispatch(removeLesson(deleteLesson))}
      />
      {removeLessonDone && (
        <SweetAlert
          title="Lección removida exitosamente"
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            dispatch(removeLessonClean())
            dispatch(getLessons(course.courseId))
            setDeleteLesson(0)
          }}
        >
          <p>La lección fue removida de forma exitosa.</p>
        </SweetAlert>
      )}
    </Row>
  )
}

CoursesLessons.propTypes = {
  lessons: PropTypes.array,
  course: PropTypes.object,
}

export default CoursesLessons
