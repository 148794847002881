import React, { useState, Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import "../../assets/scss/datatables.scss"
import { useDispatch, useSelector } from "react-redux"
import XlsExporter from "components/Common/XlsExporter"
import { numberWithCommas } from "common/strings"

const Report1ListTable = props => {
  const [page, setPage] = useState(undefined)
  const [sizePerPage, setSizePerPage] = useState(undefined)

  const report = props.report
  const loading = props.loading

  if (report !== undefined) {
    report.forEach(element => {
      element.IdHenkelCurso = parseInt(element.IdHenkelCurso)
    })
  }

  const columns = [
    {
      dataField: "IdHenkelCurso",
      text: "ID Curso Henkel",
      sort: true,
    },
    {
      dataField: "nombre_curso",
      text: "Nombre curso",
      sort: true,
    },
    {
      dataField: "nivel_curso",
      text: "Nivel curso",
      sort: true,
    },
    {
      dataField: "total_aprobados_x_curso",
      text: "Total de veces cursado",
      sort: true,
    },
    {
      dataField: "tiempo_dedicado_promedio",
      text: "Tiempo promedio de dedicación",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <span>
            {Math.round((row.tiempo_dedicado_promedio + Number.EPSILON) * 100) /
              100}
          </span>
        )
      },
    },
  ]

  const pageOptions = {
    sizePerPage: sizePerPage === undefined ? 10 : sizePerPage,
    totalSize: report.length,
    custom: true,
    showTotal: true,
    page: page === undefined ? 1 : page,
    onPageChange: page => {
      setPage(page)
    },
    onSizePerPageChange: sizePerPage => {
      setPage(1)
      setSizePerPage(sizePerPage)
    },
  }

  const defaultSorted = [{ dataField: "IdHenkelCurso", order: "asc" }]

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  return loading && report === undefined ? (
    <div className="mt-3 mb-3">
      <div className="spinner-chase">
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
      </div>
    </div>
  ) : (
    <PaginationProvider
      pagination={paginationFactory(pageOptions)}
      keyField="IdHenkelCurso"
      columns={columns}
      data={report}
    >
      {({ paginationProps, paginationTableProps }) => (
        <ToolkitProvider
          keyField="IdHenkelCurso"
          columns={columns}
          data={report}
        >
          {toolkitProps => (
            <React.Fragment>
              <Row>
                <Col xs={12}>
                  <p>
                    <span>
                      Mostrando{" "}
                      <strong>{numberWithCommas(report.length)}</strong>{" "}
                      elementos
                    </span>
                  </p>
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  <Fragment>
                    <div className="table-responsive">
                      <BootstrapTable
                        keyField={"IdHenkelCurso"}
                        bordered={false}
                        striped={true}
                        defaultSorted={defaultSorted}
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={"thead-light"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                      />
                    </div>
                    {report?.length <= 0 && (
                      <Row>
                        <Col md={12} className="text-center">
                          -- No hay resultados --
                        </Col>
                      </Row>
                    )}
                  </Fragment>
                </Col>
              </Row>

              <Row className="align-items-md-center mt-30">
                <Col className="inner-custom-pagination d-flex">
                  <div className="d-inline">
                    <SizePerPageDropdownStandalone {...paginationProps} />
                  </div>
                  <div className="text-md-right ms-auto">
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </ToolkitProvider>
      )}
    </PaginationProvider>
  )
}

Report1ListTable.propTypes = {
  report: PropTypes.array,
  loading: PropTypes.bool,
}

export default Report1ListTable
