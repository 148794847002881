import {
  FINISH_SOFTSKILLS_USER_COURSE,
  FINISH_SOFTSKILLS_USER_COURSE_SUCCESS,
  FINISH_SOFTSKILLS_USER_COURSE_CLEAN,
  FINISH_SOFTSKILLS_USER_COURSE_ERROR,
} from "./actionTypes"

export const finishSoftskillsUserCourse = form => ({
  type: FINISH_SOFTSKILLS_USER_COURSE,
  payload: { form },
})

export const finishSoftskillsUserCourseSuccess = course => {
  return {
    type: FINISH_SOFTSKILLS_USER_COURSE_SUCCESS,
    payload: { course },
  }
}

export const finishSoftskillsUserCourseClean = () => {
  return {
    type: FINISH_SOFTSKILLS_USER_COURSE_CLEAN,
    payload: {},
  }
}

export const finishSoftskillsUserCourseError = error => {
  return {
    type: FINISH_SOFTSKILLS_USER_COURSE_ERROR,
    payload: error,
  }
}
