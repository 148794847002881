import { Card, CardBody, CardImg } from "reactstrap"
import { PropTypes } from "prop-types"
import { Link } from "react-router-dom"
import fester from "../../assets/images/logo_academia_fester_header.png"

const CourseCard = props => {
  const course = props.course

  return (
    <Card>
      <Link to={`/courses/info/${course.idCurso}`}>
        <CardImg
          className="img-fluid"
          src={course.coverImage == "" ? fester : course.coverImage}
          alt="Imagen"
        />
      </Link>
      <CardBody>
        <Link to={`/courses/info/${course.idCurso}`}>
          <h3 className="alt-celf-color">{course.nombreCurso}</h3>
        </Link>
      </CardBody>
    </Card>
  )
}

CourseCard.propTypes = {
  course: PropTypes.object,
}

export default CourseCard
