import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Row, Container, Col, Card, CardBody, Label } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import Report5ListTable from "components/Reports/Report5ListTable"
import {
  getCourses,
  listReport5,
  listReport5Clean,
  getUsers,
  getGroups,
  getCustomers,
} from "store/actions"
import { LoadingIndicator } from "components/Common/LoadingIndicator"
import XlsExporter from "components/Common/XlsExporter"
import ReportFilters from "components/Reports/ReportFilters"
import Report5Chart from "components/Reports/Report5Chart"

const Report5 = props => {
  const dispatch = useDispatch()

  const [submit, setSubmit] = useState(false)
  const [reportInfo, setReportInfo] = useState([])

  const {
    error,
    loading,
    done,
    report,
    courses,
    users,
    customers,
    groups,
    whoIAm,
  } = useSelector(state => ({
    error: state.Report5List.error,
    loading: state.Report5List.loading,
    report: state.Report5List.report,
    done: state.Report5List.done,
    courses: state.CoursesList.coursesManagement,
    users: state.UsersList.users,
    customers: state.CustomersList.customers,
    groups: state.GroupsList.groups,
    whoIAm: state.Profile.user,
  }))

  const cursos = []

  useEffect(() => {
    dispatch(getCourses())
    dispatch(getUsers())
    dispatch(getGroups())
    dispatch(getCustomers())
  }, [])

  const onSubmit = data => {
    dispatch(listReport5({ ...data, status: true, userId: whoIAm.idUsuario }))
  }

  const getXlsData = report => {
    report.map((element, i) => {
      element.courses.map((course, j) => {
        if (
          cursos.filter(object => object.clave_curso === course.clave_curso)
            .length < 1
        ) {
          cursos.push(course)
        }
      })
    })

    let data = report.map((element, index) => {
      let result = {
        Nombre: `${element.nombre_usuario} ${element.apellido_usuario}`,
        Email: element.Email,
        Grupo: element.nombre_grupo,
      }

      cursos.map(curso => {
        const selected = element.courses.find(
          course => course.clave_curso === curso.clave_curso
        )

        result[`% avance - Curso ${curso.clave_curso}`] =
          selected !== undefined
            ? selected.porcentaje_avance === null
              ? 0
              : selected.porcentaje_avance
            : 0
      })

      return result
    })

    return {
      Reporte5: data,
    }
  }

  if (done) {
    setSubmit(true)
    setReportInfo(report)
    dispatch(listReport5Clean())
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Reporte 5 - % Avance de cursos por usuario | Capacitación en Línea
            Fester
          </title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Reporte 5 - % Avance de cursos por usuario</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  { key: "dashboard", title: "Dashboard", link: "/dashboard" },
                  { key: "Reports", title: "Analítica", link: "/reports" },
                  { key: "Report5", title: "Reporte 5" },
                ]}
              />
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {error !== "" && (
                    <div className="alert alert-danger mb-3">
                      Ocurrió un error al solicitar la información.
                    </div>
                  )}
                  <ReportFilters
                    title={"Reporte 5"}
                    onSubmit={values => onSubmit(values)}
                    xlsButton={
                      <XlsExporter
                        classNames={`btn btn-light mt-2 btn-block ${
                          submit ? "" : "disabled"
                        }`}
                        data={getXlsData(reportInfo)}
                        dataName={"Reporte5"}
                        name="Reporte 5"
                        label="Exportar"
                      />
                    }
                    users={users}
                    courses={courses}
                    customers={customers}
                    groups={groups}
                  />
                  {!loading && !submit && <div style={{ height: 260 }}></div>}
                  {loading && (
                    <div className="d-flex justify-content-center">
                      <LoadingIndicator />
                    </div>
                  )}
                  {!loading && submit && (
                    <div className="mt-4">
                      <Report5Chart data={reportInfo} />
                      <br />
                      <Report5ListTable loading={loading} report={reportInfo} />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Report5
