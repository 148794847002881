import React from "react"
import { Row, Col, Card, CardBody, CardTitle, Progress } from "reactstrap"
import { Link } from "react-router-dom"

const SocialSource = () => {
  const socials = [
    {
      title: "Facebook",
      bgColor: "bg-primary",
      iconClass: "mdi-facebook",
      description: "125",
    },
    {
      title: "Twitter",
      bgColor: "bg-info",
      iconClass: "mdi-twitter",
      description: "112",
    },
    {
      title: "Instagram",
      bgColor: "bg-pink",
      iconClass: "mdi-instagram",
      description: "104",
    },
  ]

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Insignias</CardTitle>
          <div className="text-center">
            <div className="avatar-sm mx-auto mb-4">
              <span className="avatar-title rounded-circle bg-primary bg-soft font-size-24">
                <i className="fa fa-trophy text-primary"></i>
              </span>
            </div>
            <p className="font-16 text-muted mb-2"></p>
            <h5>
              <Link to="#" className="text-dark">
                3
              </Link>
            </h5>
            <p className="text-muted">En tu carrera en la plataforma</p>
            <Link to="#" className="text-primary font-16">
              Learn more <i className="mdi mdi-chevron-right"></i>
            </Link>
          </div>
          <Row className="mt-4">
            <Col xs={8}>
              <p>Impermeabilizador todo terreno</p>
            </Col>
            <Col xs={4}>
              <Progress multi>
                <Progress
                  bar
                  value={100}
                  color="success"
                  style={{ width: `${100}%` }}
                  animated
                ></Progress>
              </Progress>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={8}>
              <p>Curita</p>
            </Col>
            <Col xs={4}>
              <Progress multi>
                <Progress
                  bar
                  value={86}
                  color="primary"
                  style={{ width: `${86}%` }}
                  animated
                ></Progress>
              </Progress>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={8}>
              <p>Aislado de baños</p>
            </Col>
            <Col xs={4}>
              <Progress multi>
                <Progress
                  bar
                  value={67}
                  color="warning"
                  style={{ width: `${67}%` }}
                  animated
                ></Progress>
              </Progress>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default SocialSource
