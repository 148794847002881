import {
  ADD_SUPPORT_MATERIAL_TYPE,
  ADD_SUPPORT_MATERIAL_TYPE_SUCCESS,
  ADD_SUPPORT_MATERIAL_TYPE_CLEAN,
  ADD_SUPPORT_MATERIAL_TYPE_ERROR,
} from "./actionTypes"

export const addSupportMaterialType = (form, request) => ({
  type: ADD_SUPPORT_MATERIAL_TYPE,
  payload: { form, request },
})

export const addSupportMaterialTypeSuccess = supportMaterialType => {
  return {
    type: ADD_SUPPORT_MATERIAL_TYPE_SUCCESS,
    payload: { supportMaterialType },
  }
}

export const addSupportMaterialTypeClean = () => {
  return {
    type: ADD_SUPPORT_MATERIAL_TYPE_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: ADD_SUPPORT_MATERIAL_TYPE_ERROR,
    payload: error,
  }
}
