import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

const SessionFooter = () => {
  return (
    <Col xs={12}>
      <div className="mt-5 text-center celf-footer-login">
        <p>
          © {new Date().getFullYear()} Henkel Capital |{" "}
          <a
            href="https://www.henkel.mx/terminos-de-uso"
            target={"_blank"}
            rel="noreferrer"
          >
            Términos y Condiciones
          </a>{" "}
          |{" "}
          <a
            href="https://www.henkel.mx/declaracion-de-proteccion-de-datos"
            target={"_blank"}
            rel="noreferrer"
          >
            Políticas de privacidad
          </a>{" "}
          |{" "}
          <a href="https://www.faradaygo.com/" target="_blank" rel="noreferrer">
            By Faraday Services
          </a>
        </p>
      </div>
      <style jsx="true">{`
        p {
          color: rgb(215, 215, 215);
          font-size: 18px;
        }
        a {
          color: rgb(215, 215, 215);
        }
        a:hover {
          color: rgb(255, 255, 255);
          text-decoration: underline;
        }
      `}</style>
    </Col>
  )
}

export default SessionFooter
