import PropTypes from "prop-types"
import React, { useEffect, useState, Fragment } from "react"
import MetaTags from "react-meta-tags"
import { useSelector, useDispatch } from "react-redux"
import classnames from "classnames"
import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  CardImg,
  CardBody,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Progress,
  CardFooter,
} from "reactstrap"
import { Link, withRouter } from "react-router-dom"

import classNames from "classnames"

//import Charts
import StackedColumnChart from "../../pages/Dashboard/StackedColumnChart"

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"

import festerImpermeabilizante from "../../assets/images/fester-impermeabilizante.jpg"
import festerConcreto from "../../assets/images/fester-concreto.jpeg"
import festerPisos from "../../assets/images/fester-pisos.jpeg"

// Pages Components
import WelcomeComp from "../../pages/Dashboard/WelcomeComp"
import MonthlyEarning from "../../pages/Dashboard/MonthlyEarning"
import SocialSource from "../../pages/Dashboard/SocialSource"
import ActivityComp from "../../pages/Dashboard/ActivityComp"
import TopCities from "../../pages/Dashboard/TopCities"
import LatestTranaction from "../../pages/Dashboard/LatestTranaction"

import {
  getCourseKnowledges,
  getSkills,
  getCoursesByUser,
} from "../../store/actions"
import CoursesByStatus from "./CoursesByStatus"

const N1 = props => {
  const [activeTab, setActiveTab] = useState("1")
  const dispatch = useDispatch()

  const { knowledges, skills, user, courses, loading } = useSelector(store => ({
    knowledges: store.CourseKnowledgesList.courseKnowledges,
    skills: store.SkillsList.skills,
    user: store.Profile.user,
    courses: store.CoursesList.courses,
    loading: store.CoursesList.loading,
  }))

  const idUser = user?.idUsuario ?? 0

  useEffect(() => {
    dispatch(getCourseKnowledges())
    dispatch(getSkills())
    dispatch(getCoursesByUser(idUser))
  }, [idUser])

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
    dispatch(onGetChartsData(pType))
  }

  const toggleIconCustom = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <Fragment>
      <Row>
        <Col xl="4">
          <WelcomeComp />
          <MonthlyEarning />
        </Col>
        <Col xl="8">
          <Row>
            {/* Reports Render 
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Media>
                          <Media body>
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </Media>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </Media>
                      </CardBody>
                    </Card>
                  </Col>
                ))}*/}
          </Row>

          <Card>
            <CardBody>
              <div className="d-sm-flex flex-wrap">
                <h4 className="card-title mb-4">
                  Estatus de cursos por usuario
                </h4>
                <div className="ms-auto">
                  <ul className="nav nav-pills">
                    <li className="nav-item">
                      <Link
                        to="#"
                        className={classNames(
                          { active: periodType === "weekly" },
                          "nav-link"
                        )}
                        onClick={() => {
                          onChangeChartPeriod("weekly")
                        }}
                        id="one_month"
                      >
                        Semanal
                      </Link>{" "}
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#"
                        className={classNames(
                          { active: periodType === "monthly" },
                          "nav-link"
                        )}
                        onClick={() => {
                          onChangeChartPeriod("monthly")
                        }}
                        id="one_month"
                      >
                        Mensual
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#"
                        className={classNames(
                          { active: periodType === "yearly" },
                          "nav-link"
                        )}
                        onClick={() => {
                          onChangeChartPeriod("yearly")
                        }}
                        id="one_month"
                      >
                        Anual
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <Row className="mt-2 mb-2">
                <Col md={4}>
                  Por perfil:
                  <Input type="select">
                    <option value="">Todos los perfiles</option>
                    <option value="N1">N1</option>
                    <option value="N2">N2</option>
                    <option value="N3">N3</option>
                    <option value="N4">N4</option>
                    <option value="N5">N5</option>
                    <option value="N6">N6</option>
                    <option value="N7">N7</option>
                    <option value="N8">N8</option>
                  </Input>
                </Col>
                <Col md={4}>
                  Zona comercial:
                  <Input type="select">
                    <option value="">Todas las zonas</option>
                    <option value="Norte">Norte</option>
                    <option value="Centro">Centro</option>
                    <option value="Sur">Sur</option>
                  </Input>
                </Col>
                <Col md={4}>
                  Tipo usuario:
                  <Input type="select">
                    <option value="">Todos los tipos</option>
                    <option value="Interno">Interno</option>
                    <option value="Externo">Externo</option>
                  </Input>
                </Col>
              </Row>
              <div className="clearfix"></div>
              <StackedColumnChart periodData={periodData} />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xl="12">
          <Card>
          <CoursesByStatus
                    courses={courses}
                    loading={loading}
                  />
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xl="4">
          <SocialSource />
        </Col>
        <Col xl="4">
          <ActivityComp />
        </Col>

        <Col xl="4">{<TopCities />}</Col>
      </Row>

      <Row>
        <Col lg="12">{/* <LatestTranaction /> */}</Col>
      </Row>
    </Fragment>
  )
}

export default N1
