import {
  GET_COURSE,
  GET_COURSE_SUCCESS,
  GET_COURSE_CLEAN,
  GET_COURSE_USER,
  GET_COURSE_USER_SUCCESS,
  GET_COURSE_USER_CLEAN,
  API_ERROR,
  GET_COURSE_USER_ERROR,
} from "./actionTypes"

export const getCourse = (id, newApi) => {
  return {
    type: GET_COURSE,
    payload: { id, newApi },
  }
}

export const getCourseSuccess = course => {
  return {
    type: GET_COURSE_SUCCESS,
    payload: { course },
  }
}

export const getCourseClean = () => {
  return {
    type: GET_COURSE_CLEAN,
    payload: {},
  }
}

export const getCourseUser = (idCourse, idUser) => {
  return {
    type: GET_COURSE_USER,
    payload: { idCourse, idUser },
  }
}

export const getCourseUserSuccess = course => {
  return {
    type: GET_COURSE_USER_SUCCESS,
    payload: { course },
  }
}

export const getCourseUserClean = () => {
  return {
    type: GET_COURSE_USER_CLEAN,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
export const getCourseUserError = error => {
  return {
    type: GET_COURSE_USER_ERROR,
    payload: error,
  }
}
