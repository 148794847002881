/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { PUBLISH_SOFTSKILLS_COURSE } from "./actionTypes"
import {
  publishSoftskillsCourseError,
  publishSoftskillsCourseSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { changeSoftskillsCourseStatus } from "../../../helpers/backend_helper"

function* get(action) {
  try {
    const response = yield call(changeSoftskillsCourseStatus, action.payload)
    if (response == undefined) throw new Error("API error")
    yield put(publishSoftskillsCourseSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(publishSoftskillsCourseError(error.message))
  }
}

function* softskillCoursePublishSaga() {
  yield takeEvery(PUBLISH_SOFTSKILLS_COURSE, get)
}

export default softskillCoursePublishSaga
