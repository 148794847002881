import PropTypes from "prop-types"
import { Fragment, useState } from "react"
import { Col, Row } from "reactstrap"
import avatar1 from "../../../assets/images/user_without_pic.png"
import {
  getSoftskillsPositionName,
  getSoftskillsProfileName,
  getSoftskillsRegionName,
} from "pages/SoftSkills/Helpers/Helpers"
import { Link } from "react-router-dom/cjs/react-router-dom"
import moment from "moment"

const SoftskillsProfileDashboardBox = ({ user }) => {
  const [showEditModal, setShowEditModal] = useState(undefined)
  return (
    <Fragment>
      <div className="profile-box p-3">
        <Row>
          <Col md={3}>
            <div
              className="mt-2"
              style={{
                position: "relative",
                overflow: "hidden",
                paddingBottom: "100%",
              }}
            >
              <div
                style={{
                  backgroundImage:
                    user.avatar == null
                      ? `url(${avatar1})`
                      : `url(${user.avatar})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  borderRadius: "50%",
                }}
              >
                &nbsp;
              </div>
            </div>
          </Col>
          <Col md={6}>
            <p className="name mb-1">
              {user.firstName} {user.lastName}
            </p>
            <p className="mb-1">
              <strong>Último acceso:</strong>{" "}
              {moment(user.lastActivity).format("DD/MM/YYYY HH:mm")}
            </p>
            <p className="mb-1">
              <strong>Email:</strong> {user.email}
            </p>
            <p className="mb-1">
              <strong>Perfil:</strong>{" "}
              {getSoftskillsProfileName(user.softskillsProfileId)}
            </p>
            <p className="mb-1">
              <strong>Región:</strong>{" "}
              {getSoftskillsRegionName(user.softskillsRegionId)}
            </p>
          </Col>
          <Col md={3} className="">
            <div className="mt-4 mb-2">
              <Link
                className="btn btn-secondary btn-block"
                to={`/soft-skills/my-courses`}
              >
                Mis cursos
              </Link>
            </div>
            <div className="">
              <Link
                className="btn btn-warning btn-block"
                to={`soft-skills/notifications`}
              >
                Notificaciones
              </Link>
            </div>
          </Col>
        </Row>
      </div>
      <style jsx>{`
        .profile-box {
          border-radius: 12px;
          background-color: rgb(234, 240, 246);
        }
        .profile-box .name {
          font-weight: 500;
          font-size: 20px;
        }
      `}</style>
    </Fragment>
  )
}

SoftskillsProfileDashboardBox.propTypes = {
  user: PropTypes.object,
  updateInfo: PropTypes.func,
}

export default SoftskillsProfileDashboardBox
