import {
  GET_SOFTSKILLS_USERS_ALL,
  GET_SOFTSKILLS_USERS_ALL_SUCCESS,
  GET_SOFTSKILLS_USERS_ALL_ERROR,
} from "./actionTypes"

export const getSoftskillsUsersAll = userId => ({
  type: GET_SOFTSKILLS_USERS_ALL,
  payload: { userId },
})

export const getSoftskillsUsersAllSuccess = users => {
  return {
    type: GET_SOFTSKILLS_USERS_ALL_SUCCESS,
    payload: { users },
  }
}

export const getSoftskillsUsersAllError = error => {
  return {
    type: GET_SOFTSKILLS_USERS_ALL_ERROR,
    payload: error,
  }
}
