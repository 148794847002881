import {
  GET_SOFTSKILLS_USER_PROGRESS,
  GET_SOFTSKILLS_USER_PROGRESS_SUCCESS,
  GET_SOFTSKILLS_USER_PROGRESS_ERROR,
  GET_SOFTSKILLS_USER_PROGRESS_CLEAN,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  progress: [],
}

const SoftskillsUserProgressList = (state = initialState, action) => {
  switch (action.type) {
    case GET_SOFTSKILLS_USER_PROGRESS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_SOFTSKILLS_USER_PROGRESS_SUCCESS:
      state = {
        ...state,
        progress: action.payload.progress,
        loading: false,
        done: true,
      }
      break
    case GET_SOFTSKILLS_USER_PROGRESS_CLEAN:
      state = initialState
      break
    case GET_SOFTSKILLS_USER_PROGRESS_ERROR:
      state = { ...state, error: action.payload, loading: false, done: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default SoftskillsUserProgressList
