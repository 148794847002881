import React from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Input,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { Link, useParams } from "react-router-dom"
import UsersForm from "components/Users/UsersForm"

const Reports = props => {
  const { id } = useParams()

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Analítica | Capacitación en Línea Fester</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Analítica</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  { key: "dashboard", title: "Dashboard", link: "/dashboard" },
                  { key: "Reports", title: "Analítica" },
                ]}
              />
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col sm={4} md={4} xs={2} className="mb-4 pb-4 text-center">
                      <Link to="/reports/report1">
                        <i className="fa fa-table fa-list fa-2x"></i>
                        <h4 className="text-center mt-2">Reporte 1</h4>
                      </Link>
                    </Col>
                    <Col sm={4} md={4} xs={2} className="mb-4 pb-4 text-center">
                      <Link to="/reports/report2">
                        <i className="fa fa-table fa-list fa-2x"></i>
                        <h4 className="text-center mt-2">Reporte 2</h4>
                      </Link>
                    </Col>
                    <Col sm={4} md={4} xs={2} className="mb-4 pb-4 text-center">
                      <Link to="/reports/report3">
                        <i className="fa fa-table fa-list fa-2x"></i>
                        <h4 className="text-center mt-2">Reporte 3</h4>
                      </Link>
                    </Col>
                    <Col sm={4} md={4} xs={2} className="mb-4 pb-4 text-center">
                      <Link to="/reports/report4">
                        <i className="fa fa-table fa-list fa-2x"></i>
                        <h4 className="text-center mt-2">Reporte 4</h4>
                      </Link>
                    </Col>
                    <Col sm={4} md={4} xs={2} className="mb-4 pb-4 text-center">
                      <Link to="/reports/report5">
                        <i className="fa fa-table fa-list fa-2x"></i>
                        <h4 className="text-center mt-2">Reporte 5</h4>
                      </Link>
                    </Col>
                    <Col sm={4} md={4} xs={2} className="mb-4 pb-4 text-center">
                      <Link to="/reports/report6">
                        <i className="fa fa-table fa-list fa-2x"></i>
                        <h4 className="text-center mt-2">Reporte 6</h4>
                      </Link>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <style jsx="true">{`
        .fa-Reports {
          font-size: 3rem;
          margin-bottom: 1rem;
        }
      `}</style>
    </React.Fragment>
  )
}

export default Reports
