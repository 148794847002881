/* USERS */
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const API_ERROR = "API_ERROR"
export const GET_USERS_BY_ROLE = "GET_USERS_BY_ROLE"
export const GET_USERS_BY_ROLE_SUCCESS = "GET_USERS_BY_ROLE_SUCCESS"
export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR"
export const CHANGE_PASSWORD_CLEAN = "CHANGE_PASSWORD_CLEAN"
export const SEND_USER_TRASH = "SEND_USER_TRASH"
export const SEND_USER_TRASH_SUCCESS = "SEND_USER_TRASH_SUCCESS"
export const SEND_USER_TRASH_ERROR = "SEND_USER_TRASH_ERROR"
export const SEND_USER_TRASH_CLEAN = "SEND_USER_TRASH_CLEAN"
