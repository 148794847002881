import { Fragment, useState, useEffect } from "react"
import { PropTypes } from "prop-types"
import { Card, CardBody, Row, Col } from "reactstrap"
import {
  AvForm,
  AvRadio,
  AvRadioGroup,
  AvField,
} from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import { tryPoll, tryPollClean } from "store/actions"

const CoursePollForm = props => {
  const idCourse = props.course.idCurso ?? 0
  const dispatch = useDispatch()

  const { done } = useSelector(store => ({
    done: store.PollTry.done,
  }))

  const [answered, setAnswered] = useState(false)
  const [formError, setFormError] = useState(false)
  const [answer1, setAnswer1] = useState(0)
  const [answer2, setAnswer2] = useState(0)
  const [answer3, setAnswer3] = useState(0)
  const [answer4, setAnswer4] = useState(0)
  const [answer5, setAnswer5] = useState(0)
  const [answer6, setAnswer6] = useState(0)
  const [answer7, setAnswer7] = useState(0)
  const [answer8, setAnswer8] = useState(0)
  const [answer9, setAnswer9] = useState("")

  useEffect(() => {
    if (idCourse !== 0) {
      const answer = localStorage.getItem(`myPoll-${idCourse}`)
      if (answer) setAnswered(true)
    }
  }, [idCourse])

  const handleSubmit = (event, errors, values) => {
    if (errors.length > 0) {
      setFormError(true)
      return
    }
    setFormError(false)

    const request = {
      idCourse: idCourse,
      Answers: [
        {
          idCourse: idCourse,
          idQuestion: 1,
          response: answer1.toString(),
        },
        {
          idCourse: idCourse,
          idQuestion: 2,
          response: answer2.toString(),
        },
        {
          idCourse: idCourse,
          idQuestion: 3,
          response: answer3.toString(),
        },
        {
          idCourse: idCourse,
          idQuestion: 4,
          response: answer4.toString(),
        },
        {
          idCourse: idCourse,
          idQuestion: 5,
          response: answer5.toString(),
        },
        {
          idCourse: idCourse,
          idQuestion: 6,
          response: answer6.toString(),
        },
        {
          idCourse: idCourse,
          idQuestion: 7,
          response: answer7.toString(),
        },
        {
          idCourse: idCourse,
          idQuestion: 8,
          response: answer8.toString(),
        },
        {
          idCourse: idCourse,
          idQuestion: 9,
          response: answer9.toString(),
        },
      ],
    }

    dispatch(tryPoll(request))
  }

  if (done) {
    localStorage.setItem(`myPoll-${idCourse}`, "true")
    setAnswered(true)
    dispatch(tryPollClean())
  }

  const getQuestionForm = (question, number, value, onChange) => {
    return (
      <Fragment>
        <p className="mt-3">
          {number}. {question}
        </p>
        {number < 9 ? (
          <AvRadioGroup
            name={`answer${number}`}
            className="form-control"
            onChange={event => onChange(parseInt(event.target.value))}
            value={value}
            validate={{
              required: {
                value: true,
                errorMessage: "Este campo es requerido",
              },
            }}
          >
            <Row className="mb-2 mt-2">
              <Col md={1}>
                <AvRadio value={1} label="1" />
              </Col>
              <Col md={1}>
                <AvRadio value={2} label="2" />
              </Col>
              <Col md={1}>
                <AvRadio value={3} label="3" />
              </Col>
              <Col md={1}>
                <AvRadio value={4} label="4" />
              </Col>
              <Col md={1}>
                <AvRadio value={5} label="5" />
              </Col>
              <Col md={7}>
                * Siendo 1 la calificación más baja y 5 la más alta
              </Col>
            </Row>
          </AvRadioGroup>
        ) : (
          <AvField
            name={`answer${number}`}
            onChange={event => onChange(event.target.value)}
            value={value}
            className="form-control"
            placeholder="Ingrese aquí sus comentarios"
            type="text"
            validate={{
              required: {
                value: true,
                errorMessage: "Este campo es requerido",
              },
            }}
          />
        )}
        <div className="pb-2"></div>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Card>
        <CardBody>
          <h2 className="alt-celf-color">Encuesta de satisfacción</h2>

          {answered ? (
            <div className="alert alert-success">
              <h3 className="text-center pt-2">
                <i className="fa fa-check"></i> Recibimos sus respuestas
                exitosamente.
              </h3>
            </div>
          ) : (
            <AvForm className="form-horizontal" onSubmit={handleSubmit}>
              {formError && (
                <div className="alert alert-danger">
                  Existen errores en el formulario.
                </div>
              )}
              <h4 className="mt-4 mb-3">Contenido</h4>
              {getQuestionForm(
                "¿Consideras que la organización del temario fue adecuada?",
                1,
                answer1,
                value => setAnswer1(value)
              )}
              {getQuestionForm(
                "¿La explicación de los temas fue correcta?",
                2,
                answer2,
                value => setAnswer2(value)
              )}
              {getQuestionForm(
                "¿Qué te pareció el material presentado en el curso?",
                3,
                answer3,
                value => setAnswer3(value)
              )}
              {getQuestionForm(
                "¿Las actividades dentro del curso fueron útiles para reforzar el conocimiento?",
                4,
                answer4,
                value => setAnswer4(value)
              )}
              <h4 className="mt-4 mb-3">Plataforma</h4>
              {getQuestionForm(
                "En cuanto a las herramientas mostradas dentro del curso, ¿Te fueron útiles?",
                5,
                answer5,
                value => setAnswer5(value)
              )}
              {getQuestionForm(
                "¿Como consideras que fue la experiencia dentro de la plataforma (conexión, navegación dentro de la misma)?",
                6,
                answer6,
                value => setAnswer6(value)
              )}
              {getQuestionForm(
                "¿La estructura y diseño de la plataforma le parecieron adecuados?",
                7,
                answer7,
                value => setAnswer7(value)
              )}
              {getQuestionForm(
                "¿En general, como valorarías la experiencia del curso? ",
                8,
                answer8,
                value => setAnswer8(value)
              )}
              {getQuestionForm("Comentarios", 9, answer9, value =>
                setAnswer9(value)
              )}
              <Row className="mb-3 mt-3">
                <Col xl={10} lg={10} md={10} sm={10} xs={0}></Col>
                <Col xl={2} lg={2} md={2} sm={2} xs={6}>
                  <button
                    className={`btn btn-secondary float-right  btn-block`}
                    type="submit"
                  >
                    Guardar
                  </button>
                </Col>
              </Row>
            </AvForm>
          )}
        </CardBody>
      </Card>
    </Fragment>
  )
}

CoursePollForm.propTypes = {
  course: PropTypes.object,
}

export default CoursePollForm
