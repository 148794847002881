import {
  DELETE_EVENT,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_ERROR,
  DELETE_EVENT_CLEAN,
} from "./actionTypes"

export const deleteEvent = id => {
  return {
    type: DELETE_EVENT,
    payload: { id },
  }
}

export const deleteEventSuccess = event => {
  return {
    type: DELETE_EVENT_SUCCESS,
    payload: { event },
  }
}

export const deleteEventClean = () => {
  return {
    type: DELETE_EVENT_CLEAN,
    payload: {},
  }
}

export const deleteEventError = error => {
  return {
    type: DELETE_EVENT_ERROR,
    payload: error,
  }
}
