import {
  UPDATE_SOFTSKILLS_USER,
  UPDATE_SOFTSKILLS_USER_SUCCESS,
  UPDATE_SOFTSKILLS_USER_CLEAN,
  UPDATE_SOFTSKILLS_USER_ERROR,
} from "./actionTypes"

export const updateSoftskillsUser = form => ({
  type: UPDATE_SOFTSKILLS_USER,
  payload: { form },
})

export const updateSoftskillsUserSuccess = course => {
  return {
    type: UPDATE_SOFTSKILLS_USER_SUCCESS,
    payload: { course },
  }
}

export const updateSoftskillsUserClean = () => {
  return {
    type: UPDATE_SOFTSKILLS_USER_CLEAN,
    payload: {},
  }
}

export const updateSoftskillsUserError = error => {
  return {
    type: UPDATE_SOFTSKILLS_USER_ERROR,
    payload: error,
  }
}
