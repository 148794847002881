import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer"
import { PropTypes } from "prop-types"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Table,
} from "reactstrap"
import academiaFester from "assets/images/academia_fester.png"
import pleca from "assets/images/pleca_constancia.png"
import logoHenkel from "assets/images/logo_henkel.png"

const ModalCertificatePdf = props => {
  const styles = StyleSheet.create({
    page: {},
    section: {
      margin: 0,
      padding: 0,
      display: "flex",
      alignItems: "center",
      paddingLeft: "100px",
      paddingRight: "50px",
    },
  })

  return (
    <Modal isOpen={props.open} backdrop={"static"} size="xl">
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          Ver constancia
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            props.onClose()
          }}
          aria-label="Close"
        ></button>
      </div>
      <ModalBody>
        <PDFViewer width={"100%"} height={"560px"}>
          <Document>
            <Page size="A4" style={styles.page}>
              <View
                style={{
                  ...styles.section,
                }}
              >
                <Image
                  src={pleca}
                  style={{
                    height: "900px",
                    width: "100px",
                    position: "absolute",
                    top: -20,
                    left: 0,
                  }}
                />
                <Image
                  src={academiaFester}
                  style={{ height: "155px", width: "200px" }}
                />
                <Text
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    letterSpacing: "4px",
                    color: "rgb(0, 85,150)",
                  }}
                >
                  LA ACADEMIA FESTER®
                </Text>
                <Text style={{ fontSize: "16px", marginTop: "32px" }}>
                  Otorga la presente
                </Text>
                <Text
                  style={{
                    fontSize: "44px",
                    marginTop: "32px",
                    paddingLeft: "32px",
                    paddingRight: "32px",
                    textAlign: "center",
                  }}
                >
                  Constancia de
                </Text>
                <Text
                  style={{
                    fontSize: "44px",
                    paddingLeft: "32px",
                    paddingRight: "32px",
                    textAlign: "center",
                  }}
                >
                  finalización a
                </Text>
                <Text
                  style={{
                    fontSize: "24px",
                    marginTop: "24px",
                    textAlign: "center",
                    textDecoration: "underline",
                  }}
                >
                  {props.user.nombre} {props.user.apellido}
                </Text>
                <Text
                  style={{
                    fontSize: "12px",
                    marginTop: "24px",
                    textAlign: "center",
                  }}
                >
                  Por haber logrado completar el curso
                </Text>
                <Text
                  style={{
                    fontSize: "18px",
                    marginTop: "24px",
                    textAlign: "center",
                    color: "rgb(0, 85,150)",
                    fontWeight: "bold",
                  }}
                >
                  {props.course.nombreCurso}
                </Text>
                <Text
                  style={{
                    fontSize: "14px",
                    marginTop: "12px",
                    textAlign: "center",
                    color: "rgb(0, 0, 0)",
                    fontWeight: "normal",
                  }}
                >
                  Tiempo de dedicación: {props.course.tiempoDedicacion} minutos
                </Text>
                <Text
                  style={{
                    fontSize: "18px",
                    marginTop: "48px",
                    textAlign: "center",
                  }}
                >
                  Te invitamos a continuar con tu
                </Text>
                <Text
                  style={{
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  capacitación técnica.
                </Text>
                <Text
                  style={{
                    fontSize: "24px",
                    marginTop: "24px",
                    textAlign: "center",
                    color: "rgb(0, 85,150)",
                    fontWeight: "bold",
                  }}
                >
                  ¡Yo me capacito en la academia Fester®!
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  flexDirection: "row-reverse",
                  position: "absolute",
                  bottom: 20,
                  right: 20,
                }}
              >
                <Image
                  src={logoHenkel}
                  style={{
                    height: "30px",
                    width: "52px",
                  }}
                />
                <View style={{ paddingRight: "12px", alignItems: "flex-end" }}>
                  <Text
                    style={{
                      fontSize: "10px",
                      fontWeight: 500,
                      textAlign: "right",
                      paddingBottom: "6px",
                    }}
                  >
                    Henkel Capital S.A. de C.V.
                  </Text>
                  <Text
                    style={{
                      fontSize: "6px",
                      textAlign: "right",
                      paddingBottom: "2px",
                    }}
                  >
                    Blvd. Magnocentro No.8 Piso 2, Centro Urbano Interlomas
                    52760 Huixquilucan, Edo. de México.
                  </Text>
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </ModalBody>
    </Modal>
  )
}

ModalCertificatePdf.propTypes = {
  course: PropTypes.object,
  exam: PropTypes.object,
  open: PropTypes.bool,
  user: PropTypes.object,
  onClose: PropTypes.func,
}

export default ModalCertificatePdf
