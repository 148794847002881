import React from "react"
import { Card, CardBody, CardTitle, Media } from "reactstrap"
import { Link } from "react-router-dom"

const ActivityComp = () => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-5">Actividad</CardTitle>
          <ul className="verti-timeline list-unstyled">
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-right-arrow-circle font-size-18" />
              </div>
              <Media>
                <div className="me-3">
                  <h5 className="font-size-14">
                    14 oct{" "}
                    <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                  </h5>
                </div>
                <Media body>
                  <div>Completaste el curso: Impermeabilizantes</div>
                </Media>
              </Media>
            </li>

            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-right-arrow-circle font-size-18" />
              </div>
              <Media>
                <div className="me-3">
                  <h5 className="font-size-14">
                    10 oct{" "}
                    <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                  </h5>
                </div>
                <Media body>
                  <div id="activitytext">
                    Realizaste una aportación en el foro del Curso 2, puedes
                    verlo <Link to="#">aquí</Link>.
                  </div>
                </Media>
              </Media>
            </li>
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bxs-right-arrow-circle font-size-18" />
              </div>
              <Media>
                <div className="me-3">
                  <h5 className="font-size-14">
                    30 sep{" "}
                    <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                  </h5>
                </div>
                <Media body>
                  <div>Obtuviste la insignia &quot;Asociado de baños&quot;</div>
                </Media>
              </Media>
            </li>
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-right-arrow-circle font-size-18" />
              </div>
              <Media>
                <div className="me-3">
                  <h5 className="font-size-14">
                    12 sep{" "}
                    <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                  </h5>
                </div>
                <Media body>
                  <div>Complateaste el curso: Pisos y autonivelasntes</div>
                </Media>
              </Media>
            </li>
          </ul>
          <div className="text-center mt-4">
            <Link to="#" className="btn btn-primary  btn-sm">
              Ver más <i className="mdi mdi-arrow-right ms-1" />
            </Link>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ActivityComp
