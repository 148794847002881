import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import SweetAlert from "react-bootstrap-sweetalert"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Row, Col, Button, Label } from "reactstrap"
import Switch from "react-switch"
import {
  addBusiness,
  addBusinessClean,
  editBusiness,
  editBusinessClean,
} from "../../store/actions"

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 10,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 10,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Sí
    </div>
  )
}

const BusinessForm = props => {
  const business = props.business
  const [formError, setFormError] = useState(false)
  const [status, setStatus] = useState(business.estatus)

  const insert = props.insert ?? true

  const dispatch = useDispatch()

  const {
    loadingAdd,
    doneAdd,
    errorAdd,
    loadingUpdate,
    errorUpdate,
    doneUpdate,
  } = useSelector(state => ({
    loadingAdd: state.BusinessAdd.loading,
    errorAdd: state.BusinessAdd.error,
    doneAdd: state.BusinessAdd.done,
    loadingUpdate: state.BusinessEdit.loading,
    errorUpdate: state.BusinessEdit.error,
    doneUpdate: state.BusinessEdit.done,
  }))

  const handleSubmit = (event, errors, values) => {
    if (errors.length > 0) {
      setFormError(true)
      return
    }

    if (insert) {
      const request = {
        businessName: values.name,
        status: status,
      }
      dispatch(addBusiness(request))
    } else {
      const request = {
        idBusiness: business.idNegocio,
        negocio: values.name,
        status: status,
      }
      dispatch(editBusiness(request))
    }
  }

  return (
    <AvForm className="form-horizontal" onSubmit={handleSubmit}>
      {errorAdd !== "" && (
        <div className="alert alert-danger">
          Ocurrió un error al registrar el negocio: {errorAdd}
        </div>
      )}
      {errorUpdate !== "" && (
        <div className="alert alert-danger">
          Ocurrió un error al edutar el negocio: {errorUpdate}
        </div>
      )}
      {formError && (
        <div className="alert alert-danger">
          Por favor corrija los errores en el formulario antes de continuar.
        </div>
      )}
      <Row className="mb-3">
        <Col md={3}>
          <Label className="col-form-label" for="name">
            * Nombre del negocio:
          </Label>
        </Col>
        <Col md={9}>
          <AvField
            name="name"
            value={business.nombreNegocio}
            className="form-control"
            placeholder=""
            type="text"
            validate={{
              required: {
                value: true,
                errorMessage: "Este campo es requerido",
              },
            }}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={3}>
          <Label className="col-form-label" for="name">
            * Activado:
          </Label>
        </Col>
        <Col md={9}>
          <Switch
            uncheckedIcon={<Offsymbol />}
            checkedIcon={<OnSymbol />}
            className="me-1 mb-sm-8 mb-2"
            onColor={"#ed1c2e"}
            onChange={() => {
              setStatus(!status)
            }}
            checked={status}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xl={8} lg={8} md={8} sm={8} xs={0}></Col>
        <Col xl={2} lg={2} md={2} sm={2} xs={6}>
          <Button className={`float-right  btn-block`} type="submit">
            {!loadingAdd && !loadingUpdate && <span>Guardar</span>}
            {(loadingAdd || loadingUpdate) && <span>Guardando...</span>}
          </Button>
        </Col>
        <Col xl={2} lg={2} md={2} sm={2} xs={6}>
          <button
            type="button"
            onClick={() => props.onCancel()}
            className="btn btn-light btn-block"
          >
            Cancelar
          </button>
        </Col>
      </Row>
      {doneAdd && insert && errorAdd === "" && (
        <SweetAlert
          title="Negocio registrado exitosamente"
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            props.onDone()
          }}
        >
          <p>El negocio fue registrado exitosamente.</p>
        </SweetAlert>
      )}
      {doneUpdate && !insert && errorUpdate === "" && (
        <SweetAlert
          title="Negocio actualizado exitosamente"
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            props.onDone()
          }}
        >
          <p>El negocio fue actualizado exitosamente.</p>
        </SweetAlert>
      )}
      <style jsx="true">{`
        .radio-sublabel {
          color: gray;
          padding-left: 2rem;
        }
        .users-group {
          padding-left: 3rem;
        }
      `}</style>
    </AvForm>
  )
}

BusinessForm.propTypes = {
  insert: PropTypes.bool,
  business: PropTypes.object,
  history: PropTypes.object,
  onDone: PropTypes.func,
  onCancel: PropTypes.func,
}

export default withRouter(BusinessForm)
