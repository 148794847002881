/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { UPDATE_SOFTSKILLS_USER } from "./actionTypes"
import {
  updateSoftskillsUserError,
  updateSoftskillsUserSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { updateSoftskillsUser } from "../../../helpers/backend_helper"

function* start(action) {
  try {
    const response = yield call(updateSoftskillsUser, action.payload.form)
    if (response == undefined) throw new Error("API error")
    yield put(updateSoftskillsUserSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(updateSoftskillsUserError(error.message))
  }
}

function* softskillsUserUpdateSaga() {
  yield takeEvery(UPDATE_SOFTSKILLS_USER, start)
}

export default softskillsUserUpdateSaga
