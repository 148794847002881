import {
  GET_SHOPPING_AREAS,
  GET_SHOPPING_AREAS_SUCCESS,
  API_ERROR,
} from "./actionTypes"

export const getShoppingAreas = () => ({
  type: GET_SHOPPING_AREAS,
  payload: {},
})

export const getShoppingAreasSuccess = shoppingAreas => {
  return {
    type: GET_SHOPPING_AREAS_SUCCESS,
    payload: { shoppingAreas },
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
