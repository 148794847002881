import {
  ADD_SOFTSKILL_ABILITY_USER,
  ADD_SOFTSKILL_ABILITY_USER_SUCCESS,
  ADD_SOFTSKILL_ABILITY_USER_CLEAN,
  ADD_SOFTSKILL_ABILITY_USER_ERROR,
} from "./actionTypes"

export const addSoftskillAbilityUser = form => ({
  type: ADD_SOFTSKILL_ABILITY_USER,
  payload: { form },
})

export const addSoftskillAbilityUserSuccess = ability => {
  return {
    type: ADD_SOFTSKILL_ABILITY_USER_SUCCESS,
    payload: { ability },
  }
}

export const addSoftskillAbilityUserClean = () => {
  return {
    type: ADD_SOFTSKILL_ABILITY_USER_CLEAN,
    payload: {},
  }
}

export const addSoftskillAbilityUserError = error => {
  return {
    type: ADD_SOFTSKILL_ABILITY_USER_ERROR,
    payload: error,
  }
}
