/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { EDIT_SOFTSKILL_ABILITY_USER_POSITION } from "./actionTypes"
import {
  editSoftskillAbilityUserPositionError,
  editSoftskillAbilityUserPositionSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { updateSoftskillsAbilityUserPosition } from "../../../helpers/backend_helper"

function* edit(action) {
  try {
    const response = yield call(
      updateSoftskillsAbilityUserPosition,
      action.payload.form
    )
    if (response == undefined) throw new Error("API error")
    yield put(editSoftskillAbilityUserPositionSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(editSoftskillAbilityUserPositionError(error.message))
  }
}

function* softskillAbilityEditUserPositionSaga() {
  yield takeEvery(EDIT_SOFTSKILL_ABILITY_USER_POSITION, edit)
}

export default softskillAbilityEditUserPositionSaga
