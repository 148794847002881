/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { RESET_GROUP_PARTICIPANT_COURSE } from "./actionTypes"
import {
  resetGroupParticipantCourseError,
  resetGroupParticipantCourseSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { resetCoursesWithGroup } from "../../../helpers/backend_helper"

function* reset(action) {
  try {
    const response = yield call(resetCoursesWithGroup, action.payload.form)
    yield put(resetGroupParticipantCourseSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(resetGroupParticipantCourseError(error.message))
  }
}

function* groupParticipantCourseResetSaga() {
  yield takeEvery(RESET_GROUP_PARTICIPANT_COURSE, reset)
}

export default groupParticipantCourseResetSaga
