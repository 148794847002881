import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Modal, Input, Row, Col, Card } from "reactstrap"
import { Link } from "react-router-dom"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import PropTypes from "prop-types"
import Dropzone from "react-dropzone"
import { addImage, addImageClean } from "store/actions"
import { AvField, AvInput } from "availity-reactstrap-validation"

const UploadCourseTextModal = props => {
  const dispatch = useDispatch()

  const onConfirm = props.onConfirm

  const { loading, error, done, image } = useSelector(store => ({
    loading: store.ImageAdd.loading,
    error: store.ImageAdd.error,
    done: store.ImageAdd.done,
    image: store.ImageAdd.image,
  }))

  const handleSubmit = async (event, errors, values) => {
    props.onConfirm(values.text)
  }

  return (
    <Modal isOpen={props.show} backdrop={"static"} id="staticBackdrop">
      <AvForm
        className="form-horizontal"
        id="file-form"
        onSubmit={handleSubmit}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Prueba de curso finalizado
          </h5>
          {!loading && (
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                props.onCancel()
              }}
              aria-label="Close"
            ></button>
          )}
        </div>
        <div className="modal-body">
          {error !== "" && <div className="alert alert-danger">{error}</div>}
          <p>
            <strong>Por favor, agregue sus comentarios sobre el curso</strong>
          </p>
          <AvField
            name="text"
            className="form-control"
            placeholder=""
            type="textarea"
            validate={{
              required: {
                value: true,
                errorMessage: "Este campo es requerido",
              },
            }}
          />
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              props.onCancel()
            }}
          >
            Cancelar
          </button>
          <button
            type="submit"
            className={`btn btn-secondary ${loading ? "disabled" : ""}`}
          >
            {loading ? "Cargando, por favor espera..." : "Completar"}
          </button>
        </div>
      </AvForm>
    </Modal>
  )
}

UploadCourseTextModal.propTypes = {
  show: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default UploadCourseTextModal
