import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import AcceptPrivacyTyC from "pages/Accept-privacy-tyc"
import { useSelector, useDispatch } from "react-redux"

import { me, extendSession } from "store/actions"
import { LoadingIndicator } from "components/Common/LoadingIndicator"
import SessionTimer from "components/Common/SessionTimer"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  expiryTimestamp,
  ...rest
}) => {
  const dispatch = useDispatch()

  const token = localStorage.getItem("authUser")

  const { whoIAm, loading } = useSelector(store => ({
    whoIAm: store.Profile.user,
    loading: store.Profile.profileLoading,
  }))

  useEffect(() => {
    if (whoIAm === undefined) {
      dispatch(me())
    }
  }, [whoIAm])

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && token === null) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        } else if (Component === AcceptPrivacyTyC) {
          return <Component />
        }

        return loading ? (
          <div className="vw-100 vh-100 d-flex justify-content-center">
            <div className="d-flex align-items-center">
              <LoadingIndicator />
            </div>
          </div>
        ) : (
          <Fragment>
            <SessionTimer />
            <Layout>
              <Component {...props} />
            </Layout>
          </Fragment>
        )
      }}
    ></Route>
  )
}

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  expiryTimestamp: PropTypes.any,
}

export default Authmiddleware
