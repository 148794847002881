/* eslint-disable no-undef */
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { GET_SOFTSKILLS_USER_PROGRESS } from "./actionTypes"
import {
  getSoftskillsUserProgressError,
  getSoftskillsUserProgressSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { getSoftskillsUserProgress } from "../../../helpers/backend_helper"

function* listSoftskillsUserProgress(action) {
  try {
    const response = yield call(
      getSoftskillsUserProgress,
      action.payload.userId
    )
    yield put(getSoftskillsUserProgressSuccess(response))
  } catch (error) {
    yield put(getSoftskillsUserProgressError(error))
  }
}

function* softskillsUserProgressListSaga() {
  yield takeEvery(GET_SOFTSKILLS_USER_PROGRESS, listSoftskillsUserProgress)
}

export default softskillsUserProgressListSaga
