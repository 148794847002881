import {
  ADD_SOFTSKILL_COURSE,
  ADD_SOFTSKILL_COURSE_SUCCESS,
  ADD_SOFTSKILL_COURSE_CLEAN,
  ADD_SOFTSKILL_COURSE_ERROR,
} from "./actionTypes"

export const addSoftskillCourse = form => ({
  type: ADD_SOFTSKILL_COURSE,
  payload: { form },
})

export const addSoftskillCourseSuccess = course => {
  return {
    type: ADD_SOFTSKILL_COURSE_SUCCESS,
    payload: { course },
  }
}

export const addSoftskillCourseClean = () => {
  return {
    type: ADD_SOFTSKILL_COURSE_CLEAN,
    payload: {},
  }
}

export const addSoftskillCourseError = error => {
  return {
    type: ADD_SOFTSKILL_COURSE_ERROR,
    payload: error,
  }
}
