import {
  ANSWER_SOFTSKILLS_SURVEY,
  ANSWER_SOFTSKILLS_SURVEY_SUCCESS,
  ANSWER_SOFTSKILLS_SURVEY_CLEAN,
  ANSWER_SOFTSKILLS_SURVEY_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  survey: undefined,
}

const SoftskillsSurveyAnswer = (state = initialState, action) => {
  switch (action.type) {
    case ANSWER_SOFTSKILLS_SURVEY:
      state = {
        ...state,
        loading: true,
      }
      break
    case ANSWER_SOFTSKILLS_SURVEY_SUCCESS:
      state = {
        ...state,
        survey: action.payload.survey,
        loading: false,
        done: true,
      }
      break
    case ANSWER_SOFTSKILLS_SURVEY_CLEAN:
      state = initialState
      break
    case ANSWER_SOFTSKILLS_SURVEY_ERROR:
      state = { ...state, error: action.payload, loading: false, done: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default SoftskillsSurveyAnswer
