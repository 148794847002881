import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { Modal, Input, Row, Col, FormGroup, Label } from "reactstrap"
import { AvField, AvForm, AvInput } from "availity-reactstrap-validation"
import {
  editPollAnswer,
  addPollAnswer,
  addPoll,
  addPollClean,
  editPoll,
  editPollClean,
} from "store/actions"
import ImageUploadModal from "components/Common/ImageUploadModal"

const ValPollQuestionModal = props => {
  const idQuestionType = 4
  const dispatch = useDispatch()
  const [formError, setFormError] = useState(false)
  const [questionName, setQuestionName] = useState("")
  const [pollAnswer1, setPollAnswer1] = useState(undefined)
  const [pollAnswer2, setPollAnswer2] = useState(undefined)
  const [pollAnswer3, setPollAnswer3] = useState(undefined)
  const [pollAnswer4, setPollAnswer4] = useState(undefined)
  const [pollAnswer5, setPollAnswer5] = useState(undefined)

  const idQuestion =
    props.question !== undefined ? props.question.idQuestion : 0

  const answers = props.question !== undefined ? props.question.answers : []

  const {
    pollAddLoading,
    pollAddDone,
    pollAddError,
    pollEditLoading,
    pollEditDone,
    pollEditError,
  } = useSelector(store => ({
    pollAddLoading: store.PollAdd.loading,
    pollAddDone: store.PollAdd.done,
    pollAddError: store.PollAdd.error,
    pollEditLoading: store.PollEdit.loading,
    pollEditDone: store.PollEdit.done,
    pollEditError: store.PollEdit.error,
  }))

  useEffect(() => {
    setQuestionName(props.question !== undefined ? props.question.question : "")
    return () => {
      setFormError(false)
    }
  }, [props.question])

  useEffect(() => {
    if (answers.length > 0) {
      setPollAnswer1(1)
      setPollAnswer2(2)
      setPollAnswer3(3)
      setPollAnswer4(4)
      setPollAnswer5(5)
    }
    return () => {
      /* setPollAnswer1(undefined)
      setPollAnswer2(undefined)
      setPollAnswer3(undefined)
      setPollAnswer4(undefined) */
    }
  }, [answers])

  const handleSubmit = async (event, errors, values) => {
    if (errors.length > 0) {
      setFormError(true)
      return
    }
    setFormError(false)

    const request = {
      idCourse: props.course.idCurso,
      idQuestionType: idQuestionType,
      question: values.question,
      requested: true,
      isPlataformValoration: false,
    }

    if (props.question === undefined) {
      dispatch(addPoll(request))
    } else {
      request.idQuiz = props.question.idQuiz
      dispatch(editPoll(request))

      if (!(props.question.answers.length > 0)) {
        const requestAnswers = {
          idExamQuestion: props.question.idQuestion,
          List: [
            {
              answer: "1",
              image: "",
              correctAnswer: 0,
            },
            {
              answer: "2",
              image: "",
              correctAnswer: 0,
            },
            {
              answer: "3",
              image: "",
              correctAnswer: 0,
            },
            {
              answer: "4",
              image: "",
              correctAnswer: 0,
            },
            {
              answer: "5",
              image: "",
              correctAnswer: 0,
            },
          ],
        }
        //dispatch(addAnsswers(requestAnswers))
      } else {
        /* dispatch(
          editAnswer({
            idQuestionAnswer: answer1.idQuestionAnswer,
            idExamQuestion: answer1.idQuestion,
            answer: answer1.answer,
            image: "",
            correctAnswer: 0,
          })
        )
        dispatch(
          editAnswer({
            idQuestionAnswer: answer2.idQuestionAnswer,
            idExamQuestion: answer2.idQuestion,
            answer: answer2.answer,
            image: "",
            correctAnswer:0,
          })
        )
        dispatch(
          editAnswer({
            idQuestionAnswer: answer3.idQuestionAnswer,
            idExamQuestion: answer3.idQuestion,
            answer: answer3.answer,
            image: "",
            correctAnswer: 0,
          })
        )
        dispatch(
          editAnswer({
            idQuestionAnswer: answer4.idQuestionAnswer,
            idExamQuestion: answer4.idQuestion,
            answer: answer4.answer,
            image: "",
            correctAnswer: 0,
          })
        )*/
      }
    }
  }

  if (pollAddDone) {
    dispatch(addPollClean())
    props.onConfirm()
  }

  if (pollEditDone) {
    dispatch(editPollClean())
    props.onConfirm()
  }

  return (
    <Modal isOpen={props.show} backdrop={"static"} id="staticBackdrop">
      <AvForm className="form-horizontal" onSubmit={handleSubmit}>
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Pregunta de encuesta: valoración
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              if (questionName === "") {
                //dispatch(removeQuestion(idQuestion))
              }
              props.onCancel()
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          {formError && (
            <div className="alert alert-danger">
              Existen errores en el formulario. Por favor corrígelos para
              continuar.
            </div>
          )}
          {pollAddError !== "" && (
            <div className="alert alert-danger">{pollAddError}</div>
          )}
          <Row className="mb-4">
            <Col md={12}>
              <AvField
                name="question"
                label="Ingresa la pregunta"
                className="form-control"
                placeholder="Ingresar"
                value={
                  props.question !== undefined ? props.question.question : ""
                }
                type="text"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo es requerido",
                  },
                }}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={12}>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              if (questionName === "") {
                //dispatch(removeQuestion(idQuestion))
              }
              props.onCancel()
            }}
          >
            Cancelar
          </button>
          <button
            type="submit"
            className={`btn btn-secondary ${
              pollAddLoading || pollEditLoading ? "disabled" : ""
            }`}
          >
            {pollAddLoading || pollEditLoading ? "Guardando..." : "Guardar"}
          </button>
        </div>
      </AvForm>
      <style jsx="true">{`
        .noNorder {
          border: 0 !important;
        }
      `}</style>
    </Modal>
  )
}

ValPollQuestionModal.propTypes = {
  show: PropTypes.bool,
  question: PropTypes.object,
  course: PropTypes.object,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default ValPollQuestionModal
