/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { GET_SOFTSKILLS_COURSE } from "./actionTypes"
import { getSoftskillsCourseError, getSoftskillsCourseSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getSoftskillsCourse } from "../../../helpers/backend_helper"

function* get(action) {
  try {
    const response = yield call(getSoftskillsCourse, action.payload.courseId)
    if (response == undefined) throw new Error("API error")
    yield put(getSoftskillsCourseSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(getSoftskillsCourseError(error.message))
  }
}

function* softskillCourseGetSaga() {
  yield takeEvery(GET_SOFTSKILLS_COURSE, get)
}

export default softskillCourseGetSaga
