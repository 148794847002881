import SessionTimer from "components/Common/SessionTimer"
import React from "react"
import { Container, Row, Col } from "reactstrap"
import facebookIcon from "../../assets/images/facebook_icon.png"
import linkedInIcon from "../../assets/images/linkedin_icon.png"
import youtubeIcon from "../../assets/images/youtube_icon.png"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={4}>
              {new Date().getFullYear()} © Henkel |{" "}
              <a
                href="https://www.henkel.mx/terminos-de-uso"
                target={"_blank"}
                rel="noreferrer"
              >
                Términos y Condiciones
              </a>{" "}
              |{" "}
              <a
                href="https://www.henkel.mx/declaracion-de-proteccion-de-datos"
                target={"_blank"}
                rel="noreferrer"
              >
                Políticas de Privacidad
              </a>
            </Col>
            <Col md={4} className="text-center">
              <a
                href="https://www.linkedin.com/company/fester-mexico/"
                target={"_blank"}
                rel="noreferrer"
              >
                <img
                  src={linkedInIcon}
                  alt="LinkedIn"
                  className="img-fluid"
                  width="20"
                />
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <a
                href="https://www.facebook.com/Fester-M%C3%A9xico-112708833734290/"
                target={"_blank"}
                rel="noreferrer"
              >
                <img
                  src={facebookIcon}
                  alt="Facebook"
                  className="img-fluid"
                  width="20"
                />
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <a
                href="https://www.youtube.com/channel/UCPEl4QnEIKRBvSgEgYqftVg"
                target={"_blank"}
                rel="noreferrer"
              >
                <img
                  src={youtubeIcon}
                  alt="YouTube"
                  className="img-fluid"
                  width="20"
                />
              </a>
            </Col>
            <Col md={4}>
              <div className="text-sm-end d-none d-sm-block">
                <a
                  href="https://www.faradaygo.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Desarrollado por Faraday Services
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
