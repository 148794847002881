import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import SweetAlert from "react-bootstrap-sweetalert"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Row, Col, Button, Label } from "reactstrap"
import Select from "react-select"
import Switch from "react-switch"
import {
  addCustomer,
  addCustomerClean,
  addPosition,
  addPositionClean,
  editCustomer,
  editCustomerClean,
  editPosition,
  editPositionClean,
  getShoppingAreas,
} from "../../store/actions"

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 10,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 10,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Sí
    </div>
  )
}

const CustomersForm = props => {
  const customer = props.customer
  const [formError, setFormError] = useState(false)
  const [status, setStatus] = useState(customer.statusId)
  const [shoppingAreasSelectedMulti, setShoppingAreasSelectedMulti] =
    useState(undefined)

  const insert = props.insert ?? true

  const dispatch = useDispatch()

  const {
    loadingAdd,
    doneAdd,
    errorAdd,
    loadingUpdate,
    errorUpdate,
    doneUpdate,
    shoppingAreas,
  } = useSelector(state => ({
    loadingAdd: state.CustomerAdd.loading,
    errorAdd: state.CustomerAdd.error,
    doneAdd: state.CustomerAdd.done,
    loadingUpdate: state.CustomerEdit.loading,
    errorUpdate: state.CustomerEdit.error,
    doneUpdate: state.CustomerEdit.done,
    shoppingAreas: state.ShoppingAreasList.shoppingAreas,
  }))

  useEffect(() => {
    if (shoppingAreas.length == 0) {
      dispatch(getShoppingAreas())
    }
    const shoppingArea = shoppingAreas.find(
      element => element.idZonaComercial == customer.shoppingAreaId
    )
    if (shoppingArea !== undefined) {
      setShoppingAreasSelectedMulti({
        label: `${shoppingArea.zonaComercial}`,
        value: shoppingArea.idZonaComercial,
      })
    }
  }, [shoppingAreas])

  const handleSubmit = (event, errors, values) => {
    if (errors.length > 0 || shoppingAreasSelectedMulti === undefined) {
      setFormError(true)
      return
    }

    const shoppingAreaId = shoppingAreasSelectedMulti.value

    if (insert) {
      dispatch(addCustomerClean())
      const request = {
        idh: values.idh,
        name: values.name,
        businessName: values.businessName,
        municipalityId: 0,
        stateId: 0,
        zip: "00000",
        shoppingAreaId: shoppingAreaId,
      }
      dispatch(addCustomer(request))
    } else {
      dispatch(editCustomerClean())
      const request = {
        customerId: customer.customerId,
        idh: values.idh,
        name: values.name,
        businessName: values.businessName,
        municipalityId: 0,
        stateId: 0,
        zip: "00000",
        shoppingAreaId: shoppingAreaId,
        status: status ? 1 : 0,
      }
      dispatch(editCustomer(request))
    }
  }

  const handleShoppingAreasSelectedMulti = selectedMulti => {
    setShoppingAreasSelectedMulti(selectedMulti)
  }

  const shoppingAreasOG =
    shoppingAreas !== undefined
      ? shoppingAreas.map(element => {
          return {
            label: `${element.zonaComercial}`,
            value: element.idZonaComercial,
          }
        })
      : []

  const shoppingAreasOtionGroup = [
    {
      label: "Zonas comerciales",
      options: shoppingAreasOG,
    },
  ]

  return (
    <AvForm className="form-horizontal" onSubmit={handleSubmit}>
      {errorAdd !== "" && (
        <div className="alert alert-danger">
          Ocurrió un error al registrar el cliente: {errorAdd}
        </div>
      )}
      {errorUpdate !== "" && (
        <div className="alert alert-danger">
          Ocurrió un error al edutar el cliente: {errorUpdate}
        </div>
      )}
      {formError && (
        <div className="alert alert-danger">
          Por favor corrija los errores en el formulario antes de continuar.
        </div>
      )}
      <Row className="mb-3">
        <Col md={3}>
          <Label className="col-form-label" for="name">
            * IDH:
          </Label>
        </Col>
        <Col md={9}>
          <AvField
            name="idh"
            value={customer.idh}
            className="form-control"
            placeholder=""
            type="text"
            validate={{
              required: {
                value: true,
                errorMessage: "Este campo es requerido",
              },
              number: {
                value: true,
                errorMessage: "Este campo debe ser numérico",
              },
            }}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={3}>
          <Label className="col-form-label" for="name">
            * Razón social:
          </Label>
        </Col>
        <Col md={9}>
          <AvField
            name="name"
            value={customer.name}
            className="form-control"
            placeholder=""
            type="text"
            validate={{
              required: {
                value: true,
                errorMessage: "Este campo es requerido",
              },
            }}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={3}>
          <Label className="col-form-label" for="name">
            * Nombre comercial:
          </Label>
        </Col>
        <Col md={9}>
          <AvField
            name="businessName"
            value={customer.businessName}
            className="form-control"
            placeholder=""
            type="text"
            validate={{
              required: {
                value: true,
                errorMessage: "Este campo es requerido",
              },
            }}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={3}>
          <Label className="col-form-label" for="name">
            * Zona comercial:
          </Label>
        </Col>
        <Col md={9}>
          <Select
            name="shoppingArea"
            value={shoppingAreasSelectedMulti}
            isMulti={false}
            onChange={event => {
              handleShoppingAreasSelectedMulti(event)
            }}
            options={shoppingAreasOtionGroup}
            classNamePrefix="select2-selection"
          />
        </Col>
      </Row>
      {!insert && (
        <Row className="mb-3">
          <Col md={3}>
            <Label className="col-form-label" for="name">
              * Activado:
            </Label>
          </Col>
          <Col md={9}>
            <Switch
              uncheckedIcon={<Offsymbol />}
              checkedIcon={<OnSymbol />}
              className="me-1 mb-sm-8 mb-2"
              onColor={"#ed1c2e"}
              onChange={() => {
                setStatus(!status)
              }}
              checked={status}
            />
          </Col>
        </Row>
      )}

      <Row className="mb-3">
        <Col xl={8} lg={8} md={6} sm={4} xs={0}></Col>
        <Col xl={2} lg={2} md={3} sm={4} xs={6}>
          <Button className={`float-right  btn-block`} type="submit">
            {!loadingAdd && !loadingUpdate && <span>Guardar</span>}
            {(loadingAdd || loadingUpdate) && <span>Guardando...</span>}
          </Button>
        </Col>
        <Col xl={2} lg={2} md={3} sm={4} xs={6}>
          <button
            type="button"
            onClick={() => props.onCancel()}
            className="btn btn-light btn-block"
          >
            Cancelar
          </button>
        </Col>
      </Row>
      {doneAdd && insert && errorAdd === "" && (
        <SweetAlert
          title="Cliente registrado exitosamente"
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            props.onDone()
          }}
        >
          <p>El cliente fue registrado exitosamente.</p>
        </SweetAlert>
      )}
      {doneUpdate && !insert && errorUpdate === "" && (
        <SweetAlert
          title="Cliente actualizado exitosamente"
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            props.onDone()
          }}
        >
          <p>El cliente fue actualizado exitosamente.</p>
        </SweetAlert>
      )}
      <style jsx="true">{`
        .radio-sublabel {
          color: gray;
          padding-left: 2rem;
        }
        .users-group {
          padding-left: 3rem;
        }
      `}</style>
    </AvForm>
  )
}

CustomersForm.propTypes = {
  insert: PropTypes.bool,
  customer: PropTypes.object,
  history: PropTypes.object,
  onDone: PropTypes.func,
  onCancel: PropTypes.func,
}

export default withRouter(CustomersForm)
