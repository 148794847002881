import {
  GET_SOFTSKILLS_NOTIFICATIONS,
  GET_SOFTSKILLS_NOTIFICATIONS_SUCCESS,
  GET_SOFTSKILLS_NOTIFICATIONS_ERROR,
} from "./actionTypes"

export const getSoftskillsNotifications = userId => ({
  type: GET_SOFTSKILLS_NOTIFICATIONS,
  payload: { userId },
})

export const getSoftskillsNotificationsSuccess = notifications => {
  return {
    type: GET_SOFTSKILLS_NOTIFICATIONS_SUCCESS,
    payload: { notifications },
  }
}

export const getSoftskillsNotificationsError = error => {
  return {
    type: GET_SOFTSKILLS_NOTIFICATIONS_ERROR,
    payload: error,
  }
}
