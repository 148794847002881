/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { DELETE_EVENT } from "./actionTypes"
import { deleteEventSuccess, deleteEventError } from "./actions"

//Include Both Helper File with needed methods
import { removeEvent } from "../../../helpers/backend_helper"

function* deleteEvent(action) {
  console.log(action)
  try {
    const response = yield call(removeEvent, action.payload.id)
    if (response.statusCode) throw new Error(response.message)
    yield put(deleteEventSuccess(response))
  } catch (error) {
    yield put(deleteEventError(error))
  }
}

function* deleteEventSaga() {
  yield takeEvery(DELETE_EVENT, deleteEvent)
}

export default deleteEventSaga
