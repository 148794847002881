import { Fragment } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import user1 from "../../assets/images/user_without_pic.png"
import proxInterfester from "../../assets/images/prox_interfester.svg"
import { Link, withRouter } from "react-router-dom"

const EditorialesInterfester = () => {
  return (
    <Fragment className="card-padding">
      <h3 className="text-celf">Editoriales Interfester</h3>
      <hr />
      <div>
        <Row>
          <Col md={12} className="mt-4">
            <img
              src={proxInterfester}
              alt="Próximamente"
              className="img-fluid"
            />
          </Col>
          {/*<Col md={4}>
            <Row className="element">
              <Col xs={3}>
                <img
                  className="rounded-circle img-fluid"
                  src={user1}
                  alt="Header Avatar"
                />
              </Col>
              <Col xs={9}>
                <h4>Verónica García</h4>
                <p className="main">
                  La nueva plataforma web revoluciona la forma en que
                  interactuamos en línea, fusionando innovación y
                  accessibilidad. Su diseño intuitivo y características
                  avanzadas prometen redefinir las expectativas de los usuarios
                  en el mundo digital.
                </p>
                <p className="info">Seguir @VeronicaFester</p>
                <p className="date">Sep 1, 2023</p>
                <div>
                  <button
                    type="button"
                    className="btn btn-rounded btn-secondary"
                  >
                    <i className="fa fa-heart"></i> 5.3k
                  </button>
                  &nbsp;&nbsp;
                  <button
                    type="button"
                    className="btn btn-primary btn-rounded pt-2"
                  >
                    <i className="fa fa-play-circle"></i> Reproducir
                  </button>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row className="element">
              <Col xs={3}>
                <img
                  className="rounded-circle img-fluid"
                  src={user1}
                  alt="Header Avatar"
                />
              </Col>
              <Col xs={9}>
                <h4>Atzín Rogel</h4>
                <p>
                  La digitalización educativa no solo ha traspasado fronteras
                  geográficas, sino también pedagógicas. Las plataformas
                  educativas actuales empoderan a estudiantes y docentes,
                  permitiendo un aprendizaje personalizado y a demanda.
                </p>
                <p className="info">Seguir @AtzinRogel</p>
                <p className="date">Sep 1, 2023</p>
                <div>
                  <button
                    type="button"
                    className="btn btn-rounded btn-secondary"
                  >
                    <i className="fa fa-heart"></i> 5.3k
                  </button>
                  &nbsp;&nbsp;
                  <button
                    type="button"
                    className="btn btn-primary btn-rounded pt-2"
                  >
                    <i className="fa fa-play-circle"></i> Reproducir
                  </button>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row className="element">
              <Col xs={3}>
                <img
                  className="rounded-circle img-fluid"
                  src={user1}
                  alt="Header Avatar"
                />
              </Col>
              <Col xs={9}>
                <h4>Odilón de la Paz</h4>
                <p>
                  El auge de los vehículos eléctricos señala el inicio de una
                  era más sostenible en movilidad. Con tecnologías avanzadas y
                  un menor imácto ambiental, estamos presenciando la reinvención
                  del transporte moderno.
                </p>
                <p className="info">Seguir @OdilondelaPaz</p>
                <p className="date">Ago 30, 2023</p>
                <div>
                  <button
                    type="button"
                    className="btn btn-rounded btn-secondary"
                  >
                    <i className="fa fa-heart"></i> 5.3k
                  </button>
                  &nbsp;&nbsp;
                  <button
                    type="button"
                    className="btn btn-primary btn-rounded pt-2"
                  >
                    <i className="fa fa-play-circle"></i> Reproducir
                  </button>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row className="element">
              <Col xs={3}>
                <img
                  className="rounded-circle img-fluid"
                  src={user1}
                  alt="Header Avatar"
                />
              </Col>
              <Col xs={9}>
                <h4>Vicente Madrid</h4>
                <p>
                  Más allá de simples herramientas, la inteligencia artificial
                  se está convirtiendo en nuestra aliada cotidiana, anticipando
                  necesidades y mejorando la eficiencia en múltiples sectores.
                  El futuro, aparentemente, ya está aquí.
                </p>
                <p className="info">Seguir @VicenteMadrid</p>
                <p className="date">Jul 2, 2023</p>
                <div>
                  <button
                    type="button"
                    className="btn btn-rounded btn-secondary"
                  >
                    <i className="fa fa-heart"></i> 5.3k
                  </button>
                  &nbsp;&nbsp;
                  <button
                    type="button"
                    className="btn btn-primary btn-rounded pt-2"
                  >
                    <i className="fa fa-play-circle"></i> Reproducir
                  </button>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row className="element">
              <Col xs={3}>
                <img
                  className="rounded-circle img-fluid"
                  src={user1}
                  alt="Header Avatar"
                />
              </Col>
              <Col xs={9}>
                <h4>Mila Gamboa</h4>
                <p>
                  Frente al cambio climático y la degradación ambiental, las
                  iniciativas verdes actuales
                  representanmasqueuncompromiso,sonuna necesidad. Proteger
                  nuestro planeta es, en esencia, proteger nuestro futuro
                  colectivo.
                </p>
                <p className="info">Seguir @MilaGamboa</p>
                <p className="date">Jun 2, 2023</p>
                <div>
                  <button
                    type="button"
                    className="btn btn-rounded btn-secondary"
                  >
                    <i className="fa fa-heart"></i> 5.3k
                  </button>
                  &nbsp;&nbsp;
                  <button
                    type="button"
                    className="btn btn-primary btn-rounded pt-2"
                  >
                    <i className="fa fa-play-circle"></i> Reproducir
                  </button>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row className="element">
              <Col xs={3}>
                <img
                  className="rounded-circle img-fluid"
                  src={user1}
                  alt="Header Avatar"
                />
              </Col>
              <Col xs={9}>
                <h4>Omar Sabag</h4>
                <p>
                  Frente al cambio climático yla degradación ambiental, las
                  iniciativas verdes actuales
                  representanmásqueuncompromiso,sonuna necesidad. Proteger
                  nuestro planeta es, en esencia, proteger nuestro futuro
                  colectivo.
                </p>
                <p className="info">Seguir @OmarSabag</p>
                <p className="date">May 3, 2023</p>
                <div>
                  <button
                    type="button"
                    className="btn btn-rounded btn-secondary"
                  >
                    <i className="fa fa-heart"></i> 5.3k
                  </button>
                  &nbsp;&nbsp;
                  <Link
                    to={"/editoriales"}
                    className="btn btn-primary btn-rounded pt-2"
                  >
                    <i className="fa fa-play-circle"></i> Reproducir
                  </Link>
                </div>
              </Col>
            </Row>
  </Col>*/}
        </Row>
      </div>
      <style jsx>{`
        .text-celf {
          color: #005596;
          margin-bottom: 0;
        }
        .element {
          margin-top: 16px;
          margin-bottom: 32px;
        }
        .card-padding {
          padding: 16px;
        }
        .main {
          margin-bottom: 4px;
        }
        .info {
          text-decoration: underline;
          color: #999999;
          margin-bottom: 4px;
        }
        .date {
          margin-bottom: 8px;
          font-size: 14px;
          color: #999999;
        }
        hr {
          margin-top: 4px;
          margin-bottom: 4px;
        }
      `}</style>
    </Fragment>
  )
}

export default withRouter(EditorialesInterfester)
