import {
  GET_SOFTSKILLS_SURVEY,
  GET_SOFTSKILLS_SURVEY_SUCCESS,
  GET_SOFTSKILLS_SURVEY_CLEAN,
  GET_SOFTSKILLS_SURVEY_ERROR,
} from "./actionTypes"

export const getSoftskillsSurvey = form => ({
  type: GET_SOFTSKILLS_SURVEY,
  payload: { form },
})

export const getSoftskillsSurveySuccess = survey => {
  return {
    type: GET_SOFTSKILLS_SURVEY_SUCCESS,
    payload: { survey },
  }
}

export const getSoftskillsSurveyClean = () => {
  return {
    type: GET_SOFTSKILLS_SURVEY_CLEAN,
    payload: {},
  }
}

export const getSoftskillsSurveyError = error => {
  return {
    type: GET_SOFTSKILLS_SURVEY_ERROR,
    payload: error,
  }
}
