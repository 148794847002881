/* eslint-disable no-undef */
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { GET_SOFTSKILLS_USER_COURSES } from "./actionTypes"
import {
  getSoftskillsUserCoursesError,
  getSoftskillsUserCoursesSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { getSoftskillsUserCourses } from "../../../helpers/backend_helper"

function* listSoftskillsUserCourses(action) {
  try {
    const response = yield call(getSoftskillsUserCourses, action.payload.userId)
    yield put(getSoftskillsUserCoursesSuccess(response))
  } catch (error) {
    yield put(getSoftskillsUserCoursesError(error))
  }
}

function* softskillsUserCoursesListSaga() {
  yield takeEvery(GET_SOFTSKILLS_USER_COURSES, listSoftskillsUserCourses)
}

export default softskillsUserCoursesListSaga
