import {
  ANSWER_SOFTSKILLS_SURVEY,
  ANSWER_SOFTSKILLS_SURVEY_SUCCESS,
  ANSWER_SOFTSKILLS_SURVEY_CLEAN,
  ANSWER_SOFTSKILLS_SURVEY_ERROR,
} from "./actionTypes"

export const answerSoftskillsSurvey = form => ({
  type: ANSWER_SOFTSKILLS_SURVEY,
  payload: { form },
})

export const answerSoftskillsSurveySuccess = survey => {
  return {
    type: ANSWER_SOFTSKILLS_SURVEY_SUCCESS,
    payload: { survey },
  }
}

export const answerSoftskillsSurveyClean = () => {
  return {
    type: ANSWER_SOFTSKILLS_SURVEY_CLEAN,
    payload: {},
  }
}

export const answerSoftskillsSurveyError = error => {
  return {
    type: ANSWER_SOFTSKILLS_SURVEY_ERROR,
    payload: error,
  }
}
