import {
  ADD_SOFTSKILL_ABILITY,
  ADD_SOFTSKILL_ABILITY_SUCCESS,
  ADD_SOFTSKILL_ABILITY_CLEAN,
  ADD_SOFTSKILL_ABILITY_ERROR,
} from "./actionTypes"

export const addSoftskillAbility = form => ({
  type: ADD_SOFTSKILL_ABILITY,
  payload: { form },
})

export const addSoftskillAbilitySuccess = ability => {
  return {
    type: ADD_SOFTSKILL_ABILITY_SUCCESS,
    payload: { ability },
  }
}

export const addSoftskillAbilityClean = () => {
  return {
    type: ADD_SOFTSKILL_ABILITY_CLEAN,
    payload: {},
  }
}

export const addSoftskillAbilityError = error => {
  return {
    type: ADD_SOFTSKILL_ABILITY_ERROR,
    payload: error,
  }
}
