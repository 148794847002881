/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { MARK_SOFTSKILLS_NOTIFICATION } from "./actionTypes"
import {
  markSoftskillsNotificationError,
  markSoftskillsNotificationSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { markNotificationAsRead } from "../../../helpers/backend_helper"

function* markAsRead(action) {
  try {
    const response = yield call(markNotificationAsRead, action.payload)
    if (response == undefined) throw new Error("API error")
    if (!response.courseId) throw new Error("Error al guardar al cliente")
    yield put(markSoftskillsNotificationSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(markSoftskillsNotificationError(error.message))
  }
}

function* softskillsNotificationMarkSaga() {
  yield takeEvery(MARK_SOFTSKILLS_NOTIFICATION, markAsRead)
}

export default softskillsNotificationMarkSaga
