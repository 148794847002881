/* eslint-disable no-undef */
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { GET_BUSINESSES } from "./actionTypes"
import { apiError, getBusinessesSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getBusinesses } from "../../../helpers/backend_helper"

function* businesses() {
  try {
    const response = yield call(getBusinesses)
    yield put(getBusinessesSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* businessesListSaga() {
  yield takeEvery(GET_BUSINESSES, businesses)
}

export default businessesListSaga
