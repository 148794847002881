import {
  GET_SOFTSKILLS_COURSE,
  GET_SOFTSKILLS_COURSE_SUCCESS,
  GET_SOFTSKILLS_COURSE_CLEAN,
  GET_SOFTSKILLS_COURSE_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  course: undefined,
}

const SoftskillCourseGet = (state = initialState, action) => {
  switch (action.type) {
    case GET_SOFTSKILLS_COURSE:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_SOFTSKILLS_COURSE_SUCCESS:
      state = {
        ...state,
        course: action.payload.course,
        loading: false,
        done: true,
      }
      break
    case GET_SOFTSKILLS_COURSE_CLEAN:
      state = initialState
      break
    case GET_SOFTSKILLS_COURSE_ERROR:
      state = { ...state, error: action.payload, loading: false, done: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default SoftskillCourseGet
