import React, { useState, useEffect, Fragment } from "react"
import ReactSpeedometer from "react-d3-speedometer"
import { PropTypes } from "prop-types"

const GuageN8 = props => {
  const number = isNaN(props.value) ? 0 : parseInt(props.value)

  return (
    <Fragment>
      <div className="speedometer d-flex justify-content-center">
        <div>
          <ReactSpeedometer
            width={240}
            height={150}
            className="ReactSpeedometer"
            maxSegmentLabels={5}
            segments={1000}
            value={number}
            ringWidth={20}
            minValue={0}
            maxValue={100}
            startColor="#ed1c2e"
            endColor="#34c38f"
            needleColor="#005596"
            currentValueText={" "}
          />
          <h4 className="text-center">Cursos completos: {number}%</h4>
        </div>
      </div>
      <style jsx="true">{`
        .ReactSpeedometer {
          width: 50%;
        }
        .speedometer {
          overflow: auto;
        }
        .speedometer h4 {
          width: 240px;
        }
      `}</style>
    </Fragment>
  )
}

GuageN8.propTypes = {
  value: PropTypes.number,
}

export default GuageN8
