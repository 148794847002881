export const LoadingIndicator = () => (
  <div className="mt-3 mb-3">
    <div className="spinner-chase">
      <div className="chase-dot" />
      <div className="chase-dot" />
      <div className="chase-dot" />
      <div className="chase-dot" />
      <div className="chase-dot" />
      <div className="chase-dot" />
    </div>
    <h3 className="pt-4 alt-celf-color">Cargando...</h3>
  </div>
)
