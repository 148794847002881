/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { ADD_KNOWLEDGE_CATEGORY } from "./actionTypes"
import { apiError, addKnowledgeCategorySuccess } from "./actions"

//Include Both Helper File with needed methods
import { addKnowledgeCategory } from "../../../helpers/backend_helper"

function* add(action) {
  try {
    const response = yield call(addKnowledgeCategory, action.payload.form)
    if (response == undefined) throw new Error("API error")
    if (!response.success)
      throw new Error("Error al guardar la categoría de conocimiento")
    yield put(addKnowledgeCategorySuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(apiError(error.message))
  }
}

function* knowledgeCategoryAddSaga() {
  yield takeEvery(ADD_KNOWLEDGE_CATEGORY, add)
}

export default knowledgeCategoryAddSaga
