import { useEffect, useState } from "react"
import { Card, CardBody, CardImg, Modal } from "reactstrap"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import {
  getCoursesByUserAndGroup,
  listAbilitiesByUserAndGroup,
  resetGroupParticipantCourse,
  resetGroupParticipantCourseClean,
} from "store/actions"
import avatar1 from "../../assets/images/user_without_pic.png"
import ParticipantCourseGroupListTable from "components/Groups/ParticipantCourseGroupListTable"
import GuageN8 from "components/Charts/GuageN8"
import SweetAlert from "react-bootstrap-sweetalert"

const ParticipantInfoModal = ({ user, group, onClose, show }) => {
  const dispatch = useDispatch()

  const [resetCourses, setResetCourses] = useState(false)

  const { courses, abilities, participantCourseReset } = useSelector(store => ({
    courses: store.CoursesList.coursesByUserAndGroup,
    abilities: store.AbilitiesList.abilities,
    participantCourseReset: store.GroupParticipantCourseReset.done,
  }))

  useEffect(() => {
    dispatch(getCoursesByUserAndGroup(user.idUsuario, group.idGrupo))
    dispatch(listAbilitiesByUserAndGroup(user.idUsuario, group.idGrupo))
  }, [user.idUsuario, group.idGrupo])

  const handleResetCourses = () => {
    setResetCourses(false)
    dispatch(
      resetGroupParticipantCourse({
        groupId: group.idGrupo,
        userId: user.idUsuario,
      })
    )
  }

  if (participantCourseReset) {
    dispatch(resetGroupParticipantCourseClean())
    dispatch(getCoursesByUserAndGroup(user.idUsuario, group.idGrupo))
    dispatch(listAbilitiesByUserAndGroup(user.idUsuario, group.idGrupo))
  }

  const getColor = index => {
    const value = index % 5
    if (value == 0) return "success"
    if (value == 1) return "primary"
    if (value == 2) return "dark"
    if (value == 3) return "info"
    if (value == 4) return "secondary"
  }

  return (
    <Modal size="xl" isOpen={show} backdrop={"static"} id="staticBackdrop">
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          {user.nombre} {user.apellido}
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            onClose()
          }}
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col-md-4">
            <Card className="bg-light">
              <CardBody>
                <div className="row">
                  <div className="col-md-4 offset-md-4 pb-3">
                    <img
                      src={avatar1}
                      className="img-thumbnail rounded-circle"
                    />
                  </div>
                </div>
                <h4 className="text-center">
                  {user.nombre} {user.apellido}
                </h4>
                <p className="text-center">
                  Grupo: <strong>{group.nombreGrupo}</strong>
                </p>
              </CardBody>
            </Card>
          </div>
          <div className="col-md-4">
            <h3 className="text-center pb-3">Avance del participante</h3>
            <GuageN8 value={user.groupUserPercentage} />
          </div>
          <div className="col-md-4">
            <Card className="bg-light">
              <CardBody>
                <h3 className="text-center pb-2">Habilidades adquiridas</h3>
                <div className="collapse-abilities">
                  {abilities !== undefined && abilities.length > 0 ? (
                    abilities.map((ability, index) => (
                      <span
                        key={`skill-${ability.IdHabilidad}`}
                        className={`badge bg-pill bg-${getColor(
                          index
                        )} pt-2 m-1`}
                        style={{ fontSize: "12px", borderRadius: "14px" }}
                      >
                        {ability.nombre_habilidad}
                      </span>
                    ))
                  ) : (
                    <h5 className="text-center pt-4 mt-4">
                      -- Sin habilidades adquiridas --
                    </h5>
                  )}
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
        <br />
        <ParticipantCourseGroupListTable
          courses={courses}
          onResetCourses={() => setResetCourses(true)}
        />
      </div>
      {resetCourses && (
        <SweetAlert
          title="Resetear cursos"
          warning
          confirmBtnBsStyle="secondary"
          confirmBtnText="Sí"
          onConfirm={handleResetCourses}
          showCancel={true}
          cancelBtnBsStyle="light"
          cancelBtnText="No"
          onCancel={() => setResetCourses(false)}
        >
          <p>¿Confirmas el reseteo de todos los cursos del participante?</p>
        </SweetAlert>
      )}
      <style jsx>
        {`
          .collapse-abilities {
            overflow: scroll;
            height: 150px;
            background-color: #fff;
            padding: 4px;
          }
        `}
      </style>
    </Modal>
  )
}

ParticipantInfoModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  user: PropTypes.object,
  group: PropTypes.object,
}

export default ParticipantInfoModal
