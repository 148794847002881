import { Card, CardBody, Col, Row } from "reactstrap"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Fragment } from "react"

const ConfigurationBox = props => {
  return (
    <Fragment>
      <Link to={props.link}>
        <Card className="line">
          <CardBody>
            <Row className="d-flex align-items-center">
              <Col md={4}>
                <div className="logo-config d-flex align-items-center justify-content-center">
                  <i className={props.icon}></i>
                </div>
              </Col>
              <Col md={8}>
                <h3 className="mt-2">{props.title}</h3>
                <p>{props.description}</p>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Link>
      <style jsx>
        {`
          .line {
            border: 1px solid #000000;
            border-radius: 10px;
          }
          .logo-config {
            border-radius: 35px;
            height: 70px;
            width: 70px;
            border: 1px solid #000000;
            font-size: 32px;
            text-align: center;
          }
        `}
      </style>
    </Fragment>
  )
}

ConfigurationBox.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
}

export default ConfigurationBox
