import React, { useState, Fragment, useEffect } from "react"
import { Row, Col } from "reactstrap"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import moment from "moment"
import { getSoftskillsNotifications } from "../../../store/actions"
import "../../../assets/scss/datatables.scss"
import { useDispatch, useSelector } from "react-redux"
import { numberWithCommas } from "common/strings"
import {
  getSoftskillsPositionName,
  getSoftskillsProfileName,
  getSoftskillsRegionName,
} from "pages/SoftSkills/Helpers/Helpers"

const SoftskillsNotificationsListTable = props => {
  const dispatch = useDispatch()
  const { SearchBar } = Search

  const [dropDown, setDropDown] = useState(0)
  const [notifications, setNotifications] = useState([])
  const [filteredNotifications, setFilteredNotifications] = useState([])
  const [type, setType] = useState(0)
  const [page, setPage] = useState(undefined)

  const [sizePerPage, setSizePerPage] = useState(undefined)

  const { whoIAm, notificationsPrev, loading } = useSelector(state => ({
    whoIAm: state.Profile.user,
    notificationsPrev: state.SoftskillsNotificationsList.notifications,
    loading: state.SoftskillsNotificationsList.loading,
  }))

  const userId = whoIAm?.idUsuario ?? 0
  const profileId = whoIAm?.idRol ?? 0

  useEffect(() => {
    if (userId !== 0) {
      dispatch(getSoftskillsNotifications(userId))
    }
  }, [userId])

  const dropDownActions = (cell, row, rowIndex, extraData) => {
    const active = extraData?.active ?? 0
    return (
      <div key={`dropdown-${row.notificationId}`}>
        <button
          className="btn btn-secondary"
          onClick={() => handleNotification(row)}
        >
          Ver detalles
        </button>
      </div>
    )
  }

  const handleNotification = notification => {
    const sameUser = notification.userId == notification.fromUserId
    const url = sameUser
      ? `/soft-skills/courses/info/${notification.courseId}`
      : `/soft-skills/adm-courses/review/${notification.userId}/${notification.courseId}`
    if (notification.type === "Aprobado") {
      props.history.push(url)
    } else if (notification.type === "Evidencia") {
      props.history.push(url)
    } else if (notification.type === "Encuesta") {
      props.history.push(url)
    }
  }

  useEffect(() => {
    if (type === 0) {
      setNotifications(notificationsPrev)
      return
    }
    if (type === 1) {
      setNotifications(notificationsPrev.filter(n => n.type === "Aprobado"))
      return
    }
    if (type === 2) {
      setNotifications(notificationsPrev.filter(n => n.type === "Evidencia"))
      return
    }
    if (type === 3) {
      setNotifications(notificationsPrev.filter(n => n.type === "Encuesta"))
      return
    }
  }, [notificationsPrev, type])

  const columns = [
    {
      dataField: "notificationId",
      text: "Id",
      sort: false,
      hidden: true,
    },
    {
      dataField: "user.firstName",
      text: "Usuario",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return `${row.user.firstName} ${row.user.lastName}`
      },
    },
    {
      dataField: "user.softskillsRegionId",
      text: "Región",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return getSoftskillsRegionName(row.user.softskillsRegionId)
      },
    },
    {
      dataField: "user.softskillsPositionId",
      text: "Ocupación",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return getSoftskillsPositionName(row.user.softskillsPositionId)
      },
    },
    {
      dataField: "type",
      text: "Notificación",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return (
          <h4 style={{ marginBottom: 0 }} key={`status-${row.notificationId}`}>
            <span
              className={`pt-2 badge bg-${
                row.type === "Evidencia"
                  ? "danger"
                  : row.type === "Encuesta"
                  ? "warning"
                  : "success"
              }`}
            >
              {row.type}
            </span>
          </h4>
        )
      },
    },
    {
      dataField: "createdAt",
      text: "Fecha de notificación",
      sort: true,
      align: "center",
      CSVExport: false,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return (
          <span key={`time-${row.notificationId}`}>
            {moment(row.createdAt)
              .locale("es")
              .format("DD/MM/YYYY HH:mm [hrs.]")}
          </span>
        )
      },
    },
    {
      dataField: "actions",
      text: "Acciones",
      sort: false,
      csvExport: false,
      formatter: dropDownActions,
      formatExtraData: {
        active: dropDown,
      },
    },
  ]

  const pageOptions = {
    sizePerPage: sizePerPage === undefined ? 10 : sizePerPage,
    totalSize: filteredNotifications.length,
    custom: true,
    showTotal: true,
    page: page === undefined ? 1 : page,
    onPageChange: page => {
      setPage(page)
    },
    onSizePerPageChange: sizePerPage => {
      setSizePerPage(sizePerPage)
    },
  }

  const defaultSorted = [{ dataField: "notificationId", order: "desc" }]

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const afterFilter = (newResult, newFilters) => {}

  return loading && notifications === undefined ? (
    <div className="mt-3 mb-3">
      <div className="spinner-chase">
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
      </div>
    </div>
  ) : (
    <Fragment>
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="userId"
        columns={columns}
        data={notifications}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="userId"
            columns={columns}
            data={notifications}
            search={{
              afterSearch: newData => {
                setFilteredNotifications(newData)
              },
            }}
          >
            {toolkitProps => (
              <React.Fragment>
                <Row className="mb-3 pt-3">
                  {/*<Col lg={8} md={6} sm={12} xs={12} className="text-center">
                    <div className="search-box me-2 mb-2">
                      <div className="position-relative">
                        <SearchBar
                          {...toolkitProps.searchProps}
                          onSearch={value => {
                            setPage(1)
                            toolkitProps.searchProps.onSearch(value)
                          }}
                          placeholder="Buscar"
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Col>*/}
                  <Col
                    lg={{ size: 12 }}
                    md={{ size: 12 }}
                    sm={{ size: 12 }}
                    xs={12}
                    className="d-flex justify-content-center mb-2"
                  >
                    <button
                      className={`btn btn-${type === 0 ? "primary" : "light"}`}
                      onClick={() => setType(0)}
                    >
                      Todos
                    </button>
                    <div>&nbsp;</div>
                    <button
                      className={`btn btn-${type === 1 ? "primary" : "light"}`}
                      onClick={() => setType(1)}
                    >
                      Completados
                    </button>
                    <div>&nbsp;</div>
                    <button
                      className={`btn btn-${type === 2 ? "primary" : "light"}`}
                      onClick={() => setType(2)}
                    >
                      Evidencia
                    </button>
                    <div>&nbsp;</div>
                    <button
                      className={`btn btn-${type === 3 ? "primary" : "light"}`}
                      onClick={() => setType(3)}
                    >
                      Encuesta
                    </button>
                  </Col>
                </Row>

                {notifications !== undefined &&
                  filteredNotifications !== undefined && (
                    <Row>
                      <Col xs={4}>
                        <p>
                          {filteredNotifications.length ===
                          notifications.length ? (
                            <span>
                              Mostrando{" "}
                              <strong>
                                {numberWithCommas(notifications.length)}
                              </strong>{" "}
                              usuarios
                            </span>
                          ) : (
                            <span>
                              Mostrando{" "}
                              <strong>
                                {numberWithCommas(filteredNotifications.length)}
                              </strong>{" "}
                              usuarios de un total de{" "}
                              <strong>
                                {numberWithCommas(notifications.length)}
                              </strong>
                            </span>
                          )}
                        </p>
                      </Col>
                      <Col
                        xs={8}
                        className="d-flex justify-content-end pr-4"
                      ></Col>
                    </Row>
                  )}

                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <Fragment>
                        <BootstrapTable
                          keyField={"userId"}
                          bordered={false}
                          striped={true}
                          defaultSorted={defaultSorted}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                        {notifications?.length <= 0 && (
                          <Row>
                            <Col md={12} className="text-center">
                              -- No hay registros --
                            </Col>
                          </Row>
                        )}
                      </Fragment>
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                    <div className="text-md-right ms-auto">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
    </Fragment>
  )
}

SoftskillsNotificationsListTable.propTypes = {
  history: PropTypes.object,
}

export default withRouter(SoftskillsNotificationsListTable)
