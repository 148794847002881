import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  LOGIN_CLEAN,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  success: false,
  token: localStorage.getItem("authUser") ?? undefined,
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: true,
        token: action.payload.token,
      }
      break
    case LOGOUT_USER:
      state = { ...state, token: undefined, success: false }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state }
      break
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        success: false,
      }
      break
    case LOGIN_CLEAN:
      state = {
        ...state,
        error: "",
        loading: false,
        success: false,
        token: undefined,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default login
