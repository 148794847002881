import ApplicationBox from "components/Start/ApplicationBox"
import EditorialesInterfester from "components/Start/EditorialesInterfester"
import { Fragment, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import festerAcademy from "../../assets/images/logo_academia_fester_no_margin.png"
import festerCmo from "../../assets/images/logo_cmo.png"
import softSkills from "../../assets/images/logo_soft_skills.svg"
import { useDispatch, useSelector } from "react-redux"
import EventsTree from "components/Interfester/EventsTree"
import { getUsersByRole, listAllEvents, meNew } from "store/actions"
import moment from "moment"

const StartFester = props => {
  const dispatch = useDispatch()
  const [availableEvents, setAvailableEvents] = useState([])

  const { user, userNew, usersByRole, eventsDone, events } = useSelector(
    store => ({
      user: store.Profile.user,
      userNew: store.ProfileNew.user,
      usersByRole: store.UsersList.usersByRole,
      events: store.AllEventsList.events,
      eventsDone: store.AllEventsList.done,
    })
  )

  useEffect(() => {
    dispatch(getUsersByRole(2))
    if (userNew === undefined) {
      dispatch(meNew())
    }
  }, [])

  useEffect(() => {
    if (usersByRole.length > 0) {
      dispatch(listAllEvents())
    }
  }, [usersByRole])

  useEffect(() => {
    if (eventsDone) {
      events.sort((a, b) => {
        return new Date(a.fechaInicio) - new Date(b.fechaInicio)
      })

      events.forEach(event => {
        const user = usersByRole.find(
          user => user.userId === event.responsibleUserId
        )

        event.responsibleUserName = user
          ? `${user.firstName} ${user.lastName}`
          : ""
      })

      setAvailableEvents(
        events.filter(event => {
          const now = moment(new Date())
          const endDate = moment(new Date(event.endDate))
          const diff = endDate.diff(now, "days")
          return event.status === 1 && diff >= -1
        })
      )
    }
  }, [events, eventsDone])

  const email = user?.email ?? ""
  const name = user?.nombre ?? ""
  const profileSoftSkills = userNew?.softskillsProfileId ?? 0

  return (
    <Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Inicio | La Academia Fester</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col xs={12}>
                          <h3>Buenas tardes, {name}</h3>
                          <p style={{ fontSize: "18px" }}>
                            Selecciona la aplicación a la cual deseas ingresar
                          </p>
                        </Col>
                        <Col md={4}>
                          <ApplicationBox
                            name="Capacitación en Línea Fester"
                            description={
                              "Plataforma de e-learning para aprender de los productos y procesos Fester"
                            }
                            image={festerAcademy}
                            link={"/dashboard"}
                          />
                        </Col>
                        <Col md={4}>
                          {email !== "" && (
                            <ApplicationBox
                              name="Certificación de Distribuidores"
                              description={
                                "Plataforma de seguimiento de certificación de mano de obra Fester"
                              }
                              image={festerCmo}
                              link={`https://certificaciondedistribuidores-fester.com/sesion/login_academia_email/${encodeURIComponent(
                                email
                              )}`}
                            />
                          )}
                        </Col>
                        {profileSoftSkills !== 0 && (
                          <Col md={4}>
                            <ApplicationBox
                              name="Habilidades Blandas"
                              description={
                                "Plataforma de e-learning para gestión y seguimiento de habilidades blandas"
                              }
                              image={softSkills}
                              link={"/soft-skills"}
                            />
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mt-4 pt-4">
                      <h3 className="text-celf">Próximos eventos</h3>
                      <hr />
                      <EventsTree events={availableEvents} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default StartFester
