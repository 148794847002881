import {
  MARK_SOFTSKILLS_NOTIFICATION,
  MARK_SOFTSKILLS_NOTIFICATION_SUCCESS,
  MARK_SOFTSKILLS_NOTIFICATION_CLEAN,
  MARK_SOFTSKILLS_NOTIFICATION_ERROR,
} from "./actionTypes"

export const markSoftskillsNotification = form => ({
  type: MARK_SOFTSKILLS_NOTIFICATION,
  payload: { form },
})

export const markSoftskillsNotificationSuccess = form => {
  return {
    type: MARK_SOFTSKILLS_NOTIFICATION_SUCCESS,
    payload: { ...form },
  }
}

export const markSoftskillsNotificationClean = () => {
  return {
    type: MARK_SOFTSKILLS_NOTIFICATION_CLEAN,
    payload: {},
  }
}

export const markSoftskillsNotificationError = error => {
  return {
    type: MARK_SOFTSKILLS_NOTIFICATION_ERROR,
    payload: error,
  }
}
