/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { REMOVE_SUPPORT_MATERIAL } from "./actionTypes"
import { apiError, removeSupportMaterialSuccess } from "./actions"

//Include Both Helper File with needed methods
import { deleteSupportMaterial } from "../../../helpers/backend_helper"

function* remove(action) {
  try {
    const response = yield call(deleteSupportMaterial, action.payload.id)
    if (response === undefined) throw new Error("Error al remover lección")
    if (!response.success)
      throw new Error(`Error al remover lección: ${response.error}`)
    yield put(removeSupportMaterialSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(apiError(`${error}`))
  }
}

function* supportMaterialRemoveSaga() {
  yield takeEvery(REMOVE_SUPPORT_MATERIAL, remove)
}

export default supportMaterialRemoveSaga
