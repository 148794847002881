import {
  UPDATE_EVENT,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_ERROR,
  UPDATE_EVENT_CLEAN,
} from "./actionTypes"

export const updateEvent = event => {
  return {
    type: UPDATE_EVENT,
    payload: { event },
  }
}

export const updateEventSuccess = event => {
  return {
    type: UPDATE_EVENT_SUCCESS,
    payload: { event },
  }
}

export const updateEventClean = () => {
  return {
    type: UPDATE_EVENT_CLEAN,
    payload: {},
  }
}

export const updateEventError = error => {
  return {
    type: UPDATE_EVENT_ERROR,
    payload: error,
  }
}
