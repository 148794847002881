/* eslint-disable no-undef */
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { GET_SOFTSKILLS_ABILITIES } from "./actionTypes"
import {
  getSoftskillsAbilitiesError,
  getSoftskillsAbilitiesSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { getSoftskillsAbilities } from "../../../helpers/backend_helper"

function* listSoftskillsAbilities(action) {
  try {
    const response = yield call(getSoftskillsAbilities, action.payload.active)
    yield put(getSoftskillsAbilitiesSuccess(response))
  } catch (error) {
    yield put(getSoftskillsAbilitiesError(error))
  }
}

function* softskillsAbilitiesListSaga() {
  yield takeEvery(GET_SOFTSKILLS_ABILITIES, listSoftskillsAbilities)
}

export default softskillsAbilitiesListSaga
