import {
  ALL_SOFTSKILLS_ABILITIES_USERS,
  ALL_SOFTSKILLS_ABILITIES_USERS_SUCCESS,
  ALL_SOFTSKILLS_ABILITIES_USERS_ERROR,
} from "./actionTypes"

export const allSoftskillsAbilitiesUsers = userId => ({
  type: ALL_SOFTSKILLS_ABILITIES_USERS,
  payload: { userId },
})

export const allSoftskillsAbilitiesUsersSuccess = users => {
  return {
    type: ALL_SOFTSKILLS_ABILITIES_USERS_SUCCESS,
    payload: { users },
  }
}

export const allSoftskillsAbilitiesUsersError = error => {
  return {
    type: ALL_SOFTSKILLS_ABILITIES_USERS_ERROR,
    payload: error,
  }
}
