/* eslint-disable no-undef */
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { GET_COURSE, GET_COURSE_USER } from "./actionTypes"
import {
  apiError,
  getCourseSuccess,
  getCourseUserSuccess,
  getCourseUserError,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCourse,
  getCourseNewApi,
  getCourseByUser,
} from "../../../helpers/backend_helper"

function* get(params) {
  try {
    const response =
      params.payload.newApi !== undefined
        ? yield call(getCourseNewApi, params.payload.id)
        : yield call(getCourse, params.payload.id)
    yield put(getCourseSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getByUser(params) {
  try {
    const response = yield call(
      getCourseByUser,
      params.payload.idCourse,
      params.payload.idUser
    )
    yield put(getCourseUserSuccess(response[0]))
  } catch (error) {
    yield put(getCourseUserError(error))
  }
}

function* courseGetSaga() {
  yield takeEvery(GET_COURSE, get)
  yield takeEvery(GET_COURSE_USER, getByUser)
}

export default courseGetSaga
