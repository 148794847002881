import {
  MY_PROFILE_NEW,
  MY_PROFILE_NEW_SUCCESS,
  MY_PROFILE_NEW_ERROR,
  MY_PROFILE_NEW_RESET,
} from "./actionTypes"

const initialState = {
  error: "",
  success: "",
  user: undefined,
  profileError: "",
  profileLoading: false,
}

const profileNew = (state = initialState, action) => {
  switch (action.type) {
    case MY_PROFILE_NEW:
      state = { ...state, profileLoading: true }
      break
    case MY_PROFILE_NEW_SUCCESS:
      state = { ...state, profileLoading: false, user: action.payload.user }
      break
    case MY_PROFILE_NEW_ERROR:
      state = {
        ...state,
        user: undefined,
        profileLoading: false,
        profileError: action.payload,
      }
      break
    case MY_PROFILE_NEW_RESET:
      state = {
        ...state,
        profileLoading: false,
        profileError: "",
        user: undefined,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default profileNew
