import React, { useState, Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import { Row, Col, Modal } from "reactstrap"
import { Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import moment from "common/moment_i18n"

import {
  listSupportMaterialTypes,
  editSupportMaterialTypeClean,
  addSupportMaterialTypeClean,
} from "../../store/actions"
import "../../assets/scss/datatables.scss"
import { useDispatch, useSelector } from "react-redux"
import SupportMaterialTypesForm from "./SupportMaterialTypesForm"

const SupportMaterialTypesListTable = props => {
  const dispatch = useDispatch()
  const { SearchBar } = Search

  const [insertModal, setInsertModal] = useState(false)
  const [updateModal, setUpdateModal] = useState(false)
  const [supportMaterialType, setSupportMaterialType] = useState({
    idSupportMaterialType: 0,
  })

  const { error, loading, done, supportMaterialTypes } = useSelector(state => ({
    error: state.SupportMaterialTypesList.error,
    loading: state.SupportMaterialTypesList.loading,
    supportMaterialTypes: state.SupportMaterialTypesList.supportMaterialTypes,
    done: state.SupportMaterialTypesList.done,
  }))

  useEffect(() => {
    dispatch(listSupportMaterialTypes())
  }, [])

  const handleEdit = supportMaterialType => {
    setSupportMaterialType(supportMaterialType)
    setUpdateModal(true)
  }

  const buttonActions = (cell, row, rowIndex, extraData) => {
    return (
      <button
        className="btn btn-primary"
        key={`button-${row.idSupportMaterialType}`}
        onClick={() => handleEdit(row)}
      >
        Editar
      </button>
    )
  }

  const columns = [
    {
      dataField: "idSupportMaterialType",
      text: "Id",
      sort: false,
      hidden: true,
    },
    {
      dataField: "SupportMaterialType",
      text: "Tipo de material de apoyo",
      sort: true,
    },
    {
      dataField: "allowExtension",
      text: "Extensiones permitidas",
      sort: true,
    },
    {
      dataField: "updateDate",
      text: "Última actualización",
      sort: true,
      align: "center",
      headerAlign: "center",
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <span key={`last-update-${row.idSupportMaterialType}`}>
            {moment(row.updateDate === null ? row.registerDate : row.updateDate)
              .locale("es")
              .format("D [de] MMMM [de] YYYY [a las] HH:mm [hrs.]")}
          </span>
        )
      },
    },
    {
      dataField: "status",
      text: "Estatus",
      sort: true,
      align: "center",
      headerAlign: "center",
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <h4
            style={{ marginBottom: 0 }}
            key={`status-${row.idSupportMaterialType}`}
          >
            <span
              className={`pt-2 badge bg-${row.status ? "success" : "warning"}`}
            >
              {row.status ? "Activo" : "Inactivo"}
            </span>
          </h4>
        )
      },
    },
    {
      dataField: "actions",
      text: "Acciones",
      sort: false,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        width: "150px",
      },
      formatter: buttonActions,
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: supportMaterialTypes?.length,
    custom: true,
    showTotal: true,
  }

  const defaultSorted = [{ dataField: "SupportMaterialType", order: "asc" }]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
  }

  return loading || supportMaterialTypes === undefined ? (
    <div className="mt-3 mb-3">
      <div className="spinner-chase">
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
      </div>
    </div>
  ) : (
    <Fragment>
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="idSupportMaterialType"
        columns={columns}
        data={supportMaterialTypes}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="idSupportMaterialType"
            columns={columns}
            data={supportMaterialTypes}
            search
          >
            {toolkitProps => (
              <React.Fragment>
                <Row className="mb-2 pt-3">
                  <Col lg={2} md={3} sm={4} xs={6}>
                    <div className="d-grid">
                      <button
                        className="btn btn-primary btn-rounded"
                        onClick={() => setInsertModal(true)}
                      >
                        <i className="fa fa-plus"></i> Agregar negocio
                      </button>
                    </div>
                  </Col>
                  <Col
                    lg={{ size: 2, offset: 8 }}
                    md={{ size: 3, offset: 6 }}
                    sm={{ size: 4, offset: 4 }}
                    xs={6}
                  >
                    <div className="search-box me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <SearchBar
                          {...toolkitProps.searchProps}
                          placeholder="Buscar"
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl="12">
                    <Fragment>
                      <BootstrapTable
                        keyField={"idSupportMaterialType"}
                        bordered={false}
                        striped={true}
                        defaultSorted={defaultSorted}
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={"thead-light"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                      />
                      {supportMaterialTypes?.length <= 0 && (
                        <Row>
                          <Col md={12} className="text-center">
                            -- No hay registros --
                          </Col>
                        </Row>
                      )}
                    </Fragment>
                  </Col>
                </Row>

                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                    <div className="text-md-right ms-auto">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
      <Modal
        isOpen={insertModal}
        backdrop={"static"}
        size="lg"
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title">Registrar tipo de material de apoyo</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setInsertModal(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <SupportMaterialTypesForm
            onDone={() => {
              dispatch(addSupportMaterialTypeClean())
              dispatch(listSupportMaterialTypes())
              setInsertModal(false)
            }}
            onCancel={() => {
              dispatch(editSupportMaterialTypeClean())
              setInsertModal(false)
            }}
            insert={true}
            supportMaterialType={{
              idSupportMaterialType: 0,
              SupportMaterialType: "",
              status: true,
            }}
          />
        </div>
      </Modal>
      <Modal
        isOpen={updateModal}
        backdrop={"static"}
        size="lg"
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title">Editar tipo de material de apoyo</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setUpdateModal(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <SupportMaterialTypesForm
            onDone={() => {
              dispatch(editSupportMaterialTypeClean())
              dispatch(listSupportMaterialTypes())
              setUpdateModal(false)
            }}
            onCancel={() => {
              dispatch(editSupportMaterialTypeClean())
              setUpdateModal(false)
            }}
            insert={false}
            supportMaterialType={supportMaterialType}
          />
        </div>
      </Modal>
    </Fragment>
  )
}

SupportMaterialTypesListTable.propTypes = {
  groups: PropTypes.array,
}

export default SupportMaterialTypesListTable
