import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Row, Container, Col, Card, CardBody, Label } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import ReportAListTable from "components/Reports/ReportAListTable"
import {
  getCourses,
  listReportA,
  listReportAClean,
  getUsers,
  getGroups,
  getCustomers,
} from "store/actions"
import { LoadingIndicator } from "components/Common/LoadingIndicator"
import XlsExporter from "components/Common/XlsExporter"
import ReportFilters from "components/Reports/ReportFilters"

const ReportA = props => {
  const dispatch = useDispatch()

  const [submit, setSubmit] = useState(false)
  const [reportInfo, setReportInfo] = useState([])

  const {
    error,
    loading,
    done,
    report,
    courses,
    users,
    customers,
    groups,
    whoIAm,
  } = useSelector(state => ({
    error: state.ReportAList.error,
    loading: state.ReportAList.loading,
    report: state.ReportAList.report,
    done: state.ReportAList.done,
    courses: state.CoursesList.coursesManagement,
    users: state.UsersList.users,
    customers: state.CustomersList.customers,
    groups: state.GroupsList.groups,
    whoIAm: state.Profile.user,
  }))

  useEffect(() => {
    dispatch(getCourses())
    dispatch(getUsers())
    dispatch(getGroups())
    dispatch(getCustomers())
  }, [])

  const onSubmit = data => {
    dispatch(listReportA({ ...data, status: true, userId: whoIAm.idUsuario }))
  }

  const getXlsData = report => {
    let data = report.map((element, index) => {
      const total =
        element.N1 +
        element.N2 +
        element.N3 +
        element.N4 +
        element.N5 +
        element.N6 +
        element.N7 +
        element.N8
      return {
        "ID Curso Henkel": element.clave_curso,
        "Nombre curso": element.nombre_curso,
        "Total aprobados por curso": element.total_aprobados_x_curso,
        "Total de veces cursado para aprobar":
          element.total_veces_tomaron_curso_para_aprobar,
        "Usuarios N1": element.N1,
        "Usuarios N2": element.N2,
        "Usuarios N3": element.N3,
        "Usuarios N4": element.N4,
        "Usuarios N5": element.N5,
        "Usuarios N6": element.N6,
        "Usuarios N7": element.N7,
        "Usuarios N8": element.N8,
        "Total de usuarios": total,
      }
    })

    return {
      ReporteA: data,
    }
  }

  if (done) {
    setSubmit(true)
    setReportInfo(report)
    dispatch(listReportAClean())
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Reporte 7 - Cursos aprobados por perfil | Capacitación en Línea
            Fester
          </title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Reporte 7 - Cursos aprobados por perfil</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  {
                    key: "dashboard",
                    title: "Dashboard",
                    link: "/dashboard",
                  },
                  { key: "Reports", title: "Analítica", link: "/reports" },
                  { key: "ReportA", title: "Reporte 7" },
                ]}
              />
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {error !== "" && (
                    <div className="alert alert-danger mb-3">
                      Ocurrió un error al solicitar la información.
                    </div>
                  )}
                  <ReportFilters
                    title={"Reporte 7"}
                    onSubmit={values => onSubmit(values)}
                    xlsButton={
                      <XlsExporter
                        classNames={`btn btn-light mt-2 btn-block ${
                          submit ? "" : "disabled"
                        }`}
                        data={getXlsData(reportInfo)}
                        dataName={"ReporteA"}
                        name="Reporte 7"
                        label="Exportar"
                      />
                    }
                    users={users}
                    courses={courses}
                    customers={customers}
                    groups={groups}
                  />
                  {!loading && !submit && <div style={{ height: 260 }}></div>}
                  {loading && (
                    <div className="d-flex justify-content-center">
                      <LoadingIndicator />
                    </div>
                  )}
                  {!loading && submit && (
                    <div className="mt-4">
                      <ReportAListTable loading={loading} report={reportInfo} />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ReportA
