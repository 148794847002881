import { PropTypes } from "prop-types"
import { useState } from "react"
import { AvForm, AvInput } from "availity-reactstrap-validation"
import { Row, Col, Label } from "reactstrap"
import Select from "react-select"

const ReportFilters = props => {
  const [coursesSelectedMulti, setCoursesSelectedMulti] = useState([])
  const [profilesSelectedMulti, setProfilesSelectedMulti] = useState([])
  const [usersSelectedMulti, setUsersSelectedMulti] = useState([])
  const [customersSelectedMulti, setCustomersSelectedMulti] = useState([])
  const [groupsSelectedMulti, setGroupsSelectedMulti] = useState([])

  const handleSubmit = (event, errors, values) => {
    props.onSubmit({
      courses: coursesSelectedMulti.map(course => course.value),
      profiles: profilesSelectedMulti.map(profile => profile.value),
      users: usersSelectedMulti.map(user => user.value),
      customers: customersSelectedMulti.map(customer => customer.value),
      groups: groupsSelectedMulti.map(group => group.value),
      status: 1,
    })
  }

  const handleCoursesMulti = coursesSelectedMulti =>
    setCoursesSelectedMulti(coursesSelectedMulti)

  const handleProfilesMulti = profilesSelectedMulti =>
    setProfilesSelectedMulti(profilesSelectedMulti)

  const handleUsersMulti = usersSelectedMulti =>
    setUsersSelectedMulti(usersSelectedMulti)

  const handleCustomersMulti = customersSelectedMulti =>
    setCustomersSelectedMulti(customersSelectedMulti)

  const handleGroupsMulti = groupsSelectedMulti =>
    setGroupsSelectedMulti(groupsSelectedMulti)

  const coursesOptions =
    props.courses !== undefined
      ? props.courses
          .filter(course => course.status === 2)
          .sort((a, b) => parseInt(a.courseCode) - parseInt(b.courseCode))
          .map(course => {
            return {
              label: `${course.courseCode} - ${course.courseName}`,
              value: course.courseId,
            }
          })
      : []

  const filteredUsers =
    props.users !== undefined
      ? props.users
          .filter(user => user.idStatus)
          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
      : []

  const usersOptions = filteredUsers.map(user => {
    return {
      label: `${user.nombre} ${user.apellido}`,
      value: user.idUsuario,
    }
  })

  const filteredCustomers =
    props.customers !== undefined
      ? props.customers
          .filter(customer => customer.statusId)
          .sort((a, b) => (a.name > b.name ? 1 : -1))
      : []

  const customersOptions = filteredCustomers.map(customer => {
    return {
      label: `${customer.idh} - ${customer.name}`,
      value: customer.idh,
    }
  })

  const groupsOptions =
    props.groups !== undefined
      ? props.groups
          .sort((a, b) => (a.nombreGrupo > b.nombreGrupo ? 1 : -1))
          .map(group => {
            return {
              label: `${group.nombreGrupo}`,
              value: group.idGrupo,
            }
          })
      : []

  const coursesOptionGroup = [
    {
      label: "Cursos",
      options: coursesOptions,
    },
  ]

  const profilesOptionGroup = [
    {
      label: "Perfiles",
      options: [
        { label: "N1", value: 1 },
        { label: "N2", value: 2 },
        { label: "N3", value: 3 },
        { label: "N4", value: 4 },
        { label: "N5", value: 5 },
        { label: "N6", value: 6 },
        { label: "N7", value: 7 },
        { label: "N8", value: 8 },
      ],
    },
  ]

  const usersOptionGroup = [
    {
      label: "Usuarios",
      options: usersOptions,
    },
  ]

  const customersOptionGroup = [
    {
      label: "Clientes",
      options: customersOptions,
    },
  ]

  const groupsOptionGroup = [
    {
      label: "Grupos",
      options: groupsOptions,
    },
  ]

  return (
    <AvForm onSubmit={handleSubmit}>
      <Row>
        <Col lg={4} md={6} className="mb-3">
          <Label for="">Filtrar por cursos</Label>
          <Select
            value={coursesSelectedMulti}
            isMulti={true}
            onChange={event => {
              handleCoursesMulti(event)
            }}
            options={coursesOptionGroup}
            classNamePrefix="select2-selection"
          />
        </Col>
        <Col lg={4} md={6} className="mb-3">
          <Label for="">Filtrar por perfiles</Label>
          <Select
            value={profilesSelectedMulti}
            isMulti={true}
            onChange={event => {
              handleProfilesMulti(event)
            }}
            options={profilesOptionGroup}
            classNamePrefix="select2-selection"
          />
        </Col>
        <Col lg={4} md={6} className="mb-3">
          <Label for="">Filtrar por usuarios</Label>
          <Select
            value={usersSelectedMulti}
            isMulti={true}
            onChange={event => {
              handleUsersMulti(event)
            }}
            options={usersOptionGroup}
            classNamePrefix="select2-selection"
          />
        </Col>
        <Col lg={4} md={6} className="mb-3">
          <Label for="">Filtrar por cliente</Label>
          <Select
            value={customersSelectedMulti}
            isMulti={true}
            onChange={event => {
              handleCustomersMulti(event)
            }}
            options={customersOptionGroup}
            classNamePrefix="select2-selection"
          />
        </Col>
        <Col lg={4} md={6} className="mb-3">
          <Label for="">Filtrar por grupo</Label>
          <Select
            value={groupsSelectedMulti}
            isMulti={true}
            onChange={event => {
              handleGroupsMulti(event)
            }}
            options={groupsOptionGroup}
            classNamePrefix="select2-selection"
          />
        </Col>
        <Col lg={2} md={3} xs={6} className="pt-4">
          <button className="btn btn-secondary mt-2 btn-block" type="submit">
            <i className="fa fa-table"></i> Generar reporte
          </button>
        </Col>
        <Col lg={2} md={3} xs={6} className="pt-4">
          {props.xlsButton}
        </Col>
      </Row>
    </AvForm>
  )
}

ReportFilters.propTypes = {
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  xlsButton: PropTypes.element,
  users: PropTypes.array,
  courses: PropTypes.array,
  customers: PropTypes.array,
  groups: PropTypes.array,
}

export default ReportFilters
