/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { REMOVE_NOTE } from "./actionTypes"
import { removeNoteError, removeNoteSuccess } from "./actions"

//Include Both Helper File with needed methods
import { deleteNote } from "../../../helpers/backend_helper"

function* remove(action) {
  try {
    const response = yield call(deleteNote, action.payload.id)
    if (response === undefined) throw new Error("Error al remover nota")
    if (!response.success)
      throw new Error(`Error al remover nota: ${response.error}`)
    yield put(removeNoteSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(removeNoteError(`${error}`))
  }
}

function* noteRemoveSaga() {
  yield takeEvery(REMOVE_NOTE, remove)
}

export default noteRemoveSaga
