import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { MY_PROFILE_NEW } from "./actionTypes"
import { meNewSuccess, meNewError } from "./actions"

import { whoIamNewApi } from "../../../helpers/backend_helper"

function* meNew() {
  try {
    const response = yield call(whoIamNewApi)
    if (!response.idUsuario) {
      yield put(meNewError("La sesión ha finalizado"))
    } else {
      yield put(meNewSuccess(response))
    }
  } catch (error) {
    yield put(meNewError(error))
  }
}

export function* watchProfileNew() {
  yield takeEvery(MY_PROFILE_NEW, meNew)
}

function* ProfileNewSaga() {
  yield all([fork(watchProfileNew)])
}

export default ProfileNewSaga
