import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Row, Container, Col, Card, CardBody, Label } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import Report4ListTable from "components/Reports/Report4ListTable"
import {
  getCourses,
  listReport4,
  listReport4Clean,
  getUsers,
  getGroups,
  getCustomers,
} from "store/actions"
import { LoadingIndicator } from "components/Common/LoadingIndicator"
import XlsExporter from "components/Common/XlsExporter"
import ReportFilters from "components/Reports/ReportFilters"
import Report4Chart from "components/Reports/Report4Chart"

const Report4 = props => {
  const dispatch = useDispatch()

  const [submit, setSubmit] = useState(false)
  const [reportInfo, setReportInfo] = useState([])

  const {
    error,
    loading,
    done,
    report,
    courses,
    users,
    customers,
    groups,
    whoIAm,
  } = useSelector(state => ({
    error: state.Report4List.error,
    loading: state.Report4List.loading,
    report: state.Report4List.report,
    done: state.Report4List.done,
    courses: state.CoursesList.coursesManagement,
    users: state.UsersList.users,
    customers: state.CustomersList.customers,
    groups: state.GroupsList.groups,
    whoIAm: state.Profile.user,
  }))

  useEffect(() => {
    dispatch(getCourses())
    dispatch(getUsers())
    dispatch(getGroups())
    dispatch(getCustomers())
  }, [])

  const onSubmit = data => {
    dispatch(listReport4({ ...data, status: true, userId: whoIAm.idUsuario }))
  }

  const getXlsData = report => {
    let data = report.map((element, index) => {
      return {
        "Nombre(s)": element.nombre_usuario,
        "Apellido(s)": element.apellido_usuario,
        "Correo electrónico": element.Email,
        Perfil: `N${element.IdRol}`,
        Estatus: element.Id_status ? "Activado" : "Desactivado",
        Grupo: element.nombre_grupo,
        Distribuidor: element.nombre_distribuidor,
        "Porcentaje de aprobación":
          Math.round((element.porcentaje_aprobacion + Number.EPSILON) * 100) /
          100,
        "Porcentaje de avance":
          Math.round((element.porcentaje_avance + Number.EPSILON) * 100) / 100,
      }
    })

    return {
      Reporte4: data,
    }
  }

  if (done) {
    setSubmit(true)
    setReportInfo(report)
    dispatch(listReport4Clean())
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Reporte 4 - % Avance participación total | Capacitación en Línea
            Fester
          </title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Reporte 4 - % Avance participación total</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  { key: "dashboard", title: "Dashboard", link: "/dashboard" },
                  { key: "Reports", title: "Analítica", link: "/reports" },
                  { key: "Report4", title: "Reporte 4" },
                ]}
              />
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {error !== "" && (
                    <div className="alert alert-danger mb-3">
                      Ocurrió un error al solicitar la información.
                    </div>
                  )}
                  <ReportFilters
                    title={"Reporte 4"}
                    onSubmit={values => onSubmit(values)}
                    xlsButton={
                      <XlsExporter
                        classNames={`btn btn-light mt-2 btn-block ${
                          submit ? "" : "disabled"
                        }`}
                        data={getXlsData(reportInfo)}
                        dataName={"Reporte4"}
                        name="Reporte 4"
                        label="Exportar"
                      />
                    }
                    users={users}
                    courses={courses}
                    customers={customers}
                    groups={groups}
                  />
                  {!loading && !submit && <div style={{ height: 260 }}></div>}
                  {loading && (
                    <div className="d-flex justify-content-center">
                      <LoadingIndicator />
                    </div>
                  )}
                  {!loading && submit && (
                    <div className="mt-4">
                      <Report4Chart data={reportInfo} />
                      <br />
                      <Report4ListTable loading={loading} report={reportInfo} />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Report4
