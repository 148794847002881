import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Row, Col, Button, Label, Progress } from "reactstrap"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import {
  getUsers,
  getBusinesses,
  getShoppingAreas,
  addGroup,
  addGroupClean,
  editGroup,
  editGroupClean,
} from "../../store/actions"

const GroupsForm = props => {
  const group = props.group
  const [formError, setFormError] = useState(false)
  const [privacy, setPrivacy] = useState(false)
  const [canEdit, setCanEdit] = useState(false)
  const [selectedMulti, setSelectedMulti] = useState([])

  const showPercentage = props.showPercentage ?? false
  const insert = props.insert ?? true
  const percentage = props.group?.porcentajeCompleto ?? 0
  const groupType = group.tipoGrupo
  const responsibles = group.Responsible

  const dispatch = useDispatch()

  const {
    error,
    usersLoading,
    users,
    usersDone,
    businesses,
    shoppingAreas,
    loading,
    done,
    insertedGroupError,
    loadingUpdate,
    doneUpdate,
    errorUpdate,
    whoIAm,
  } = useSelector(state => ({
    error: state.UsersList.error,
    usersLoading: state.UsersList.loading,
    users: state.UsersList.users,
    usersDone: state.UsersList.done,
    businesses: state.BusinessesList.businesses,
    shoppingAreas: state.ShoppingAreasList.shoppingAreas,
    loading: state.GroupAdd.loading,
    done: state.GroupAdd.done,
    insertedGroupError: state.GroupAdd.error,
    loadingUpdate: state.GroupEdit.error,
    doneUpdate: state.GroupEdit.done,
    errorUpdate: state.GroupEdit.error,
    whoIAm: state.Profile.user,
  }))

  const idRol = whoIAm?.idRol ?? 0

  useEffect(() => {
    setCanEdit(idRol === 1 || idRol === 2)

    return () => {
      setCanEdit(false)
    }
  }, [idRol])

  useEffect(() => {
    let resp = []
    responsibles.forEach((value, index) => {
      const user = users.find(user => {
        return user.idUsuario === value
      })
      if (user !== undefined)
        resp.push({
          label: `${user.nombre} ${user.apellido}`,
          value: user.idUsuario,
        })
    })
    setSelectedMulti(resp)
  }, [responsibles, users])

  useEffect(() => {
    dispatch(getBusinesses())
    dispatch(getShoppingAreas())
    handlePrivacy(groupType === 2)
  }, [groupType])

  const handleSubmit = (event, errors, values) => {
    if (errors.length > 0) {
      setFormError(true)
      return
    }
    if (!(selectedMulti.length > 0) && values.private === 2) {
      setFormError(true)
      return
    }
    setFormError(false)

    const Responsible = selectedMulti.map(user => user.value)

    let request = {
      nombreGrupo: values.name,
      tipoGrupo: parseInt(values.private),
      idZonaComercial: parseInt(values.idZona),
      idNegocio: parseInt(values.idNegocio),
      Responsible,
    }

    if (insert) {
      request = { ...request, estatusGrupo: true }
      dispatch(addGroup(request))
    } else {
      request = {
        ...request,
        estatusGrupo: group.estatusGrupo,
        idGrupo: group.idGrupo,
      }
      dispatch(editGroup(request))
    }
  }

  const changePrivacy = event => {
    const privacy = event.target.value == 2
    handlePrivacy(privacy)
  }

  const handlePrivacy = privacy => {
    setPrivacy(privacy)
    if (privacy) {
      dispatch(getUsers())
    }
  }

  const handleMulti = selectedMulti => {
    setSelectedMulti(selectedMulti)
  }

  const userOptions =
    users !== undefined
      ? users
          .filter(user => {
            return user.idRol !== 1 && user.idRol !== 2
          })
          .map(user => {
            return {
              label: `${user.nombre} ${user.apellido}`,
              value: user.idUsuario,
            }
          })
      : []

  const optionGroup = [
    {
      label: "Usuarios",
      options: userOptions,
    },
  ]

  return (
    <AvForm className="form-horizontal" onSubmit={handleSubmit}>
      {insertedGroupError !== "" && (
        <div className="alert alert-danger">
          Ocurrió un error al registrar el grupo: {insertedGroupError}
        </div>
      )}
      {formError && (
        <div className="alert alert-danger">
          Por favor corrija los errores en el formulario antes de continuar.
        </div>
      )}
      <Row className="mb-3">
        <Col md={6}>
          <AvField
            name="name"
            disabled={!canEdit}
            value={group.nombreGrupo}
            label={`* ${insert ? "Crear" : "Nombre"} grupo:`}
            className="form-control"
            placeholder=""
            type="text"
            validate={{
              required: {
                value: true,
                errorMessage: "Este campo es requerido",
              },
            }}
          />
        </Col>
        {showPercentage && (
          <Col md={{ size: 4, offset: 2 }} className="mt-4">
            <Progress multi>
              <Progress
                bar
                animated
                value={percentage}
                color="primary"
                className="progress-lg"
              />
              <Progress
                bar
                value={100 - percentage}
                color="light"
                className="progress-lg"
              />
            </Progress>
            <h3 className="mt-2">
              {Math.round((percentage + Number.EPSILON) * 100) / 100}% de avance
              grupal
            </h3>
          </Col>
        )}
      </Row>
      <Row className="mb-3">
        <Col md={6}>
          <AvRadioGroup
            disabled={!canEdit}
            name="private"
            className="form-control"
            onChange={changePrivacy}
            value={group.tipoGrupo}
            validate={{
              required: {
                value: true,
                errorMessage: "Este campo es requerido",
              },
            }}
          >
            <AvRadio value={1} label="* Público a todos" />
            <small className="radio-sublabel">
              Todos los usuarios pueden ver el grupo al agregar usuarios
            </small>
            <div className="mt-3"></div>
            <AvRadio value={2} label="* Privado a usuarios" />
            <small className="radio-sublabel">
              Los usuarios que agregues serán los únicos que pueden ver el grupo
              y agregar o quitar usuarios
            </small>
          </AvRadioGroup>
        </Col>
      </Row>
      {privacy && (
        <Row className="mb-3">
          <Col
            className={`users-group ${
              formError && selectedMulti.length < 1 ? "text-danger" : ""
            }`}
            md={{ size: 11 }}
          >
            <Label>*Usuarios que pueden ver y agregar usuarios al grupo</Label>{" "}
            o <Link to="/users/add">agregar usuario</Link>
          </Col>
        </Row>
      )}
      {privacy && (
        <Row className="mb-3">
          <Col className="users-group" md={{ size: 11 }}>
            {canEdit ? (
              <Select
                value={selectedMulti}
                isMulti={true}
                onChange={event => {
                  handleMulti(event)
                }}
                options={optionGroup}
                classNamePrefix="select2-selection"
              />
            ) : (
              <div className="mb-4">
                {selectedMulti.map((user, index) => (
                  <span key={`readonly-user-${index}`}>
                    {user.label}
                    {selectedMulti.length >= index + 1 ? "" : ", "}
                  </span>
                ))}
              </div>
            )}
            {formError && selectedMulti.length < 1 && (
              <div className="text-danger">Ingrese al menos un usuario.</div>
            )}
          </Col>
        </Row>
      )}
      <Row className="mb-3">
        <Col md={12}>
          <AvField
            name="idZona"
            value={group.idZonaComercial}
            label="* Zona comercial / Región:"
            className="form-control"
            placeholder=""
            type="select"
            disabled={!canEdit}
            validate={{
              required: {
                value: true,
                errorMessage: "Este campo es requerido",
              },
            }}
          >
            <option>-- Seleccione zona comercial / región --</option>
            {shoppingAreas !== undefined &&
              shoppingAreas.map((element, index) => (
                <option
                  value={element.idZonaComercial}
                  key={element.idZonaComercial}
                >
                  {element.zonaComercial}
                </option>
              ))}
          </AvField>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={12}>
          <AvField
            name="idNegocio"
            value={group.idNegocio}
            label="* Negocio:"
            className="form-control"
            placeholder=""
            type="select"
            disabled={!canEdit}
            validate={{
              required: {
                value: true,
                errorMessage: "Este campo es requerido",
              },
            }}
          >
            <option>-- Seleccione negocio --</option>
            {businesses !== undefined &&
              businesses.map((element, index) => (
                <option value={element.idNegocio} key={element.idNegocio}>
                  {element.nombreNegocio}
                </option>
              ))}
          </AvField>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xl={8} lg={8} md={8} sm={8} xs={0}></Col>
        <Col xl={2} lg={2} md={2} sm={2} xs={6}>
          {canEdit && (
            <Button className={`float-right  btn-block`} type="submit">
              {!loading && !loadingUpdate && <span>Guardar</span>}
              {(loading || loadingUpdate) && <span>Guardando...</span>}
            </Button>
          )}
        </Col>
        <Col xl={2} lg={2} md={2} sm={2} xs={6}>
          <Link to="/groups" className="btn btn-light btn-block">
            Cancelar
          </Link>
        </Col>
      </Row>
      {done && insert && insertedGroupError === "" && (
        <SweetAlert
          title="Grupo registrado exitosamente"
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            dispatch(addGroupClean())
            props.history.push("/groups")
          }}
        >
          <p>El grupo fue registrado exitosamente.</p>
        </SweetAlert>
      )}
      {doneUpdate && !insert && errorUpdate === "" && (
        <SweetAlert
          title="Grupo actualizado exitosamente"
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            dispatch(editGroupClean())
            props.history.push("/groups")
          }}
        >
          <p>El grupo fue actualizado exitosamente.</p>
        </SweetAlert>
      )}
      <style jsx="true">{`
        .radio-sublabel {
          color: gray;
          padding-left: 2rem;
        }
        .users-group {
          padding-left: 3rem;
        }
      `}</style>
    </AvForm>
  )
}

GroupsForm.propTypes = {
  showPercentage: PropTypes.bool,
  insert: PropTypes.bool,
  group: PropTypes.object,
  history: PropTypes.object,
}

export default withRouter(GroupsForm)
