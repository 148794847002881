/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { ANSWER_SOFTSKILLS_SURVEY } from "./actionTypes"
import {
  answerSoftskillsSurveyError,
  answerSoftskillsSurveySuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { saveSoftskillsCourseUserSurvey } from "../../../helpers/backend_helper"

function* answer(action) {
  try {
    const response = yield call(
      saveSoftskillsCourseUserSurvey,
      action.payload.form
    )
    if (response == undefined) throw new Error("API error")
    yield put(answerSoftskillsSurveySuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(answerSoftskillsSurveyError(error.message))
  }
}

function* softskillsSurveyAnswerSaga() {
  yield takeEvery(ANSWER_SOFTSKILLS_SURVEY, answer)
}

export default softskillsSurveyAnswerSaga
