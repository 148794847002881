import { use } from "echarts"
import PropTypes from "prop-types"
import { Fragment, useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { useDispatch, useSelector } from "react-redux"
import { Modal } from "reactstrap"
import {
  addSoftskillAbilityUser,
  addSoftskillAbilityUserClean,
  getSoftskillsUserProgress,
  getUser,
} from "store/actions"

const AbilitiesForUserModal = props => {
  const dispatch = useDispatch()
  const [finalAbilities, setFinalAbilities] = useState([])
  const [alertError, setAlertError] = useState("")

  const { error, loading, done, abilities, user } = useSelector(state => ({
    error: state.SoftskillAbilityUserAdd.error,
    loading: state.SoftskillAbilityUserAdd.loading,
    done: state.SoftskillAbilityUserAdd.done,
    abilities: state.SoftskillsAbilitiesList.abilities,
    user: state.UserEdit.user,
  }))

  useEffect(() => {
    let newAbilities = []
    for (let i = 0; i < abilities.length; i++) {
      if (abilities[i].status === false) continue
      let found = false
      for (let j = 0; j < user.abilities.length; j++) {
        if (abilities[i].abilityId === user.abilities[j].abilityId) {
          found = true
        }
      }
      if (!found) newAbilities.push(abilities[i])
    }

    newAbilities.sort((a, b) => {
      return a.abilityName > b.abilityName ? 1 : -1
    })

    setFinalAbilities(newAbilities)
  }, [abilities, user.abilities])

  const handleAddAbility = ability => {
    if (ability.dependientAbilities.length > 0) {
      for (let i = 0; i < ability.dependientAbilities.length; i++) {
        let found = false
        for (let j = 0; j < user.abilities.length; j++) {
          if (
            ability.dependientAbilities[i].dependientAbilityId ===
            user.abilities[j].abilityId
          ) {
            found = true
          }
        }
        if (!found) {
          const pendingAbility = abilities.find(
            value =>
              value.abilityId ===
              ability.dependientAbilities[i].dependientAbilityId
          )
          setAlertError(
            `La habilidad ${ability.abilityName} depende de ${pendingAbility.abilityName}. Por favor agregue primero ${pendingAbility.abilityName}.`
          )
          return
        }
      }
    }
    dispatch(
      addSoftskillAbilityUser({
        userId: user.userId,
        abilityId: ability.abilityId,
        registerUserId: props.userId,
      })
    )
  }

  if (done) {
    dispatch(addSoftskillAbilityUserClean())
    dispatch(getUser(user.userId))
    dispatch(getSoftskillsUserProgress(user.userId))
  }

  return (
    <Fragment>
      <Modal isOpen={true} backdrop={"static"} size="md" id="staticBackdrop">
        <div className="modal-header">
          <h5 className="modal-title">Asignar habilidad</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              props.onClose()
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <table className="table table-bordered table-hover">
            <thead>
              <tr>
                <th>Habilidad</th>
                <th>Añadir</th>
              </tr>
            </thead>
            <tbody>
              {finalAbilities.map(ability => (
                <tr key={ability.abilityId}>
                  <td>{ability.abilityName}</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-rounded btn-primary"
                      onClick={() => handleAddAbility(ability)}
                    >
                      <i className="fa fa-plus-circle"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal>
      {alertError !== "" && (
        <SweetAlert
          title={`No es posible agregar la habilidad`}
          warning
          confirmBtnBsStyle="secondary"
          confirmBtnText="Ok, entendido"
          onConfirm={() => setAlertError("")}
          showCancel={false}
        >
          <p>{alertError}</p>
        </SweetAlert>
      )}
    </Fragment>
  )
}

AbilitiesForUserModal.propTypes = {
  onClose: PropTypes.func,
  userId: PropTypes.number,
}

export default AbilitiesForUserModal
