import PropTypes from "prop-types"
import React from "react"

import { Switch, BrowserRouter as Router, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import * as Sentry from "@sentry/react"
import { Container, Row, Col } from "reactstrap"

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
import "./App.css"

const App = props => {
  const time = new Date()
  time.setSeconds(time.getSeconds() + 60 * 15)

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <React.Fragment>
          {process.env.REACT_APP_ENV === "development" ? (
            <Container className="mt-4">
              <Row>
                <Col md={12}>
                  <h2>Tenemos un inconveniente</h2>
                  <div>Lo resolveremos a la brevedad.</div>
                  <div>{error.toString()}</div>

                  <div>{componentStack}</div>
                  <a href="/" className="btn btn-primary">
                    Clic aquí para regresar al inicio
                  </a>
                </Col>
              </Row>
            </Container>
          ) : (
            (location.href = "/")
          )}
        </React.Fragment>
      )}
    >
      <React.Fragment>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={HorizontalLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                expiryTimestamp={time}
                exact
              />
            ))}
          </Switch>
        </Router>
      </React.Fragment>
    </Sentry.ErrorBoundary>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
