import {
  MY_PROFILE_NEW,
  MY_PROFILE_NEW_SUCCESS,
  MY_PROFILE_NEW_ERROR,
  MY_PROFILE_NEW_RESET,
} from "./actionTypes"

export const meNew = () => {
  return {
    type: MY_PROFILE_NEW,
    payload: {},
  }
}

export const meNewSuccess = user => {
  return {
    type: MY_PROFILE_NEW_SUCCESS,
    payload: { user },
  }
}

export const meNewError = msg => {
  return {
    type: MY_PROFILE_NEW_ERROR,
    payload: { msg },
  }
}

export const meNewReset = () => {
  return {
    type: MY_PROFILE_NEW_RESET,
    payload: {},
  }
}
