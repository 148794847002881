export const getSoftskillsProfileName = softskillsProfileId => {
  switch (softskillsProfileId) {
    case 1:
      return "S1 - Admin"
    case 2:
      return "S2 - Head of Sales"
    case 3:
      return "S3 - Regional Sales Manager"
    case 4:
      return "S4 - Learner"
    default:
      return "No asignado"
  }
}

export const getSoftskillsPositionName = softskillsPositionId => {
  switch (softskillsPositionId) {
    case 1:
      return "Sales Excelence Manager"
    case 2:
      return "Regional Sales Manager"
    case 3:
      return "Technical Sales Advisor"
    case 4:
      return "Key Account Executive"
    case 5:
      return "Key Account Manager"
    case 6:
      return "Otro"
    case 7:
      return "Head of Sales"
    default:
      return "No asignado"
  }
}

export const getSoftskillsRegionName = softskillsRegionId => {
  switch (softskillsRegionId) {
    case 1:
      return "Admin plataforma"
    case 2:
      return "National"
    case 3:
      return "Negocios institucionales"
    case 4:
      return "Especificaciones"
    case 5:
      return "Bajío"
    case 6:
      return "Centro"
    case 7:
      return "Norte"
    case 8:
      return "Sureste"
    default:
      return "No asignado"
  }
}

export const getAbilityStatus = progress => {
  const pending = progress.courses.filter(course => course.progress === 0)
  if (pending.length === progress.courses.length) return 0
  const finished = progress.courses.filter(course => course.progress === 2)
  if (finished.length === progress.courses.length) return 2
  return 1
}

export const getIndicator = status => {
  if (status === 1) return <span className="indicator indicator-red">P</span>
  if (status === 2) return <span className="indicator indicator-orange">R</span>
  if (status === 3) return <span className="indicator indicator-green">C</span>
  return <span className="indicator indicator-red">P</span>
}

export const getStatusName = status => {
  if (status === 1) return <span className="text-status-red">Pendiente</span>
  if (status === 2)
    return <span className="text-status-orange">En revisión</span>
  if (status === 3) return <span className="text-status-green">Completado</span>
  return <span className="text-status-red">Pendiente</span>
}

export const getProgress = progress => {
  let progressPrev = []
  progress.map((item, index) => {
    const found = progressPrev.find(value => {
      return value.abilityId === item.id_habilidad
    })
    if (!found) {
      const element = {
        abilityId: item.id_habilidad,
        abilityName: item.nombre,
        statusId: item.id_status,
        courses: [
          {
            courseId: item.id_curso,
            courseName: item.nombre_curso,
            progress: item.iniciado === 0 ? 0 : item.finalizado === 0 ? 1 : 2,
          },
        ],
      }
      progressPrev.push(element)
    } else {
      const element = {
        ...found,
        courses: [
          ...found.courses,
          {
            courseId: item.id_curso,
            courseName: item.nombre_curso,
            progress: item.iniciado === 0 ? 0 : item.finalizado === 0 ? 1 : 2,
          },
        ],
      }
      const index = progressPrev.findIndex(value => {
        return value.abilityId === item.id_habilidad
      })
      progressPrev[index] = element
    }
  })
  return progressPrev
}
