import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import {
  Modal,
  Input,
  Row,
  Col,
  FormGroup,
  Label,
  ModalFooter,
} from "reactstrap"
import { AvField, AvForm, AvInput } from "availity-reactstrap-validation"
import CoursesLesonnsFormWysiwyg from "./CoursesLessonsFormWysiwyg"
import { editBlock, editBlockClean } from "../../store/actions"

const EditBlockModal = props => {
  const block = props.block

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [editor, setEditor] = useState(block.value)

  const { done } = useSelector(store => ({
    done: store.BlockEdit.done,
  }))

  const saveBlock = () => {
    const request = {
      ...block,
      value: editor,
    }
    console.log(request)
    dispatch(editBlock(request))
  }

  if (done) {
    dispatch(editBlockClean())
    props.onConfirm()
  }

  return (
    <Modal isOpen={true} backdrop={"static"} id="staticBackdrop" size="xl">
      {block !== undefined && (
        <CoursesLesonnsFormWysiwyg
          block={block}
          setEditor={e => setEditor(e)}
        />
      )}
      <ModalFooter>
        <div className="row">
          <div className="col-12 text-right">
            <button onClick={() => saveBlock()} className="btn btn-primary">
              Guardar
            </button>
            <span>&nbsp;&nbsp;</span>
            <button
              onClick={() => props.onCancel()}
              className="btn btn-warning"
            >
              Salir sin guardar
            </button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  )
}

EditBlockModal.propTypes = {
  block: PropTypes.object,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default EditBlockModal
