import {
  GET_USERS,
  GET_USERS_SUCCESS,
  API_ERROR,
  GET_USERS_BY_ROLE,
  GET_USERS_BY_ROLE_SUCCESS,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_CLEAN,
  SEND_USER_TRASH,
  SEND_USER_TRASH_SUCCESS,
  SEND_USER_TRASH_ERROR,
  SEND_USER_TRASH_CLEAN,
} from "./actionTypes"

export const getUsers = userId => ({
  type: GET_USERS,
  payload: { userId },
})

export const getUsersSuccess = users => {
  return {
    type: GET_USERS_SUCCESS,
    payload: { users },
  }
}

export const getUsersByRole = idRol => ({
  type: GET_USERS_BY_ROLE,
  payload: { idRol },
})

export const getUsersByRoleSuccess = users => {
  return {
    type: GET_USERS_BY_ROLE_SUCCESS,
    payload: { users },
  }
}

export const changePassword = (id, password, registerUserId) => {
  return {
    type: CHANGE_PASSWORD,
    payload: { id, password, registerUserId },
  }
}

export const changePasswordSuccess = result => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: { result },
  }
}

export const usersListApiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const changePasswordError = error => {
  return {
    type: CHANGE_PASSWORD_ERROR,
    payload: error,
  }
}

export const changePasswordClean = () => {
  return {
    type: CHANGE_PASSWORD_CLEAN,
    payload: {},
  }
}

export const sendUserToTrash = id => {
  return {
    type: SEND_USER_TRASH,
    payload: { id },
  }
}

export const sendUserToTrashSuccess = courses => {
  return {
    type: SEND_USER_TRASH_SUCCESS,
    payload: {},
  }
}

export const sendUserToTrashError = error => {
  return {
    type: SEND_USER_TRASH_ERROR,
    payload: error,
  }
}

export const sendUserToTrashClean = () => {
  return {
    type: SEND_USER_TRASH_CLEAN,
    payload: {},
  }
}
