import React, { Component, Fragment, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import MetaTags from "react-meta-tags"
import { Container, Row, Col, Card, CardBody } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb2"
import EventosListTable from "components/Interfester/EventosListTable"

const ListadoEventos = props => {
  return (
    <Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Eventos | La Academia Fester</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Gestión de eventos</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  { key: "home", title: "La Academia Fester", link: "/home" },
                  { key: "eventos", title: "Gestión de eventos" },
                ]}
              />
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <EventosListTable />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default ListadoEventos
