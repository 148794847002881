import { withRouter } from "react-router"
import { useParams } from "react-router-dom"
import React, { Fragment, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import { useDispatch, useSelector } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import CoursesForm from "components/Courses/CoursesForm"
import CoursesLessons from "components/Courses/CoursesLessons"
import { getCourse, getCourseClean } from "store/actions"
import CourseSupportMaterials from "components/Courses/CourseSupportMaterials"
import CourseExam from "components/Courses/CourseExam"
import CoursePublish from "components/Courses/CoursePublish"
import CoursePoll from "components/Courses/CoursePoll"

const CourseDetails = props => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const insert = id === undefined
  const [activeTab, setActiveTab] = useState("1")

  const { course } = useSelector(store => ({
    course: store.CourseGet.courseManagement,
  }))

  const myCourse = insert
    ? {
        courseId: 0,
        courseCode: "",
        courseName: "",
        status: 0,
        courseLevelId: 0,
        dedicationTime: 0,
      }
    : course

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getCourse(id, true))
    }
    return () => {
      dispatch(getCourseClean())
    }
  }, [id])

  const toggleIconCustom = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <React.Fragment>
      {myCourse !== undefined && (
        <div className="page-content">
          <MetaTags>
            <title>
              {myCourse.courseId == 0
                ? "Nuevo curso"
                : `Curso: ${myCourse.courseCode}`}{" "}
              | Capacitación en Línea Fester
            </title>
          </MetaTags>
          <Container fluid>
            <Row>
              <Col md={6}>
                <h5>
                  {myCourse.courseId == 0 ? "Nuevo curso" : `Detalle del curso`}
                </h5>
                <h2 className="main-celf-color">{myCourse.courseName}</h2>
              </Col>
              <Col md={6}>
                <Breadcrumbs
                  breadcrumbItems={[
                    {
                      key: "dashboard",
                      title: "Dashboard",
                      link: "/dashboard",
                    },
                    { key: "courses", title: "Cursos", link: "/courses" },
                    {
                      key: "edit_group",
                      title:
                        myCourse.courseId == 0
                          ? "Nuevo curso"
                          : `${myCourse.courseName}`,
                    },
                  ]}
                />
              </Col>
            </Row>

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    {insert && (
                      <CoursesForm
                        course={myCourse}
                        insert={id === undefined}
                      />
                    )}
                    {!insert && (
                      <Fragment>
                        <Nav className="icon-tab nav-justified">
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: activeTab === "1",
                              })}
                              onClick={() => {
                                toggleIconCustom("1")
                              }}
                            >
                              <span className="d-none d-sm-block">
                                <i className="fas fa-cogs"></i> Configuración
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="fas fa-cogs"></i>
                              </span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: activeTab === "2",
                              })}
                              onClick={() => {
                                toggleIconCustom("2")
                              }}
                            >
                              <span className="d-none d-sm-block">
                                <i className="fas fa-book-open"></i> Contenido
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="fas fa-book-open"></i>
                              </span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: activeTab === "6",
                              })}
                              onClick={() => {
                                toggleIconCustom("6")
                              }}
                            >
                              <span className="d-none d-sm-block">
                                <i className="fas fa-paperclip"></i> Material
                                adjunto
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="fas fa-paperclip"></i>
                              </span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: activeTab === "3",
                              })}
                              onClick={() => {
                                toggleIconCustom("3")
                              }}
                            >
                              <span className="d-none d-sm-block">
                                <i className="fas fa-question"></i> Examen
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="fas fa-question"></i>
                              </span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: activeTab === "4",
                              })}
                              onClick={() => {
                                toggleIconCustom("4")
                              }}
                            >
                              <span className="d-none d-sm-block">
                                <i className="fas fa-comment-dots"></i> Encuesta
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="fas fa-comment-dots"></i>
                              </span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: activeTab === "5",
                              })}
                              onClick={() => {
                                toggleIconCustom("5")
                              }}
                            >
                              <span className="d-none d-sm-block">
                                <i className="fas fa-upload"></i> Publicar
                              </span>
                              <span className="d-block d-sm-none">
                                <i className="fas fa-upload"></i>
                              </span>
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent
                          activeTab={activeTab}
                          className="p-3 text-muted mt-4"
                        >
                          <TabPane tabId="1">
                            <CoursesForm
                              course={myCourse}
                              insert={id === undefined}
                            />
                          </TabPane>
                          <TabPane tabId="2">
                            <CoursesLessons course={myCourse} />
                          </TabPane>
                          <TabPane tabId="3">
                            <CourseExam course={myCourse} />
                          </TabPane>
                          <TabPane tabId="5">
                            <CoursePublish course={myCourse} />
                          </TabPane>
                          <TabPane tabId="4">
                            <CoursePoll course={myCourse} />
                          </TabPane>
                          <TabPane tabId="6">
                            <CourseSupportMaterials course={myCourse} />
                          </TabPane>
                        </TabContent>
                      </Fragment>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

export default withRouter(CourseDetails)
