import {
  TRY_POLL,
  TRY_POLL_CLEAN,
  TRY_POLL_SUCCESS,
  TRY_POLL_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  poll: undefined,
}

const PollTry = (state = initialState, action) => {
  switch (action.type) {
    case TRY_POLL:
      state = {
        ...state,
        loading: true,
      }
      break
    case TRY_POLL_SUCCESS:
      state = {
        ...state,
        loading: false,
        done: true,
        poll: action.payload.poll,
      }
      break
    case TRY_POLL_CLEAN:
      state = initialState
      break
    case TRY_POLL_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default PollTry
