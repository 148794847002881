/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { FINISH_SOFTSKILLS_USER_COURSE } from "./actionTypes"
import {
  finishSoftskillsUserCourseError,
  finishSoftskillsUserCourseSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { finishSoftskillsUserCourse } from "../../../helpers/backend_helper"

function* finish(action) {
  try {
    const response = yield call(finishSoftskillsUserCourse, action.payload.form)
    if (response == undefined) throw new Error("API error")
    yield put(finishSoftskillsUserCourseSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(finishSoftskillsUserCourseError(error.message))
  }
}

function* softskillsUserCourseFinishSaga() {
  yield takeEvery(FINISH_SOFTSKILLS_USER_COURSE, finish)
}

export default softskillsUserCourseFinishSaga
