const BACKEND_URL = process.env.REACT_APP_API_ENDPOINT
const BACKEND2_URL = process.env.REACT_APP_API2_ENDPOINT
const API_URL = `${BACKEND_URL}api`
const API2_URL = `${BACKEND2_URL}`

export function getBackendUrl() {
  return BACKEND_URL
}

export async function get(url, config = {}, useApiV2 = false) {
  const headers = addHeaders(config)

  try {
    const headers = addHeaders(config)
    const response = await fetch(`${useApiV2 ? API2_URL : API_URL}/${url}`, {
      ...headers,
      method: "GET",
    })
    const data = await response.json()
    return data
  } catch (error) {
    console.log(error)
    return error
  }
}

export async function post(url, body, config = {}, useApiV2 = false) {
  try {
    const headers =
      url === "login/authenticate"
        ? {
            headers: {
              ...config.headers,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        : addHeaders(config)

    const response = await fetch(`${useApiV2 ? API2_URL : API_URL}/${url}`, {
      ...headers,
      method: "POST",
      body: JSON.stringify(body),
    })
    const data = await response.json()
    return data
  } catch (error) {
    console.log(error)
    return error
  }
}

export async function put(url, data, config = {}) {}

export async function del(url, body, config = {}) {
  try {
    let token = localStorage.getItem("authUser")

    const response = await fetch(`${API_URL}/${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "DELETE",
    })
    const data = await response.json()
    return data
  } catch (error) {
    console.log(error)
    return error
  }
}

export async function postFile(url, body, config = {}, useApiV2 = false) {
  try {
    let token = localStorage.getItem("authUser")

    const response = await fetch(`${useApiV2 ? API2_URL : API_URL}/${url}`, {
      method: "POST",
      body: body,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    const data = await response.json()
    return data
  } catch (error) {
    console.log(error)
    return error
  }
}

function addHeaders(config, contentType = "application/json") {
  let newConfig = {
    ...config,
    headers: {
      ...config.headers,
      "Content-Type": contentType,
      Accept: "application/json",
    },
  }

  let token = localStorage.getItem("authUser")
  if (token != null) {
    token = token.replace('"', "").replace('"', "")
    newConfig = {
      ...newConfig,
      headers: {
        ...newConfig.headers,
        Authorization: `Bearer ${token}`,
      },
    }
  }
  return newConfig
}

function unauthorized() {
  /* localStorage.removeItem("authUser")
  window.location.href = "/" */
}
