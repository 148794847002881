import CoursesTab from "components/SoftSkills/CourseUsers/CoursesTab"
import { Fragment, useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { getSoftskillsUserCourses } from "store/actions"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb2"

const MySoftskillsCourses = () => {
  const [activeTab, setActiveTab] = useState("1")
  const dispatch = useDispatch()

  const { user, courses, loading } = useSelector(store => ({
    user: store.Profile.user,
    courses: store.SoftskillsUserCoursesList.courses,
    loading: store.SoftskillsUserCoursesList.loading,
  }))

  const userId = user?.idUsuario ?? 0

  useEffect(() => {
    dispatch(getSoftskillsUserCourses(userId))
  }, [userId])

  return (
    <Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mis cursos | Soft Skills</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Mis cursos</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  { key: "dashboard", title: "Inicio", link: "/soft-skills" },
                  {
                    key: "courses",
                    title: "Mis cursos",
                  },
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-end">
              <Link to={"/soft-skills"} className="btn btn-link">
                <i className="fa fa-times" style={{ fontSize: "24px" }}></i>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col>
              <CoursesTab
                courses={courses}
                loading={loading}
                userId={userId}
                all={true}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default MySoftskillsCourses
