/* eslint-disable no-undef */
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { GET_SHOPPING_AREAS } from "./actionTypes"
import { apiError, getShoppingAreasSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getShoppingAreas } from "../../../helpers/backend_helper"

function* shoppingAreas() {
  try {
    const response = yield call(getShoppingAreas)
    yield put(getShoppingAreasSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* shoppingAreasListSaga() {
  yield takeEvery(GET_SHOPPING_AREAS, shoppingAreas)
}

export default shoppingAreasListSaga
