import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { ACCEPT_PRIVACY_TYC, EDIT_PROFILE, MY_PROFILE } from "./actionTypes"
import { profileSuccess, profileError, meSuccess, meError } from "./actions"

import { whoIAm, postAcceptPrivacyTyc } from "../../../helpers/backend_helper"
import { acceptPrivacyTycError, acceptPrivacyTycSuccess } from "store/actions"

function* me() {
  try {
    const response = yield call(whoIAm)
    if (!response.idUsuario) {
      yield put(meError("La sesión ha finalizado"))
    } else {
      yield put(meSuccess(response))
    }
  } catch (error) {
    yield put(meError(error))
  }
}

function* editProfile({ payload: { user } }) {
  /* try {
    const response = yield call(postJwtProfile, "/post-jwt-profile", {
      username: user.username,
      idx: user.idx,
    })
    yield put(profileSuccess(response.user))
  } catch (error) {
    yield put(profileError(error))
  } */
}

function* updatePrivacyTyc({ payload: { idUser, tyc, privacity } }) {
  try {
    const response = yield call(postAcceptPrivacyTyc, {
      idUser,
      tyc,
      privacity,
    })
    if (response.success) {
      yield put(acceptPrivacyTycSuccess(response))
    } else {
      yield put(acceptPrivacyTycError("Ocurrió un error al actualizar"))
    }
  } catch (error) {
    yield put(acceptPrivacyTycError(error))
  }
}

export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
  yield takeEvery(MY_PROFILE, me)
  yield takeEvery(ACCEPT_PRIVACY_TYC, updatePrivacyTyc)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
