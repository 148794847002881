import {
  LIST_ALL_EVENTS,
  LIST_ALL_EVENTS_SUCCESS,
  LIST_ALL_EVENTS_ERROR,
  LIST_ALL_EVENTS_CLEAN,
} from "./actionTypes"

export const listAllEvents = () => ({
  type: LIST_ALL_EVENTS,
  payload: {},
})

export const listAllEventsSuccess = events => {
  return {
    type: LIST_ALL_EVENTS_SUCCESS,
    payload: { events },
  }
}

export const listAllEventsClean = () => {
  return {
    type: LIST_ALL_EVENTS_CLEAN,
    payload: {},
  }
}

export const listAllEventsError = error => {
  return {
    type: LIST_ALL_EVENTS_ERROR,
    payload: error,
  }
}
