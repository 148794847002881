/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { GET_KNOWLEDGE_CATEGORIES } from "./actionTypes"
import { apiError, getKnowledgeCategoriesSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getKnowledgeCategories } from "../../../helpers/backend_helper"

function* knowledgeCategories() {
  try {
    const response = yield call(getKnowledgeCategories)
    yield put(getKnowledgeCategoriesSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* knowledgeCategoriesSaga() {
  yield takeEvery(GET_KNOWLEDGE_CATEGORIES, knowledgeCategories)
}

export default knowledgeCategoriesSaga
