import React, { Component, Fragment, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Row, Col, Card, CardBody } from "reactstrap"

import Breadcrumbs from "../../../components/Common/Breadcrumb2"
import SoftskillsAbilitiesListTable from "components/SoftSkills/Abilities/SoftskillsAbilitiesListTable"

const SoftskillsAbilitiesList = props => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Habilidades | Soft Skills</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Catálogo de habilidades</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  { key: "dashboard", title: "Inicio", link: "/soft-skills" },
                  { key: "abilities", title: "Habilidades" },
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <SoftskillsAbilitiesListTable />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SoftskillsAbilitiesList
