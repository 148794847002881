import { PropTypes } from "prop-types"
import { Fragment, useState } from "react"
import XlsExporter from "components/Common/XlsExporter"
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Progress,
  Button,
} from "reactstrap"
import { Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import moment from "common/moment_i18n"

const ParticipantCourseGroupListTable = props => {
  const courses = props.courses
  const { SearchBar } = Search

  const [page, setPage] = useState(undefined)
  const [sizePerPage, setSizePerPage] = useState(undefined)

  const getStatus = course => {
    if (course.aprobado) {
      return (
        <label className="badge bg-pill bg-success pt-2 m-1">Completo</label>
      )
    }
    if (course.porcentaje_avance > 0) {
      return (
        <label className="badge bg-pill bg-warning pt-2 m-1">En progreso</label>
      )
    }
    return <label className="badge bg-pill bg-danger pt-2 m-1">Pendiente</label>
  }

  const getStatusRaw = course => {
    if (course.aprobado) {
      return "Completo"
    }
    if (course.porcentaje_avance > 0) {
      return "En progreso"
    }
    return "Pendiente"
  }

  const columns = [
    {
      dataField: "nombre_curso",
      text: "Nombre del curso",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return (
          <Fragment>
            <div>{row.nombre_curso}</div>
            <small>{row.claveCurso}</small>
          </Fragment>
        )
      },
    },
    {
      dataField: "porcentaje_avance",
      text: "Progreso",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return (
          <div className="text-center">
            {Math.round((row.porcentaje_avance + Number.EPSILON) * 100) / 100}%
          </div>
        )
      },
    },
    {
      dataField: "last_activity",
      text: "Último ingreso",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return (
          <span key={`time-${row.IdCurso}`}>
            {moment(row.last_activity)
              .locale("es")
              .format("D [de] MMM [de] YYYY")}
          </span>
        )
      },
    },
    {
      dataField: "tiempo_dedicacion",
      text: "Tiempo dedicación",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return <div className="text-center">{row.tiempo_dedicacion} min.</div>
      },
    },
    {
      dataField: "aprobado",
      text: "Estatus",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return <div className="text-center">{getStatus(row)}</div>
      },
    },
  ]

  const pageOptions = {
    sizePerPage: sizePerPage === undefined ? 10 : sizePerPage,
    totalSize: courses?.length,
    custom: true,
    showTotal: true,
    page: page === undefined ? 1 : page,
    onPageChange: page => {
      setPage(page)
    },
    onSizePerPageChange: sizePerPage => {
      setSizePerPage(sizePerPage)
    },
  }

  const defaultSorted = [{ dataField: "claveCurso", order: "asc" }]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
  }

  const getXlsData = courses => {
    let data = courses.map((element, index) => {
      return {
        "Nombre del curso": `${element.nombre_curso}`,
        "Porcentaje de avance": `${element.porcentaje_avance}%`,
        "Último ingreso": moment(element.last_activity)
          .locale("es")
          .format("D [de] MMMM [de] YYYY"),
        "Tiempo dedicación": `${element.tiempo_dedicacion} min.`,
        Estatus: getStatusRaw(element),
      }
    })

    return {
      Cursos: data,
    }
  }

  return (
    <Fragment>
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="IdCurso"
        columns={columns}
        data={courses}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="IdCurso"
            columns={columns}
            data={courses}
            search
          >
            {toolkitProps => (
              <Fragment>
                {/*<Row>
                  <Col className="text-center">
                    <button className="btn btn-link">
                      <i className="fa fa-file-excel"></i> Exportar a Excel
                    </button>
                    {"   "}
                    <Link to="/courses" className="btn btn-link">
                      <i className="fa fa-users"></i> Ver todos los cursos
                    </Link>
                    {"   "}
                    <button className="btn btn-link">
                      <i className="fa fa-sync-alt"></i> Resetear cursos
                    </button>
                  </Col>
            </Row>*/}

                <Row>
                  <Col className="text-left">
                    <XlsExporter
                      classNames={`btn btn-link`}
                      data={getXlsData(courses)}
                      dataName={"Cursos"}
                      name="Cursos"
                      label="Exportar a Excel"
                    />
                    {"   "}
                    <Link to="/courses" className="btn btn-link">
                      <i className="fa fa-users"></i> Ver todos los cursos
                    </Link>
                    {"   "}
                    <button
                      className="btn btn-link"
                      onClick={props.onResetCourses}
                    >
                      <i className="fa fa-sync-alt"></i> Resetear cursos
                    </button>
                  </Col>
                  <Col
                    xl={{ size: 2 }}
                    lg={{ size: 3 }}
                    md={{ size: 4 }}
                    sm={{ size: 4 }}
                    xs={6}
                  >
                    <div className="search-box me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <SearchBar
                          {...toolkitProps.searchProps}
                          onSearch={value => {
                            setPage(1)
                            toolkitProps.searchProps.onSearch(value)
                          }}
                          placeholder="Buscar"
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl="12">
                    <Fragment>
                      <BootstrapTable
                        keyField={"IdCurso"}
                        bordered={false}
                        striped={true}
                        defaultSorted={defaultSorted}
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={"thead-light"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                      />
                      {courses.length <= 0 && (
                        <Row>
                          <Col md={12} className="text-center">
                            -- No hay registros --
                          </Col>
                        </Row>
                      )}
                    </Fragment>
                  </Col>
                </Row>

                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                    <div className="text-md-right ms-auto">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </Col>
                </Row>
              </Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
    </Fragment>
  )
}

ParticipantCourseGroupListTable.propTypes = {
  courses: PropTypes.array,
  onResetCourses: PropTypes.func,
}

export default ParticipantCourseGroupListTable
