import { Fragment, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb2"
import HubUserFormBasic from "./HubUserFormBasic"
import HubUsersFormCelf from "./HubUsersFormCelf"
import HubUsersFormCmo from "./HubUsersFormCmo"

const HubUsersAdd = props => {
  const [stage, setStage] = useState(0)
  const [userInfo, setUserInfo] = useState({})

  return (
    <Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Registrar usuario | La Academia FEster</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Registrar usuario</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  { key: "home", title: "La Academia Fester", link: "/Home" },
                  { key: "hub-users", title: "Registrar usuario" },
                ]}
              />
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {stage === 0 && (
                    <HubUserFormBasic
                      insert={true}
                      onConfirm={data => {
                        setUserInfo({
                          ...userInfo,
                          ...data,
                        })
                        setStage(1)
                      }}
                    />
                  )}
                  {stage === 1 && (
                    <HubUsersFormCelf
                      insert={true}
                      onConfirm={data => {
                        setUserInfo({
                          ...userInfo,
                          ...data,
                        })
                        setStage(2)
                      }}
                      onCancel={() => setStage(0)}
                    />
                  )}
                  {stage === 2 && (
                    <HubUsersFormCmo
                      insert={true}
                      onConfirm={data => {
                        setUserInfo({
                          ...userInfo,
                          ...data,
                        })
                        setStage(3)
                      }}
                      onCancel={() => setStage(1)}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default HubUsersAdd
