import {
  GET_SOFTSKILLS_ABILITY,
  GET_SOFTSKILLS_ABILITY_SUCCESS,
  GET_SOFTSKILLS_ABILITY_CLEAN,
  GET_SOFTSKILLS_ABILITY_ERROR,
} from "./actionTypes"

export const getSoftskillsAbility = form => ({
  type: GET_SOFTSKILLS_ABILITY,
  payload: { form },
})

export const getSoftskillsAbilitySuccess = ability => {
  return {
    type: GET_SOFTSKILLS_ABILITY_SUCCESS,
    payload: { ability },
  }
}

export const getSoftskillsAbilityClean = () => {
  return {
    type: GET_SOFTSKILLS_ABILITY_CLEAN,
    payload: {},
  }
}

export const getSoftskillsAbilityError = error => {
  return {
    type: GET_SOFTSKILLS_ABILITY_ERROR,
    payload: error,
  }
}
