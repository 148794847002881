/* eslint-disable no-undef */
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  ACTIVATE_DEACTIVATE_USER,
  EDIT_USER,
  GET_USER,
  UPDATE_ABOUT_ME,
  UPDATE_PASSWORD,
  UPDATE_POSITION,
  UPDATE_PROFILE_PICTURE,
} from "./actionTypes"
import {
  apiError,
  editUserSuccess,
  getUserSuccess,
  activateDeactivateSuccess,
  activateDeactivateError,
  updateProfilePictureSuccess,
  updateProfilePictureError,
  updatePasswordSuccess,
  updatePasswordError,
  updatePositionError,
  updatePositionSuccess,
  updateAboutMeError,
  updateAboutMeSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  updateUserNewApi,
  getUserNewApi,
  activateDeactivateUserNewApi,
  updateProfilePictureNewApi,
  changeUserPasswordNewApi,
  changePosition,
  changeAboutMe,
} from "../../../helpers/backend_helper"

function* get(params) {
  try {
    const response = yield call(getUserNewApi, params.payload.id)
    yield put(getUserSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* edit(action) {
  try {
    const response = yield call(updateUserNewApi, action.payload.form)
    if (response == undefined) throw new Error("API error")
    if (response.error) throw new Error(response)
    yield put(editUserSuccess(response))
  } catch (error) {
    yield put(apiError(error.message))
  }
}

function* activationDeactivation(action) {
  try {
    const response = yield call(activateDeactivateUserNewApi, {
      ...action.payload,
    })
    if (response == undefined) throw new Error("API error")
    if (!response.success)
      throw new Error("Error al guardar el estatus del usuario")
    yield put(activateDeactivateSuccess(response))
  } catch (error) {
    yield put(activateDeactivateError(error))
  }
}

function* profilePictureEdit(action) {
  try {
    const response = yield call(updateProfilePictureNewApi, action.payload)
    if (response.statusCode !== undefined)
      throw new Error("No se realizó la carga correctamente")
    yield put(updateProfilePictureSuccess(response))
  } catch (error) {
    yield put(updateProfilePictureError(error))
  }
}

function* passwordEdit({
  payload: { password, newPassword, registerUserId, userId },
}) {
  try {
    const response = yield call(changeUserPasswordNewApi, {
      password: password,
      newPassword: newPassword,
      registerUserId: registerUserId,
      userId: userId,
    })
    if (response.statusCode !== undefined) {
      yield put(updatePasswordError(response.message))
      return
    }
    yield put(updatePasswordSuccess())
  } catch (error) {
    yield put(updatePasswordError(error))
  }
}

function* userPositionEdit({ payload: { positionId, userId } }) {
  try {
    const response = yield call(changePosition, {
      id: positionId,
      userId,
    })
    if (response.statusCode !== undefined) {
      yield put(updatePositionError(response.message))
      return
    }
    yield put(updatePositionSuccess())
  } catch (error) {
    yield put(updatePositionError(error))
  }
}

function* aboutMeEdit({ payload: { text, userId } }) {
  try {
    const response = yield call(changeAboutMe, {
      text,
      userId,
    })
    if (response.statusCode !== undefined) {
      yield put(updateAboutMeError(response.message))
      return
    }
    yield put(updateAboutMeSuccess())
  } catch (error) {
    yield put(updateAboutMeError(error))
  }
}

function* userEditSaga() {
  yield takeEvery(EDIT_USER, edit)
  yield takeEvery(GET_USER, get)
  yield takeEvery(ACTIVATE_DEACTIVATE_USER, activationDeactivation)
  yield takeEvery(UPDATE_PROFILE_PICTURE, profilePictureEdit)
  yield takeEvery(UPDATE_PASSWORD, passwordEdit)
  yield takeEvery(UPDATE_POSITION, userPositionEdit)
  yield takeEvery(UPDATE_ABOUT_ME, aboutMeEdit)
}

export default userEditSaga
