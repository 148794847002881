import React, { useState, Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit"
import avatar1 from "../../assets/images/user_without_pic.png"
import moment from "moment"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  listAllEvents,
  listLocations,
  getUsersByRole,
  deleteEvent,
  deleteEventClean,
} from "../../store/actions"
import "../../assets/scss/datatables.scss"
import { useDispatch, useSelector } from "react-redux"
import { numberWithCommas } from "common/strings"

const EventosListTable = props => {
  const dispatch = useDispatch()
  const { SearchBar } = Search

  const [page, setPage] = useState(undefined)
  const [filteredEvents, setFilteredEvents] = useState([])
  const [trash, setTrash] = useState(undefined)
  const [sizePerPage, setSizePerPage] = useState(undefined)

  const {
    error,
    loading,
    done,
    events,
    whoIAm,
    trashDone,
    usersByRole,
    locations,
  } = useSelector(state => ({
    error: state.AllEventsList.error,
    loading: state.AllEventsList.loading,
    events: state.AllEventsList.events,
    done: state.AllEventsList.done,
    whoIAm: state.Profile.user,
    trashDone: state.EventDelete.done,
    usersByRole: state.UsersList.usersByRole,
    locations: state.LocationsList.locations,
  }))

  const userId = whoIAm?.idUsuario ?? 0
  const profileId = whoIAm?.idRol ?? 0

  useEffect(() => {
    dispatch(getUsersByRole(2))
    dispatch(listLocations())
  }, [])

  useEffect(() => {
    if (usersByRole.length > 0 && locations.length > 0) {
      dispatch(listAllEvents())
    }
  }, [usersByRole, locations])

  const handleDeleteEvent = id => {
    setTrash(id)
  }

  if (trashDone) {
    dispatch(deleteEventClean())
    dispatch(listAllEvents())
  }

  const getEventIcon = eventType => {
    if (eventType === "Presencial") return "map-marker"
    if (eventType === "Webinar") return "desktop"
    return "calendar"
  }

  const columns = [
    {
      dataField: "eventId",
      text: "Id",
      sort: false,
      hidden: true,
    },
    {
      dataField: "eventName",
      text: "Nombre del evento",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        const user = usersByRole.find(
          user => user.userId === row.responsibleUserId
        )
        return (
          <div key={`event-${row.eventId}`}>
            <strong>
              <Link to={`/start/adm-eventos/edit/${row.eventId}`}>
                {row.name}
              </Link>
            </strong>
            <br />
            {user !== undefined ? (
              <span>
                <i className="fa fa-user"></i> {user.firstName} {user.lastName}
              </span>
            ) : (
              <span></span>
            )}
          </div>
        )
      },
      csvFormatter: (column, row) => `${row.eventName}`,
    },
    {
      dataField: "eventType",
      text: "Tipo de evento",
      sort: true,
      formatter: (column, row) => {
        return (
          <div key={`event-type-${row.eventId}`}>
            <i className={`fa fa-${getEventIcon(row.eventType)}`}></i>{" "}
            {row.eventType ?? "Sin definir"}
          </div>
        )
      },
    },
    {
      dataField: "responsibleName",
      text: "Responsable",
      sort: true,
      csvExport: false,
      hidden: true,
    },
    {
      dataField: "startDate",
      text: "Fecha",
      sort: true,
      csvExport: true,
      hidden: false,
      formatter: (column, row) => {
        return (
          <div key={`start-date-${row.eventId}`}>
            {moment(row.startDate).locale("es").format("MMMM DD")}
            {moment(row.startDate).locale("es").format("YYYY") ===
            moment(row.endDate).locale("es").format("YYYY")
              ? ""
              : `, ${moment(row.startDate).locale("es").format("YYYY")}`}{" "}
            a {moment(row.endDate).locale("es").format("MMMM DD, YYYY")}
          </div>
        )
      },
      csvFormatter: (column, row) => `${row.eventName}`,
    },
    {
      dataField: "endDate",
      text: "Fecha fin",
      sort: true,
      csvExport: true,
      hidden: true,
    },
    {
      dataField: "status",
      text: "Estatus",
      sort: true,
      align: "center",
      CSVExport: false,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        const now = moment(new Date())
        const endDate = moment(new Date(row.endDate))
        const diff = endDate.diff(now, "days")
        console.log(diff)
        return (
          <h4 style={{ marginBottom: 0 }} key={`status-${row.status}`}>
            <span
              className={`pt-2 badge bg-${
                diff > 7 ? "success" : diff < 0 ? "dark" : "danger"
              }`}
            >
              {diff > 7 ? "Activo" : diff < 0 ? "Realizado" : "Próximamente"}
            </span>
          </h4>
        )
      },
      csvFormatter: (column, row) => row.status,
    },
    {
      dataField: "edit",
      text: "Editar",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return (
          <div key={`edit-${row.eventId}`}>
            <Link
              className="btn btn-link"
              to={`/start/adm-eventos/edit/${row.eventId}`}
            >
              <i className="fa fa-edit"></i>
            </Link>
          </div>
        )
      },
      align: "center",
      CSVExport: false,
    },
    {
      dataField: "delete",
      text: "Eliminar",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return (
          <div key={`remove-${row.eventId}`}>
            <button
              className="btn btn-link"
              onClick={() => handleDeleteEvent(row.eventId)}
            >
              <i className="fa fa-trash"></i>
            </button>
          </div>
        )
      },
      align: "center",
      CSVExport: false,
    },
  ]

  const pageOptions = {
    sizePerPage: sizePerPage === undefined ? 10 : sizePerPage,
    totalSize: filteredEvents.length,
    custom: true,
    showTotal: true,
    page: page === undefined ? 1 : page,
    onPageChange: page => {
      setPage(page)
    },
    onSizePerPageChange: sizePerPage => {
      setSizePerPage(sizePerPage)
    },
  }

  const defaultSorted = [{ dataField: "startDate", order: "asc" }]

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  return loading && events === undefined ? (
    <div className="mt-3 mb-3">
      <div className="spinner-chase">
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
      </div>
    </div>
  ) : (
    <Fragment>
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="eventId"
        columns={columns}
        data={events}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="eventId"
            columns={columns}
            data={events}
            search={{
              afterSearch: newData => {
                setFilteredEvents(newData)
              },
            }}
          >
            {toolkitProps => (
              <React.Fragment>
                <Row className="mb-2 pt-3">
                  <Col lg={2} md={3} sm={4} xs={4}>
                    <div className="d-grid">
                      <Link
                        to="/start/adm-eventos/add"
                        className="btn btn-primary btn-rounded"
                      >
                        <i className="fa fa-plus"></i> Agregar evento
                      </Link>
                    </div>
                  </Col>
                  <Col
                    lg={8}
                    md={6}
                    sm={4}
                    xs={4}
                    className="text-center"
                  ></Col>
                  <Col
                    lg={{ size: 2 }}
                    md={{ size: 3 }}
                    sm={{ size: 4 }}
                    xs={4}
                  >
                    <div className="search-box me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <SearchBar
                          {...toolkitProps.searchProps}
                          onSearch={value => {
                            setPage(1)
                            toolkitProps.searchProps.onSearch(value)
                          }}
                          placeholder="Buscar"
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Col>
                </Row>

                {events !== undefined && filteredEvents !== undefined && (
                  <Row>
                    <Col xs={12}>
                      <p>
                        <span>
                          Mostrando{" "}
                          <strong>{numberWithCommas(events.length)}</strong>{" "}
                          eventos
                        </span>
                      </p>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <Fragment>
                        <BootstrapTable
                          keyField={"eventId"}
                          bordered={false}
                          striped={true}
                          defaultSorted={defaultSorted}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                        {events?.length <= 0 && (
                          <Row>
                            <Col md={12} className="text-center">
                              -- No hay registros --
                            </Col>
                          </Row>
                        )}
                      </Fragment>
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                    <div className="text-md-right ms-auto">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
      {trash !== undefined && (
        <SweetAlert
          title="Eliminar evento"
          warning
          confirmBtnBsStyle="danger"
          confirmBtnText="Sí, eliminar"
          showCancel={true}
          cancelBtnBsStyle="light"
          cancelBtnText="No, cancelar"
          onConfirm={() => {
            dispatch(deleteEvent(trash))
            setTrash(undefined)
          }}
          onCancel={() => {
            setTrash(undefined)
          }}
        >
          <p>¿Confirmas que deseas eliminar este evento?</p>
        </SweetAlert>
      )}
    </Fragment>
  )
}

EventosListTable.propTypes = {
  events: PropTypes.array,
  onUpdateStatus: PropTypes.func,
}

export default EventosListTable
