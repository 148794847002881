import {
  LIST_LOCATIONS,
  LIST_LOCATIONS_SUCCESS,
  LIST_LOCATIONS_ERROR,
} from "./actionTypes"

export const listLocations = () => ({
  type: LIST_LOCATIONS,
  payload: {},
})

export const listLocationsSuccess = locations => {
  return {
    type: LIST_LOCATIONS_SUCCESS,
    payload: { locations },
  }
}

export const listLocationsError = error => {
  return {
    type: LIST_LOCATIONS_ERROR,
    payload: error,
  }
}
