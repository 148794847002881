/* eslint-disable no-undef */
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { GET_SOFTSKILLS_USERS_ALL } from "./actionTypes"
import {
  getSoftskillsUsersAllError,
  getSoftskillsUsersAllSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { getSoftskillsUsers } from "../../../helpers/backend_helper"

function* listSoftskillsUsersAll(action) {
  try {
    const response = yield call(getSoftskillsUsers, action.payload.userId)
    yield put(getSoftskillsUsersAllSuccess(response))
  } catch (error) {
    yield put(getSoftskillsUsersAllError(error))
  }
}

function* softskillsUsersAllSaga() {
  yield takeEvery(GET_SOFTSKILLS_USERS_ALL, listSoftskillsUsersAll)
}

export default softskillsUsersAllSaga
