import React, { Component, Fragment, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Row, Col, Card, CardBody } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb2"
import CourseKnowledgesListTable from "components/CourseKnowledges/CourseKnowledgesListTable"

const CourseKnowledgesList = props => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Conocimientos | Capacitación en Línea Fester</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Catálogo de conocimientos</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  { key: "dashboard", title: "Dashboard", link: "/dashboard" },
                  { key: "catalog", title: "Catálogos", link: "/catalog" },
                  { key: "course_knowledges", title: "Conocimientos" },
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CourseKnowledgesListTable />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CourseKnowledgesList
