import {
  REMOVE_SOFTSKILL_ABILITY_USER,
  REMOVE_SOFTSKILL_ABILITY_USER_SUCCESS,
  REMOVE_SOFTSKILL_ABILITY_USER_CLEAN,
  REMOVE_SOFTSKILL_ABILITY_USER_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  abilityUser: {},
}

const SoftskillAbilityUserRemove = (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_SOFTSKILL_ABILITY_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case REMOVE_SOFTSKILL_ABILITY_USER_SUCCESS:
      state = {
        ...state,
        abilityUser: action.payload.abilityUser,
        loading: false,
        done: true,
      }
      break
    case REMOVE_SOFTSKILL_ABILITY_USER_CLEAN:
      state = initialState
      break
    case REMOVE_SOFTSKILL_ABILITY_USER_ERROR:
      state = { ...state, error: action.payload, loading: false, done: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default SoftskillAbilityUserRemove
