import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Row, Container, Col, Card, CardBody, Label } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { AvForm, AvInput } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import Report2ListTable from "components/Reports/Report2ListTable"
import {
  getCourses,
  listReport2,
  listReport2Clean,
  getLevels,
  getUsers,
  getGroups,
  getCustomers,
} from "store/actions"
import { LoadingIndicator } from "components/Common/LoadingIndicator"
import XlsExporter from "components/Common/XlsExporter"
import ReportFilters from "components/Reports/ReportFilters"
import Report2Chart from "components/Reports/Report2Chart"

const Report2 = props => {
  const dispatch = useDispatch()

  const [submit, setSubmit] = useState(false)
  const [reportInfo, setReportInfo] = useState([])

  const {
    error,
    loading,
    done,
    report,
    courses,
    users,
    customers,
    groups,
    levels,
    whoIAm,
  } = useSelector(state => ({
    error: state.Report2List.error,
    loading: state.Report2List.loading,
    report: state.Report2List.report,
    done: state.Report2List.done,
    courses: state.CoursesList.coursesManagement,
    users: state.UsersList.users,
    levels: state.LevelsList.levels,
    customers: state.CustomersList.customers,
    groups: state.GroupsList.groups,
    whoIAm: state.Profile.user,
  }))

  useEffect(() => {
    dispatch(getCourses())
    dispatch(getLevels())
    dispatch(getUsers())
    dispatch(getGroups())
    dispatch(getCustomers())
  }, [])

  const onSubmit = data => {
    dispatch(listReport2({ ...data, status: true, userId: whoIAm.idUsuario }))
  }

  /*const handleSubmit = (event, errors, values) => {
    const idh = idhs === "" ? [] : idhs.split(",")
    dispatch(
      listReport2({
        idCurso: coursesSelectedMulti.map(course => course.value),
        idRol: profilesSelectedMulti.map(profile => profile.value),
        idUsuario: usersSelectedMulti.map(user => user.value),
        Idh: idh.map(value => value.trim()),
        nivelCurso: courseLevelsSelectedMulti.map(
          courseLevel => courseLevel.value
        ),
        status: null,
      })
    )
  }*/

  const getXlsData = report => {
    let data = report.map((element, index) => {
      return {
        Nombre: element.nombre,
        Apellido: element.apellido,
        "Cursos con avance del 0%": element.num_usuarios_pctavance_0,
        "Cursos con avance mayor a 0% y hasta 30%":
          element.num_usuarios_pctavance_0_30,
        "Cursos con avance mayor a 30% y hasta 60%":
          element.total_usuarios_porcentaje_avance_30_60,
        "Cursos con avance mayor a 60% y menor a 100%":
          element.total_usuarios_porcentaje_avance_60_99,
        "Cursos con avance al 100%":
          element.total_usuarios_porcentaje_avance_100,
      }
    })

    return {
      Reporte2: data,
    }
  }

  if (done) {
    setSubmit(true)
    setReportInfo(report)
    dispatch(listReport2Clean())
  }

  /*const handleCourseLevelsMulti = courseLevelsSelectedMulti =>
    setCourseLevelsSelectedMulti(courseLevelsSelectedMulti)

  const courseLevelsOptions =
    levels !== undefined
      ? levels.map(level => {
          return {
            label: `${level.level}`,
            value: level.idLevel,
          }
        })
      : []

  const courseLevelsOptionGroup = [
    {
      label: "Niveles",
      options: courseLevelsOptions,
    },
  ]*/

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Reporte 2 - Usuarios por % Avance de curso | Capacitación en Línea
            Fester
          </title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Reporte 2 - Usuarios por % Avance de curso</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  { key: "dashboard", title: "Dashboard", link: "/dashboard" },
                  { key: "Reports", title: "Analítica", link: "/reports" },
                  { key: "Report2", title: "Reporte 2" },
                ]}
              />
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {error !== "" && (
                    <div className="alert alert-danger mb-3">
                      Ocurrió un error al solicitar la información.
                    </div>
                  )}
                  <ReportFilters
                    title={"Reporte 2"}
                    onSubmit={values => onSubmit(values)}
                    xlsButton={
                      <XlsExporter
                        classNames={`btn btn-light mt-2 btn-block ${
                          submit ? "" : "disabled"
                        }`}
                        data={getXlsData(reportInfo)}
                        dataName={"Reporte2"}
                        name="Reporte 2"
                        label="Exportar"
                      />
                    }
                    users={users}
                    courses={courses}
                    customers={customers}
                    groups={groups}
                    levels={levels}
                  />
                  {!loading && !submit && <div style={{ height: 260 }}></div>}
                  {loading && (
                    <div className="d-flex justify-content-center">
                      <LoadingIndicator />
                    </div>
                  )}
                  {!loading && submit && (
                    <div className="mt-4">
                      <Report2Chart data={reportInfo} />
                      <br />
                      <Report2ListTable loading={loading} report={reportInfo} />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Report2
