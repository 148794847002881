/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { EDIT_POSITION } from "./actionTypes"
import { apiError, editPositionSuccess } from "./actions"

//Include Both Helper File with needed methods
import { updatePosition } from "../../../helpers/backend_helper"

function* edit(action) {
  try {
    const response = yield call(updatePosition, action.payload.form)
    if (response === undefined) yield put(apiError("API error"))
    if (!response.success)
      yield put(apiError(`Error al editar posición: ${response.error}`))
    else yield put(editPositionSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* positionEditSaga() {
  yield takeEvery(EDIT_POSITION, edit)
}

export default positionEditSaga
