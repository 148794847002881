import {
  ADD_SOFTSKILL_ABILITY,
  ADD_SOFTSKILL_ABILITY_SUCCESS,
  ADD_SOFTSKILL_ABILITY_CLEAN,
  ADD_SOFTSKILL_ABILITY_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  ability: {},
}

const SoftskillAbilityAdd = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SOFTSKILL_ABILITY:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_SOFTSKILL_ABILITY_SUCCESS:
      state = {
        ...state,
        ability: action.payload.ability,
        loading: false,
        done: true,
      }
      break
    case ADD_SOFTSKILL_ABILITY_CLEAN:
      state = initialState
      break
    case ADD_SOFTSKILL_ABILITY_ERROR:
      state = { ...state, error: action.payload, loading: false, done: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default SoftskillAbilityAdd
