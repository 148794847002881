/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { GET_SOFTSKILLS_SURVEY } from "./actionTypes"
import { getSoftskillsSurveyError, getSoftskillsSurveySuccess } from "./actions"

//Include Both Helper File with needed methods
import { getSoftskillsCourseUserSurvey } from "../../../helpers/backend_helper"

function* get(action) {
  try {
    const response = yield call(
      getSoftskillsCourseUserSurvey,
      action.payload.form
    )
    if (response == undefined) throw new Error("API error")
    yield put(getSoftskillsSurveySuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(getSoftskillsSurveyError(error.message))
  }
}

function* softskillsSurveyGetSaga() {
  yield takeEvery(GET_SOFTSKILLS_SURVEY, get)
}

export default softskillsSurveyGetSaga
