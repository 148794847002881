import React, { Component, Fragment, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import Breadcrumbs from "../../../components/Common/Breadcrumb2"
import SoftskillsNotificationsListTable from "components/SoftSkills/Notifications/SoftskillsNotificationsListTable"

const SoftskillsNotificationsList = props => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Notificaciones | Soft Skills</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Notificaciones</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  { key: "dashboard", title: "Inicio", link: "/soft-skills" },
                  { key: "notifications", title: "Notificaciones" },
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Link to={"/soft-skills"} className="btn btn-link">
                        <i
                          className="fa fa-times"
                          style={{ fontSize: "24px" }}
                        ></i>
                      </Link>
                    </Col>
                  </Row>
                  <SoftskillsNotificationsListTable />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SoftskillsNotificationsList
