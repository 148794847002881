/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { EDIT_SHOPPING_AREA } from "./actionTypes"
import { apiError, editShoppingAreaSuccess } from "./actions"

//Include Both Helper File with needed methods
import { updateShoppingArea } from "../../../helpers/backend_helper"

function* edit(action) {
  try {
    const response = yield call(updateShoppingArea, action.payload.form)
    if (response === undefined) yield put(apiError("API error"))
    if (!response.success)
      yield put(apiError(`Error al editar zona comercial: ${response.error}`))
    else yield put(editShoppingAreaSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* shoppingAreaEditSaga() {
  yield takeEvery(EDIT_SHOPPING_AREA, edit)
}

export default shoppingAreaEditSaga
