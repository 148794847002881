import {
  REVIEW_SOFTSKILL_COURSE_USER,
  REVIEW_SOFTSKILL_COURSE_USER_SUCCESS,
  REVIEW_SOFTSKILL_COURSE_USER_CLEAN,
  REVIEW_SOFTSKILL_COURSE_USER_ERROR,
} from "./actionTypes"

export const reviewSoftskillCourseUser = form => ({
  type: REVIEW_SOFTSKILL_COURSE_USER,
  payload: { form },
})

export const reviewSoftskillCourseUserSuccess = course => {
  return {
    type: REVIEW_SOFTSKILL_COURSE_USER_SUCCESS,
    payload: { course },
  }
}

export const reviewSoftskillCourseUserClean = () => {
  return {
    type: REVIEW_SOFTSKILL_COURSE_USER_CLEAN,
    payload: {},
  }
}

export const reviewSoftskillCourseUserError = error => {
  return {
    type: REVIEW_SOFTSKILL_COURSE_USER_ERROR,
    payload: error,
  }
}
