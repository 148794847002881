import {
  GET_SOFTSKILLS_USER_COURSES,
  GET_SOFTSKILLS_USER_COURSES_SUCCESS,
  GET_SOFTSKILLS_USER_COURSES_ERROR,
} from "./actionTypes"

export const getSoftskillsUserCourses = userId => ({
  type: GET_SOFTSKILLS_USER_COURSES,
  payload: { userId },
})

export const getSoftskillsUserCoursesSuccess = courses => {
  return {
    type: GET_SOFTSKILLS_USER_COURSES_SUCCESS,
    payload: { courses },
  }
}

export const getSoftskillsUserCoursesError = error => {
  return {
    type: GET_SOFTSKILLS_USER_COURSES_ERROR,
    payload: error,
  }
}
