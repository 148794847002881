import {
  GET_COURSES,
  GET_COURSES_SUCCESS,
  GET_COURSES_ERROR,
  GET_COURSES_BY_USER,
  GET_COURSES_BY_USER_SUCCESS,
  GET_COURSES_BY_USER_AND_GROUP,
  GET_COURSES_BY_USER_AND_GROUP_SUCCESS,
  GET_COURSES_BY_USER_AND_GROUP_CLEAN,
  GET_COURSES_BY_USER_AND_GROUP_ERROR,
  SEND_TRASH,
  SEND_TRASH_ERROR,
  SEND_TRASH_SUCCESS,
  SEND_TRASH_CLEAN,
} from "./actionTypes"

export const getCourses = () => ({
  type: GET_COURSES,
  payload: {},
})

export const getCoursesSuccess = courses => {
  return {
    type: GET_COURSES_SUCCESS,
    payload: { courses },
  }
}

export const getCoursesByUser = idUser => ({
  type: GET_COURSES_BY_USER,
  payload: { idUser },
})

export const getCoursesByUserSuccess = courses => {
  return {
    type: GET_COURSES_BY_USER_SUCCESS,
    payload: { courses },
  }
}

export const getCoursesByUserAndGroup = (userId, groupId) => ({
  type: GET_COURSES_BY_USER_AND_GROUP,
  payload: { userId, groupId },
})

export const getCoursesByUserAndGroupSuccess = courses => {
  return {
    type: GET_COURSES_BY_USER_AND_GROUP_SUCCESS,
    payload: { courses },
  }
}

export const getCoursesByUserAndGroupClean = () => {
  return {
    type: GET_COURSES_BY_USER_AND_GROUP_CLEAN,
    payload: {},
  }
}

export const getCoursesByUserAndGroupError = error => {
  return {
    type: GET_COURSES_BY_USER_AND_GROUP_ERROR,
    payload: error,
  }
}

export const apiError = error => {
  return {
    type: GET_COURSES_ERROR,
    payload: error,
  }
}

export const sendToTrash = id => {
  return {
    type: SEND_TRASH,
    payload: { id },
  }
}

export const sendToTrashSuccess = courses => {
  return {
    type: SEND_TRASH_SUCCESS,
    payload: {},
  }
}

export const sendToTrashError = error => {
  return {
    type: SEND_TRASH_ERROR,
    payload: error,
  }
}

export const sendToTrashClean = () => {
  return {
    type: SEND_TRASH_CLEAN,
    payload: {},
  }
}
