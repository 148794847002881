/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { ALL_SOFTSKILLS_ABILITIES_USERS } from "./actionTypes"
import {
  allSoftskillsAbilitiesUsersError,
  allSoftskillsAbilitiesUsersSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { getAllSoftskillsAbilitiesUsers } from "../../../helpers/backend_helper"

function* listAllSoftskillsAbilitiesUsers(action) {
  try {
    const response = yield call(
      getAllSoftskillsAbilitiesUsers,
      action.payload.userId
    )
    yield put(allSoftskillsAbilitiesUsersSuccess(response))
  } catch (error) {
    yield put(allSoftskillsAbilitiesUsersError(error))
  }
}

function* softskillsAbilitiesUsersAllSaga() {
  yield takeEvery(
    ALL_SOFTSKILLS_ABILITIES_USERS,
    listAllSoftskillsAbilitiesUsers
  )
}

export default softskillsAbilitiesUsersAllSaga
