/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { TRASH_SOFTSKILLS_COURSE } from "./actionTypes"
import {
  trashSoftskillsCourseError,
  trashSoftskillsCourseSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { sendSoftskillsCourseToTrash } from "../../../helpers/backend_helper"

function* get(action) {
  try {
    const response = yield call(sendSoftskillsCourseToTrash, action.payload)
    if (response == undefined) throw new Error("API error")
    yield put(trashSoftskillsCourseSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(trashSoftskillsCourseError(error.message))
  }
}

function* softskillCourseTrashSaga() {
  yield takeEvery(TRASH_SOFTSKILLS_COURSE, get)
}

export default softskillCourseTrashSaga
