import {
  GET_COURSES,
  GET_COURSES_SUCCESS,
  GET_COURSES_ERROR,
  GET_COURSES_BY_USER,
  GET_COURSES_BY_USER_SUCCESS,
  GET_COURSES_BY_USER_AND_GROUP,
  GET_COURSES_BY_USER_AND_GROUP_SUCCESS,
  GET_COURSES_BY_USER_AND_GROUP_CLEAN,
  GET_COURSES_BY_USER_AND_GROUP_ERROR,
  SEND_TRASH,
  SEND_TRASH_SUCCESS,
  SEND_TRASH_ERROR,
  SEND_TRASH_CLEAN,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  trashDone: false,
  courses: [],
  coursesManagement: [],
  coursesByUserAndGroup: [],
}

const CoursesList = (state = initialState, action) => {
  switch (action.type) {
    case GET_COURSES:
      state = {
        ...state,
        coursesManagement: [],
        loading: true,
      }
      break
    case GET_COURSES_SUCCESS:
      state = {
        ...state,
        coursesManagement: action.payload.courses,
        loading: false,
        done: true,
      }
      break
    case GET_COURSES_BY_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_COURSES_BY_USER_SUCCESS:
      state = {
        ...state,
        courses: action.payload.courses,
        loading: false,
        done: true,
      }
      break
    case GET_COURSES_ERROR:
      state = { ...state, error: action.payload, loading: false, done: true }
      break
    case GET_COURSES_BY_USER_AND_GROUP:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_COURSES_BY_USER_AND_GROUP_SUCCESS:
      state = {
        ...state,
        coursesByUserAndGroup: action.payload.courses,
        loading: false,
        done: true,
      }
      break
    case GET_COURSES_BY_USER_AND_GROUP_CLEAN:
      state = initialState
      break
    case GET_COURSES_BY_USER_AND_GROUP_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        done: false,
        coursesByUserAndGroup: [],
      }
      break
    case SEND_TRASH:
      state = {
        ...state,
        loading: true,
        trashDone: false,
      }
      break
    case SEND_TRASH_SUCCESS:
      state = {
        ...state,
        loading: false,
        trashDone: true,
      }
      break
    case SEND_TRASH_ERROR:
      state = {
        ...state,
        loading: false,
        trashDone: false,
      }
      break
    case SEND_TRASH_CLEAN:
      state = {
        ...state,
        loading: false,
        trashDone: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default CoursesList
