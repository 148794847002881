import {
  TRASH_SOFTSKILLS_COURSE,
  TRASH_SOFTSKILLS_COURSE_SUCCESS,
  TRASH_SOFTSKILLS_COURSE_CLEAN,
  TRASH_SOFTSKILLS_COURSE_ERROR,
} from "./actionTypes"

export const trashSoftskillsCourse = form => ({
  type: TRASH_SOFTSKILLS_COURSE,
  payload: { ...form },
})

export const trashSoftskillsCourseSuccess = course => {
  return {
    type: TRASH_SOFTSKILLS_COURSE_SUCCESS,
    payload: { course },
  }
}

export const trashSoftskillsCourseClean = () => {
  return {
    type: TRASH_SOFTSKILLS_COURSE_CLEAN,
    payload: {},
  }
}

export const trashSoftskillsCourseError = error => {
  return {
    type: TRASH_SOFTSKILLS_COURSE_ERROR,
    payload: error,
  }
}
