import { Fragment } from "react"
import { Row, Col } from "reactstrap"
import { PropTypes } from "prop-types"

const ApplicationBox = props => {
  return (
    <Fragment>
      <Row className="blue-box">
        <Col xs={4}>
          <img src={props.image} alt={props.name} className="img-fluid" />
        </Col>
        <Col xs={8}>
          <h4>{props.name}</h4>
          <p className="detail">{props.description}</p>
          <div className="text-center mb-2 mt-4">
            <a
              href={props.link}
              className="btn btn-secondary btn-lg"
              rel="noreferrer"
            >
              Ingresar
            </a>
          </div>
        </Col>
      </Row>
      <style jsx>{`
        h4 {
          color: #005596;
        }
        .blue-box {
          padding: 16px;
          border-radius: 4px;
          background-color: #00559615;
          border-color: #005596;
          margin-left: 8px;
        }
        .detail {
          margin-bottom: 0;
          font-size: 18px;
        }
      `}</style>
    </Fragment>
  )
}

ApplicationBox.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
}

export default ApplicationBox
