/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { TRY_POLL } from "./actionTypes"
import { tryPollError, tryPollSuccess } from "./actions"

//Include Both Helper File with needed methods
import { tryPoll } from "../../../helpers/backend_helper"

function* poll(action) {
  try {
    const response = yield call(tryPoll, action.payload.form)
    if (response === undefined) throw new Error("Error al guardar encuesta")
    if (!response.success)
      throw new Error(`Error al guardar encuesta: ${response.error}`)
    yield put(tryPollSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(tryPollError(`${error}`))
  }
}

function* pollTrySaga() {
  yield takeEvery(TRY_POLL, poll)
}

export default pollTrySaga
