import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { Modal, Input, Row, Col, FormGroup, Label } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { updatePosition, updatePositionClean } from "store/actions"

const ChangePositionModal = props => {
  const dispatch = useDispatch()
  const [formError, setFormError] = useState(false)
  const [position, setPosition] = useState(0)

  const { loading, error, done } = useSelector(store => ({
    loading: store.UserEdit.updatePositionLoading,
    error: store.UserEdit.updatePositionError,
    done: store.UserEdit.updatePositionSuccess,
  }))

  useEffect(() => {
    setPosition(props.positionId)
  }, [props.positionId])

  const handleSubmit = (event, errors, values) => {
    if (errors.length > 0) {
      setFormError(true)
      return
    }

    dispatch(updatePosition(position, props.idUsuario))
  }

  if (done) {
    dispatch(updatePositionClean())
    props.onConfirm()
  }

  return (
    <Modal isOpen={props.show} backdrop={"static"} id="staticBackdrop">
      <AvForm className="form-horizontal" onSubmit={handleSubmit}>
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Cambiar ocupación
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              props.onCancel()
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <Row>
            <Col xs={12}>
              {formError && (
                <div className="alert alert-danger">
                  Existen errores en el formulario.
                </div>
              )}
            </Col>
            <Col xs={12} className="mb-3">
              <AvField
                name="position"
                value={props.positionId}
                className="form-control"
                placeholder=""
                type="select"
                onChange={event => {
                  setPosition(parseInt(event.target.value))
                }}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo es requerido",
                  },
                }}
              >
                <option key={0} value="">
                  -- Seleccione perfil --
                </option>
                {props.availablePositions.map((element, index) => (
                  <option value={element.idPosicion} key={element.idPosicion}>
                    {element.posicion}
                  </option>
                ))}
              </AvField>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              props.onCancel()
            }}
          >
            Cancelar
          </button>
          <button type="submit" className="btn btn-secondary">
            Confirmar
          </button>
        </div>
      </AvForm>
    </Modal>
  )
}

ChangePositionModal.propTypes = {
  show: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  availablePositions: PropTypes.array,
  positionId: PropTypes.number,
  idUsuario: PropTypes.number,
}

export default ChangePositionModal
