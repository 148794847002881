import { withRouter } from "react-router"
import { useParams } from "react-router-dom"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import GroupsForm from "components/Groups/GroupsForm"
import UsersGroupListTable from "components/Groups/UsersGroupListTable"
import CoursesGroupListTable from "components/Groups/CoursesGroupListTable"
import NotificationsGroup from "components/Groups/NotificationsGroup"
import ForumGroup from "components/Groups/ForumGroup"
import ProgressGroup from "components/Groups/ProgressGroup"
import { useDispatch, useSelector } from "react-redux"
import { getGroup } from "store/actions"

const GroupDetails = props => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const { groupFromRedux } = useSelector(state => ({
    groupFromRedux: state.GroupGet.group,
  }))

  useEffect(() => {
    dispatch(getGroup(id))
  }, [id])

  const group =
    groupFromRedux === undefined
      ? {
          idGrupo: id,
          nombreGrupo: "",
          tipoGrupo: 0,
          idNegocio: 0,
          idZonaComercial: 0,
          Responsible: [],
        }
      : groupFromRedux
  const [activeTab, setActiveTab] = useState("1")

  const toggleIconCustom = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Grupo: {group.nombreGrupo} | Capacitación en Línea Fester
          </title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h5>Grupo</h5>
              <h2 className="main-celf-color">{group.nombreGrupo}</h2>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  {
                    key: "dashboard",
                    title: "Dashboard",
                    link: "/dashboard",
                  },
                  { key: "grupos", title: "Grupos", link: "/groups" },
                  { key: "edit_group", title: `${group.nombreGrupo}` },
                ]}
              />
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Nav className="icon-tab nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleIconCustom("1")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-newspaper"></i> Información
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-newspaper"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleIconCustom("2")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-users"></i> Participantes
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-users"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "3",
                        })}
                        onClick={() => {
                          toggleIconCustom("3")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-book-open"></i> Cursos
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-book-open"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "4",
                        })}
                        onClick={() => {
                          toggleIconCustom("4")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-envelope"></i> Mensajes
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-envelope"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "5",
                        })}
                        onClick={() => {
                          toggleIconCustom("5")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-chart-line"></i> Progreso
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-chart-line"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "6",
                        })}
                        onClick={() => {
                          toggleIconCustom("6")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-people-arrows"></i> Foros
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-people-arrows"></i>
                        </span>
                      </NavLink>
                    </NavItem>*/}
                  </Nav>
                  <TabContent
                    activeTab={activeTab}
                    className="p-3 text-muted mt-4"
                  >
                    <TabPane tabId="1">
                      <GroupsForm
                        showPercentage={true}
                        percentage={82}
                        insert={false}
                        group={group}
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      <UsersGroupListTable group={group} />
                    </TabPane>
                    <TabPane tabId="3">
                      <CoursesGroupListTable group={group} />
                    </TabPane>
                    <TabPane tabId="4">
                      <NotificationsGroup />
                    </TabPane>
                    <TabPane tabId="5">
                      <ProgressGroup />
                    </TabPane>
                    <TabPane tabId="6">
                      <ForumGroup />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(GroupDetails)
