/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { REMOVE_SOFTSKILL_ABILITY_USER } from "./actionTypes"
import {
  removeSoftskillAbilityUserError,
  removeSoftskillAbilityUserSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { removeSoftskillsAbilityUser } from "../../../helpers/backend_helper"

function* remove(action) {
  try {
    const response = yield call(
      removeSoftskillsAbilityUser,
      action.payload.form
    )
    if (response == undefined) throw new Error("API error")
    if (response.message) throw new Error(response.message)
    yield put(removeSoftskillAbilityUserSuccess(response))
  } catch (error) {
    console.log(error.message)
    yield put(removeSoftskillAbilityUserError(error.message))
  }
}

function* softskillAbilityUserRemoveSaga() {
  yield takeEvery(REMOVE_SOFTSKILL_ABILITY_USER, remove)
}

export default softskillAbilityUserRemoveSaga
