import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { Modal, Input, Row, Col, FormGroup, Label, Button } from "reactstrap"
import {
  AvField,
  AvForm,
  AvRadioGroup,
  AvRadio,
  AvFeedback,
  AvInput,
  AvGroup,
} from "availity-reactstrap-validation"
import {
  getLessons,
  addQuizzAnswer,
  addQuizzAnswerClean,
  editQuizz,
  editQuizzClean,
  editQuizzAnswer,
  editQuizzAnswerClean,
  removeQuizzAnswer,
  removeQuizzAnswerClean,
  listQuizzAnswers,
  listQuizzAnswersClean,
} from "store/actions"

const QuizzFormModal = props => {
  const dispatch = useDispatch()
  const [formError, setFormError] = useState(false)
  const [saved, setSaved] = useState(false)
  const [value, setValue] = useState(undefined)

  const {
    quizzAnswers,
    quizzAnswerAddDone,
    quizzAnswerEditDone,
    quizzAnswerRemoveDone,
    quizzEditDone,
    quizzBackend,
    whoIAm,
  } = useSelector(store => ({
    quizzAnswers: store.QuizzAnswersList.quizzAnswers,
    quizzAnswerAddDone: store.QuizzAnswerAdd.done,
    quizzAnswerEditDone: store.QuizzAnswerEdit.done,
    quizzAnswerRemoveDone: store.QuizzAnswerRemove.done,
    quizzEditDone: store.QuizzEdit.done,
    quizzBackend: store.QuizzList.quizz,
    whoIAm: store.Profile.user,
  }))

  const quizz = props.quizz
  const answers = quizzAnswers ?? []
  const quizzId = quizz !== undefined ? quizz.quizzId : 0

  useEffect(() => {
    if (quizzId !== 0) dispatch(listQuizzAnswers(quizzId, whoIAm.idUsuario))
    return () => {
      dispatch(listQuizzAnswersClean())
    }
  }, [quizzId])

  useEffect(() => {
    const correct = answers.find(answer => answer.correct)
    if (correct !== undefined) setValue(correct.quizzAnswerId)
  }, [answers])

  const changeValue = event => {
    const quizzAnswerId = parseInt(event.target.value)
    setValue(quizzAnswerId)

    const quizzAnswer = answers.find(
      answer => answer.quizzAnswerId === quizzAnswerId
    )

    const request = {
      quizzAnswerId: quizzAnswerId,
      answer: quizzAnswer.answer,
      quizzId: quizzId,
      courseId: props.course.courseId,
      correct: 1,
      userId: whoIAm.idUsuario,
    }
    dispatch(editQuizzAnswer(request))
  }

  const handleAddAnswer = () => {
    const request = {
      quizzId: quizzId,
      courseId: props.course.courseId,
      answer: `Respuesta ${answers.length + 1}`,
      correct: answers.length <= 0 ? 1 : 0,
      userId: whoIAm.idUsuario,
    }
    dispatch(addQuizzAnswer(request))
  }

  const handleUpdateAnswerName = (idQuizAnswer, answer) => {
    const request = {
      quizzAnswerId: idQuizAnswer,
      quizzId: quizzId,
      answer: answer,
      correct: value === idQuizAnswer ? 1 : 0,
      courseId: props.course.courseId,
      userId: whoIAm.idUsuario,
    }
    dispatch(editQuizzAnswer(request))
  }

  const handleRemoveAnswer = idQuizzAnswer => {
    dispatch(removeQuizzAnswer({ quizzAnswerId: idQuizzAnswer }))
  }

  const handleSubmit = async (event, errors, values) => {
    setSaved(false)
    if (errors.length > 0) {
      setFormError(true)
      return
    }
    setFormError(false)

    const request = {
      quizzId: quizz.quizzId,
      courseId: props.course.courseId,
      question: values.question,
    }
    dispatch(editQuizz(request))
  }

  if (quizzAnswerAddDone) {
    dispatch(addQuizzAnswerClean())
    dispatch(listQuizzAnswers(quizzId, whoIAm.idUsuario))
  }

  if (quizzAnswerEditDone) {
    dispatch(editQuizzAnswerClean())
    dispatch(listQuizzAnswers(quizzId, whoIAm.idUsuario))
  }

  if (quizzEditDone) {
    dispatch(editQuizzClean())
    setSaved(true)
    props.onClose()
  }

  if (quizzAnswerRemoveDone) {
    dispatch(removeQuizzAnswerClean())
    dispatch(listQuizzAnswers(quizzId, whoIAm.idUsuario))
  }

  return props.quizz === undefined ? (
    <div></div>
  ) : (
    <Modal isOpen={quizz !== undefined} backdrop={"static"} id="staticBackdrop">
      <AvForm className="form-horizontal" onSubmit={handleSubmit}>
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Quizz
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              props.onClose()
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          {formError && (
            <div className="alert alert-danger">
              Existen errores en el formulario. Por favor corrígelos para
              continuar.
            </div>
          )}
          {saved && (
            <div className="alert alert-success">
              El quizz ha sido guardado exitosamente.
            </div>
          )}
          <Row className="mb-4">
            <Col md={12}>
              <AvField
                name="question"
                label="Ingresa la pregunta (únicamente opción múltiple)"
                className="form-control"
                placeholder="Ingresar"
                value={quizz.question}
                type="text"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo es requerido",
                  },
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-end">
              <button
                type="button"
                onClick={handleAddAnswer}
                className="btn btn-link"
              >
                <i className="fa fa-plus"></i> Agregar respuesta
              </button>
            </Col>
          </Row>
          {answers !== undefined && (
            <Row>
              <Col md={answers.length < 1 ? 12 : 1}>
                <AvRadioGroup
                  name="private"
                  className="form-control noNorder"
                  onChange={changeValue}
                  value={value}
                  validate={{
                    required: {
                      value: true,
                      errorMessage:
                        "Agregue al menos una respuesta, marque la que sea correcta.",
                    },
                  }}
                >
                  {answers.map(answer => (
                    <div
                      key={`answer-${answer.quizzAnswerId}`}
                      className="mb-4"
                    >
                      <AvRadio value={answer.quizzAnswerId} />
                    </div>
                  ))}
                </AvRadioGroup>
              </Col>
              {answers.length > 0 && (
                <Col md={11}>
                  {answers.map((answer, index) => (
                    <Row
                      key={`answer-text-${answer.quizzAnswerId}`}
                      className="mb-2"
                    >
                      <Col xs={10} className="d-flex align-items-center">
                        <AvInput
                          onBlur={e =>
                            handleUpdateAnswerName(
                              answer.quizzAnswerId,
                              e.target.value
                            )
                          }
                          name={`answer-${answer.quizzAnswerId}`}
                          className="form-control"
                          placeholder={`Ingresa la respuesta ${index + 1}`}
                          value={answer.answer}
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Este campo es requerido",
                            },
                          }}
                        />
                      </Col>
                      <Col xs={2}>
                        <button
                          type="button"
                          onClick={() =>
                            handleRemoveAnswer(answer.quizzAnswerId)
                          }
                          className="btn btn-link"
                        >
                          <i className="fa fa-times"></i>
                        </button>
                      </Col>
                    </Row>
                  ))}
                </Col>
              )}
            </Row>
          )}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              props.onClose()
            }}
          >
            Regresar
          </button>
          <button type="submit" className="btn btn-secondary">
            Guardar pregunta
          </button>
        </div>
      </AvForm>
      <style jsx="true">{`
        .noNorder {
          border: 0 !important;
        }
      `}</style>
    </Modal>
  )
}

QuizzFormModal.propTypes = {
  show: PropTypes.bool,
  course: PropTypes.object,
  quizz: PropTypes.object,
  onClose: PropTypes.func,
  reload: PropTypes.func,
}

export default QuizzFormModal
