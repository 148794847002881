import CoursesTab from "components/SoftSkills/CourseUsers/CoursesTab"
import SoftskillsProfileDashboardBox from "components/SoftSkills/Users/ProfileDashboardBox"
import { Fragment, useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Card, CardBody, Table } from "reactstrap"
import {
  getSoftskillsUserCourses,
  getSoftskillsUserProgress,
  getSoftskillsUserProgressClean,
  me,
  meNew,
} from "store/actions"
import {
  getAbilityStatus,
  getIndicator,
  getProgress,
  getStatusName,
} from "./Helpers/Helpers"
import { allSoftskillsAbilitiesUsers } from "store/softskills_abilities/all_users/actions"
import SoftskillsAbilitiesUsersListTable from "components/SoftSkills/Users/SoftskillsAbilitiesUsersListTable"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { LoadingIndicator } from "components/Common/LoadingIndicator"
import AbilityProgress from "components/SoftSkills/Users/AbilityProgress"

const WelcomeSoftSkills = () => {
  const [activeTab, setActiveTab] = useState("1")
  const [coursesValidation, setCoursesValidation] = useState([])
  const dispatch = useDispatch()

  const {
    user,
    courses,
    progress,
    loading,
    userAbilities,
    userAbilitiesLoading,
  } = useSelector(store => ({
    user: store.ProfileNew.user,
    courses: store.SoftskillsUserCoursesList.courses,
    progress: store.SoftskillsUserProgressList.progress,
    loading: store.SoftskillsUserCoursesList.loading,
    userAbilities: store.SoftskillsAbilitiesAllUsers.users,
    userAbilitiesLoading: store.SoftskillsAbilitiesAllUsers.loading,
  }))

  const userId = user?.idUsuario ?? 0
  const profileId = user?.softskillsProfileId ?? 0
  const regionId = user?.softskillsRegionId ?? 0

  useEffect(() => {
    dispatch(meNew())
  }, [])

  useEffect(() => {
    dispatch(getSoftskillsUserCourses(userId))
    dispatch(getSoftskillsUserProgress(userId))
    return () => {
      dispatch(getSoftskillsUserProgressClean())
    }
  }, [userId])

  useEffect(() => {
    const coursesValidationPrev = []
    for (const userAbility of userAbilities) {
      const name = `${userAbility.firstName} ${userAbility.lastName}`
      const userRegionId = userAbility.id_region_softskills
      let courseName = ""
      let abilityName = ""
      const userId = userAbility.userId

      if (regionId !== userRegionId && profileId === 3) {
        return
      }

      for (const ability of userAbility.abilities) {
        for (const course of ability.courses) {
          let courseId = 0
          if (course.courseUser !== null) {
            if (course.courseUser.statusId === 2) {
              courseId = course.courseId
              courseName = course.courseName
              abilityName = ability.ability.abilityName
            }
          }

          if (courseId !== 0) {
            coursesValidationPrev.push({
              name,
              courseName,
              abilityName,
              courseId,
              userId,
            })
          }
        }
      }
    }
    setCoursesValidation(coursesValidationPrev)
  }, [userAbilities])

  useEffect(() => {
    if (profileId === 1) {
      dispatch(allSoftskillsAbilitiesUsers(userId))
    }
  }, [profileId])

  return (
    <Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Habilidades blandas | La Academia Fester</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col xs={12}>
                          <h3>
                            Bienvenido a la Plataforma para el seguimiento de
                            progreso de Habilidades Blandas
                          </h3>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              {user !== undefined && (
                <div className="mb-2">
                  <SoftskillsProfileDashboardBox user={user} />
                </div>
              )}
              <Card>
                <CardBody>
                  <h4 className="text-center">Avance de habilidades</h4>
                  <AbilityProgress abilityProgress={progress} />
                </CardBody>
              </Card>
            </Col>

            <Col md={6}>
              {profileId === 1 || profileId === 2 || profileId === 3 ? (
                <Card>
                  <CardBody>
                    <h4 className="text-center">
                      Usuarios para validar cursos completados
                    </h4>
                    {userAbilitiesLoading ? (
                      <div className="text-center">
                        <LoadingIndicator />
                      </div>
                    ) : (
                      <Table>
                        <thead>
                          <tr>
                            <th>Nombre</th>
                            <th>Curso</th>
                            <th>Habilidad</th>
                            <th>Validar</th>
                          </tr>
                        </thead>
                        <tbody>
                          {coursesValidation.length === 0 && (
                            <tr>
                              <td colSpan="4" className="text-center">
                                No hay cursos por validar
                              </td>
                            </tr>
                          )}
                          {coursesValidation.map((course, index) => {
                            return (
                              <tr key={`user-ability-${index}`}>
                                <td>{course.name}</td>
                                <td>{course.courseName}</td>
                                <td>{course.abilityName}</td>
                                <td>
                                  <Link
                                    to={`soft-skills/adm-courses/review/${course.userId}/${course.courseId}`}
                                    className="btn btn-primary"
                                  >
                                    Validar
                                  </Link>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    )}
                  </CardBody>
                </Card>
              ) : (
                <Card>
                  <CardBody>
                    <h4 className="text-center">
                      Avance en cursos de la habilidad en progreso
                    </h4>

                    {(courses || []).map(ability => {
                      if (ability.current === true) {
                        return ability.courses.map(course => {
                          let statusId = 0
                          if (course.courseUser !== null) {
                            statusId = course.courseUser.statusId
                          }
                          return (
                            <Row key={`course-${statusId}`}>
                              <Col md={1} className="mb-2">
                                {getIndicator(statusId)}
                              </Col>
                              <Col md={11}>
                                <p className="mb-0">{course.courseName}</p>
                                <small>{getStatusName(statusId)}</small>
                              </Col>
                            </Row>
                          )
                        })
                      }
                      return <div key={`course-${ability.abilityId}`}></div>
                    })}
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>

          {(profileId === 1 || profileId === 2 || profileId === 3) && (
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    {userAbilitiesLoading ? (
                      <div className="text-center">
                        <LoadingIndicator />
                      </div>
                    ) : (
                      <SoftskillsAbilitiesUsersListTable />
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <CoursesTab courses={courses} loading={loading} userId={userId} />
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default WelcomeSoftSkills
