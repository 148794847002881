import { Fragment, useState, useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { PropTypes } from "prop-types"
import {
  Col,
  Row,
  Media,
  Input,
  Modal,
  Table,
  Card,
  CardBody,
} from "reactstrap"
import { getBlocks } from "store/actions"

const CourseLessonsPreview = props => {
  const [modal, setModal] = useState(true)
  const [position, setPosition] = useState(0)

  const ref = useRef(null)

  const dispatch = useDispatch()

  const { blocks } = useSelector(store => ({
    blocks: store.BlocksList.blocks,
  }))

  useEffect(() => {
    if (ref.current)
      ref.current.scrollIntoView({
        behavior: "smooth",
      })
    if (position > 1) dispatch(getBlocks(props.lessons[position - 2]?.idLesson))
  }, [props.lessons, position])

  const resposibles = []
  let level = ""

  if (props.course.responsibles !== undefined) {
    props.course.responsibles.forEach(respCourse => {
      const user = props.responsibles.find(
        user => user.idUsuario === respCourse.userId
      )
      if (user !== undefined)
        resposibles.push(`${user.nombre} ${user.apellido}`)
    })
  }

  props.levels.forEach(lvl => {
    if (lvl.idLevel === props.course.courseLevelId) level = lvl.level
  })

  return (
    <Fragment>
      <div
        style={{ position: "absolute", top: -50, left: 0, right: 0 }}
        ref={ref}
      ></div>
      <Row>
        <Col md="3">
          <ul className="verti-timeline list-unstyled">
            <li
              key={`lesson-${0}`}
              className="event-list"
              style={{ cursor: "pointer" }}
              onClick={() => setPosition(0)}
            >
              <div className="event-timeline-dot">
                <i
                  className={`fa fa-circle font-size-18 ${
                    0 <= position ? "alt-celf-color" : ""
                  }`}
                />
              </div>
              <Media>
                <Media body>
                  <h3 className={0 <= position ? "alt-celf-color" : ""}>
                    Portada
                  </h3>
                </Media>
              </Media>
            </li>
            <li
              key={`lesson-${1}`}
              className="event-list"
              style={{ cursor: "pointer" }}
              onClick={() => setPosition(1)}
            >
              <div className="event-timeline-dot">
                <i
                  className={`fa fa-circle font-size-18 ${
                    1 <= position ? "alt-celf-color" : ""
                  }`}
                />
              </div>
              <Media>
                <Media body>
                  <h3 className={1 <= position ? "alt-celf-color" : ""}>
                    Acerca del curso
                  </h3>
                </Media>
              </Media>
            </li>
            {props.lessons.map((lesson, index) => (
              <li
                key={`lesson-${index + 2}`}
                className="event-list"
                style={{ cursor: "pointer" }}
                onClick={() => setPosition(index + 2)}
              >
                <div className="event-timeline-dot">
                  <i
                    className={`fa fa-circle font-size-18 ${
                      index <= position - 2 ? "alt-celf-color" : ""
                    }`}
                  />
                </div>
                <Media>
                  <Media body>
                    <h3
                      className={index <= position - 2 ? "alt-celf-color" : ""}
                    >
                      {lesson.title}
                    </h3>
                    <span>Lección {index + 2}</span>
                  </Media>
                </Media>
              </li>
            ))}
          </ul>
        </Col>
        <Col md="9">
          {position === 0 && (
            <Row>
              <Col md={12}>
                <h3>Portada</h3>
              </Col>
              <Card>
                <CardBody>
                  <img
                    className="w-100"
                    src={props.course.backgroundImage}
                    alt={props.course.courseName}
                  />
                </CardBody>
              </Card>
            </Row>
          )}
          {position === 1 && (
            <Row>
              <Col md={12}>
                <h3>Acerca de este curso</h3>
              </Col>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <Table className="table table-striped">
                      <tbody>
                        <tr>
                          <td>Categoría</td>
                          <td>
                            {props.course.knowledgeCategories
                              .map(
                                category =>
                                  category.knowledgeCategoryName?.trim() ?? ""
                              )
                              .join(", ")}
                          </td>
                        </tr>
                        <tr>
                          <td>Clave curso</td>
                          <td>{props.course.courseCode}</td>
                        </tr>
                        <tr>
                          <td>Curso previo</td>
                          <td>
                            {props.course.previousCourses.length <= 0
                              ? "Ninguno"
                              : props.course.previousCourses
                                  .map(
                                    course => course.courseName?.trim() ?? ""
                                  )
                                  .join(", ")}
                          </td>
                        </tr>
                        <tr>
                          <td>Responsable</td>
                          <td>{resposibles.join(", ")}</td>
                        </tr>
                        <tr>
                          <td>Nivel</td>
                          <td>{level}</td>
                        </tr>
                        <tr>
                          <td>Dedicación</td>
                          <td>{props.course.dedicationTime} minutos</td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <Table className="table table-striped">
                      <tbody>
                        <tr>
                          <td>Mínimo aprobatorio:</td>
                        </tr>
                        <tr>
                          <td>{props.course.minApproval} %</td>
                        </tr>
                        <tr>
                          <td>Calificación usuarios:</td>
                        </tr>
                        <tr>
                          <td>
                            <div className="text-center">
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                              <i className="far fa-star" />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {position > 1 && (
            <Row className=" border pt-3 pb-3">
              <Col>
                <h3>{props.lessons[position - 2].title}</h3>
                <hr />
                {blocks !== undefined &&
                  blocks.map((block, index) => {
                    if (block.type == "text") {
                      return (
                        <Card className="card" key={`block-${index}`}>
                          <CardBody>
                            <div
                              dangerouslySetInnerHTML={{ __html: block.value }}
                            ></div>
                          </CardBody>
                        </Card>
                      )
                    }
                  })}
              </Col>
            </Row>
          )}
          <Row className="mt-3">
            <Col className="d-flex justify-content-end">
              <button
                type="button"
                onClick={() => {
                  if (position > 0) setPosition(position - 1)
                }}
                className={`btn btn-secondary ${
                  position <= 0 ? "disabled" : ""
                }`}
              >
                <i className="fa fa-chevron-left"></i> Lección anterior
              </button>
            </Col>
            <Col className="d-flex justify-content-start">
              <button
                type="button"
                onClick={() => {
                  if (position <= props.lessons.length)
                    setPosition(position + 1)
                }}
                className={`btn btn-secondary ${
                  position > props.lessons.length ? "disabled" : ""
                }`}
              >
                Lección siguiente <i className="fa fa-chevron-right"></i>
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  )
}

CourseLessonsPreview.propTypes = {
  course: PropTypes.object,
  lessons: PropTypes.array,
  responsibles: PropTypes.array,
  levels: PropTypes.array,
}

export default CourseLessonsPreview
