import {
  GET_SOFTSKILLS_ABILITY,
  GET_SOFTSKILLS_ABILITY_SUCCESS,
  GET_SOFTSKILLS_ABILITY_CLEAN,
  GET_SOFTSKILLS_ABILITY_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  done: false,
  ability: undefined,
}

const SoftskillAbilityGet = (state = initialState, action) => {
  switch (action.type) {
    case GET_SOFTSKILLS_ABILITY:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_SOFTSKILLS_ABILITY_SUCCESS:
      state = {
        ...state,
        ability: action.payload.ability,
        loading: false,
        done: true,
      }
      break
    case GET_SOFTSKILLS_ABILITY_CLEAN:
      state = initialState
      break
    case GET_SOFTSKILLS_ABILITY_ERROR:
      state = { ...state, error: action.payload, loading: false, done: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default SoftskillAbilityGet
