/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { PROGRESS_LESSON } from "./actionTypes"
import { progressLessonError, progressLessonSuccess } from "./actions"

//Include Both Helper File with needed methods
import { participantLesson } from "../../../helpers/backend_helper"

function* start(action) {
  try {
    const response = yield call(participantLesson, action.payload.idCourse)
    if (response == undefined) {
      throw new Error("API error")
    }
    yield put(progressLessonSuccess(response))
  } catch (error) {
    yield put(progressLessonErrorerror.message)
  }
}

function* lessonProgressSaga() {
  yield takeEvery(PROGRESS_LESSON, start)
}

export default lessonProgressSaga
