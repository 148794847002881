import UsersForm from "components/Users/UsersForm"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Input,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb2"

const AddUser = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Users | Capacitación en Línea Fester</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Nuevo usuario</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  { key: "dashboard", title: "Dashboard", link: "/dashboard" },
                  { key: "users", title: "Usuarios", link: "/users" },
                  { key: "add_user", title: "Nuevo usuario" },
                ]}
              />
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <UsersForm insert={true} id={0} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddUser
