import {
  GET_SOFTSKILLS_ABILITIES,
  GET_SOFTSKILLS_ABILITIES_SUCCESS,
  GET_SOFTSKILLS_ABILITIES_ERROR,
} from "./actionTypes"

export const getSoftskillsAbilities = active => ({
  type: GET_SOFTSKILLS_ABILITIES,
  payload: { active: active == undefined },
})

export const getSoftskillsAbilitiesSuccess = abilities => {
  return {
    type: GET_SOFTSKILLS_ABILITIES_SUCCESS,
    payload: { abilities },
  }
}

export const getSoftskillsAbilitiesError = error => {
  return {
    type: GET_SOFTSKILLS_ABILITIES_ERROR,
    payload: error,
  }
}
