import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { Modal, Input, Row, Col, FormGroup, Label } from "reactstrap"
import { AvField, AvForm, AvInput } from "availity-reactstrap-validation"
import {
  getLessons,
  addQuestion,
  editQuestion,
  addQuestionClean,
  editQuestionClean,
  editAnswer,
  removeQuestion,
  addAnsswers,
} from "store/actions"
import ImageUploadModal from "components/Common/ImageUploadModal"

const OrderQuestionModal = props => {
  const idQuestionType = 3
  const dispatch = useDispatch()
  const [formError, setFormError] = useState(false)
  const [questionName, setQuestionName] = useState("")
  const [showImageUploadModal, setShowImageUploadModal] = useState(false)
  const [image, setImage] = useState("")
  const [answer1, setAnswer1] = useState(undefined)
  const [answer2, setAnswer2] = useState(undefined)
  const [answer3, setAnswer3] = useState(undefined)
  const [answer4, setAnswer4] = useState(undefined)

  const idQuestion =
    props.question !== undefined ? props.question.idQuestion : 0

  const answers = props.question !== undefined ? props.question.answers : []

  const {
    lessons,
    questionAddLoading,
    questionAddDone,
    questionAddError,
    questionEditLoading,
    questionEditDone,
    questionEditError,
  } = useSelector(store => ({
    lessons: store.LessonsList.lessons,
    questionAddLoading: store.QuestionAdd.loading,
    questionAddDone: store.QuestionAdd.done,
    questionAddError: store.QuestionAdd.error,
    questionEditLoading: store.QuestionEdit.loading,
    questionEditDone: store.QuestionEdit.done,
    questionEditError: store.QuestionEdit.error,
  }))

  useEffect(() => {
    dispatch(getLessons(props.course.courseId))
    setQuestionName(props.question !== undefined ? props.question.question : "")
    setImage(props.question !== undefined ? props.question.image : "")
    return () => {
      setFormError(false)
    }
  }, [props.question])

  useEffect(() => {
    if (answers.length > 0) {
      setAnswer1(answers[0])
      setAnswer2(answers[1])
      setAnswer3(answers[2])
      setAnswer4(answers[3])
    }
    return () => {
      setAnswer1(undefined)
      setAnswer2(undefined)
      setAnswer3(undefined)
      setAnswer4(undefined)
    }
  }, [answers])

  const handleSubmit = async (event, errors, values) => {
    if (errors.length > 0) {
      setFormError(true)
      return
    }
    setFormError(false)

    const request = {
      courseExam: props.exam.idCourseExam,
      idQuestionType: idQuestionType,
      idLessonRef: parseInt(values.idLessonRef),
      question: values.question,
      image: image,
    }

    if (props.question === undefined) dispatch(addQuestion(request))
    else {
      request.idExamQuestion = props.question.idQuestion
      dispatch(editQuestion(request))

      if (!(props.question.answers.length > 0)) {
        const requestAnswers = {
          idExamQuestion: props.question.idQuestion,
          List: [
            {
              answer: answer1.answer,
              image: "",
              correctAnswer: 1,
            },
            {
              answer: answer2.answer,
              image: "",
              correctAnswer: 2,
            },
            {
              answer: answer3.answer,
              image: "",
              correctAnswer: 3,
            },
            {
              answer: answer4.answer,
              image: "",
              correctAnswer: 4,
            },
          ],
        }
        dispatch(addAnsswers(requestAnswers))
      } else {
        dispatch(
          editAnswer({
            idQuestionAnswer: answer1.idQuestionAnswer,
            idExamQuestion: answer1.idQuestion,
            answer: answer1.answer,
            image: "",
            correctAnswer: 1,
          })
        )
        dispatch(
          editAnswer({
            idQuestionAnswer: answer2.idQuestionAnswer,
            idExamQuestion: answer2.idQuestion,
            answer: answer2.answer,
            image: "",
            correctAnswer: 2,
          })
        )
        dispatch(
          editAnswer({
            idQuestionAnswer: answer3.idQuestionAnswer,
            idExamQuestion: answer3.idQuestion,
            answer: answer3.answer,
            image: "",
            correctAnswer: 3,
          })
        )
        dispatch(
          editAnswer({
            idQuestionAnswer: answer4.idQuestionAnswer,
            idExamQuestion: answer4.idQuestion,
            answer: answer4.answer,
            image: "",
            correctAnswer: 4,
          })
        )
      }
    }
  }

  if (questionAddDone) {
    dispatch(addQuestionClean())
    props.onConfirm()
  }

  if (questionEditDone) {
    dispatch(editQuestionClean())
    props.onConfirm()
  }

  return (
    <Modal isOpen={props.show} backdrop={"static"} id="staticBackdrop">
      <AvForm className="form-horizontal" onSubmit={handleSubmit}>
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Pregunta: orden
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              if (questionName === "") {
                dispatch(removeQuestion(idQuestion))
              }
              props.onCancel()
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          {formError && (
            <div className="alert alert-danger">
              Existen errores en el formulario. Por favor corrígelos para
              continuar.
            </div>
          )}
          {questionAddError !== "" && (
            <div className="alert alert-danger">{questionAddError}</div>
          )}
          <Row className="mb-4">
            <Col md={12}>
              <AvField
                name="question"
                label="Ingresa la pregunta"
                className="form-control"
                placeholder="Ingresar"
                value={
                  props.question !== undefined ? props.question.question : ""
                }
                type="text"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo es requerido",
                  },
                }}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <a
                href={image}
                target={"_blank"}
                className={`btn btn-light btn-block ${
                  image !== "" ? "" : "disabled"
                }`}
                rel="noreferrer"
              >
                <i className="fa fa-image"></i> Ver imagen cargada
              </a>
            </Col>
            <Col>
              {image !== "" ? (
                <button
                  onClick={() => setShowImageUploadModal(true)}
                  type="button"
                  className="btn btn-light btn-block"
                >
                  <i className="fa fa-undo"></i> Cambiar imagen
                </button>
              ) : (
                <button
                  onClick={() => setShowImageUploadModal(true)}
                  type="button"
                  className="btn btn-light btn-block"
                >
                  <i className="fa fa-plus-circle"></i> Agregar imagen
                </button>
              )}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col
              xs={1}
              className="d-flex align-items-center justify-content-center"
            >
              <h4>1</h4>
            </Col>
            <Col xs={11} className="d-flex align-items-center">
              <AvInput
                name="answer1"
                className="form-control"
                placeholder="Ingresa la respuesta 1"
                value={answer1 === undefined ? "" : answer1.answer}
                onChange={e =>
                  setAnswer1({ ...answer1, answer: e.target.value })
                }
                type="text"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo es requerido",
                  },
                }}
              />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col
              xs={1}
              className="d-flex align-items-center justify-content-center"
            >
              <h4>2</h4>
            </Col>
            <Col xs={11} className="d-flex align-items-center">
              <AvInput
                name="answer2"
                className="form-control"
                placeholder="Ingresa la respuesta 2"
                value={answer2 === undefined ? "" : answer2.answer}
                onChange={e =>
                  setAnswer2({ ...answer2, answer: e.target.value })
                }
                type="text"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo es requerido",
                  },
                }}
              />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col
              xs={1}
              className="d-flex align-items-center justify-content-center"
            >
              <h4>3</h4>
            </Col>
            <Col xs={11} className="d-flex align-items-center">
              <AvInput
                name="answer3"
                className="form-control"
                placeholder="Ingresa la respuesta 3"
                value={answer3 === undefined ? "" : answer3.answer}
                onChange={e =>
                  setAnswer3({ ...answer3, answer: e.target.value })
                }
                type="text"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo es requerido",
                  },
                }}
              />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col
              xs={1}
              className="d-flex align-items-center justify-content-center"
            >
              <h4>4</h4>
            </Col>
            <Col xs={11} className="d-flex align-items-center">
              <AvInput
                name="answer4"
                className="form-control"
                placeholder="Ingresa la respuesta 4"
                value={answer4 === undefined ? "" : answer4.answer}
                onChange={e =>
                  setAnswer4({ ...answer4, answer: e.target.value })
                }
                type="text"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo es requerido",
                  },
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mt-2">
              <AvField
                name="idLessonRef"
                label="Referencia a una lección"
                className="form-control"
                placeholder=""
                type="select"
                value={
                  props.question !== undefined
                    ? props.question.idLessonReference
                    : ""
                }
                validate={{}}
              >
                <option value="">Sin referencia</option>
                {lessons.length > 0 &&
                  lessons.map(lesson => (
                    <option key={lesson.idLesson} value={lesson.idLesson}>
                      {lesson.title}
                    </option>
                  ))}
              </AvField>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              if (questionName === "") {
                dispatch(removeQuestion(idQuestion))
              }
              props.onCancel()
            }}
          >
            Cancelar
          </button>
          <button
            type="submit"
            className={`btn btn-secondary ${
              questionAddLoading || questionEditLoading ? "disabled" : ""
            }`}
          >
            {questionAddLoading || questionEditLoading
              ? "Guardando..."
              : "Guardar"}
          </button>
        </div>
      </AvForm>
      <style jsx="true">{`
        .noNorder {
          border: 0 !important;
        }
      `}</style>
      {showImageUploadModal && (
        <ImageUploadModal
          key={"true-false-question-modal"}
          onConfirm={image => {
            setImage(image)
            setShowImageUploadModal(false)
          }}
          onCancel={() => setShowImageUploadModal(false)}
          show={true}
        />
      )}
    </Modal>
  )
}

OrderQuestionModal.propTypes = {
  show: PropTypes.bool,
  question: PropTypes.object,
  exam: PropTypes.object,
  course: PropTypes.object,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default OrderQuestionModal
