/* Knowledge categories */
export const GET_COURSES = "GET_COURSES"
export const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS"
export const GET_COURSES_BY_USER = "GET_COURSES_BY_USER"
export const GET_COURSES_BY_USER_SUCCESS = "GET_COURSES_BY_USER_SUCCESS"
export const GET_COURSES_BY_USER_AND_GROUP = "GET_COURSES_BY_USER_AND_GROUP"
export const GET_COURSES_BY_USER_AND_GROUP_SUCCESS =
  "GET_COURSES_BY_USER_AND_GROUP_SUCCESS"
export const GET_COURSES_BY_USER_AND_GROUP_CLEAN =
  "GET_COURSES_BY_USER_AND_GROUP_CLEAN"
export const GET_COURSES_BY_USER_AND_GROUP_ERROR =
  "GET_COURSES_BY_USER_AND_GROUP_ERROR"
export const GET_COURSES_ERROR = "GET_COURSES_ERROR"
export const SEND_TRASH = "SEND_TRASH"
export const SEND_TRASH_SUCCESS = "SEND_TRASH_SUCCESS"
export const SEND_TRASH_ERROR = "SEND_TRASH_ERROR"
export const SEND_TRASH_CLEAN = "SEND_TRASH_CLEAN"
