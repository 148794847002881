/* eslint-disable no-undef */
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { ADD_USER } from "./actionTypes"
import { apiError, addUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import { addUser, addUserNewApi } from "../../../helpers/backend_helper"

function* add(action) {
  try {
    const response = yield call(addUserNewApi, action.payload.form)
    if (response === undefined) throw new Error("API error")
    if (response.error) throw new Error(response)
    else yield put(addUserSuccess(response))
  } catch (error) {
    yield put(apiError(error.message))
  }
}

function* userAddSaga() {
  yield takeEvery(ADD_USER, add)
}

export default userAddSaga
