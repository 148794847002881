import React from "react"
import PropTypes from "prop-types"
import ReactApexChart from "react-apexcharts"

const Report5Chart = ({ data }) => {
  let series = []
  let courses = []
  let height = 420
  const minHeight = 18
  const maxHeight = 1280

  const redondear = value => Math.round((value + Number.EPSILON) * 100) / 100

  if (data !== undefined) {
    if (data.length > minHeight) {
      height = (height / minHeight) * data.length
    }
    if (height > maxHeight) {
      height = maxHeight
    }
    data.forEach(element => {
      element.courses.map((course, j) => {
        if (
          courses.filter(object => object.clave_curso === course.clave_curso)
            .length < 1
        ) {
          courses.push(course)
        }
      })
    })

    data.forEach(element => {
      const seriesData = courses.map(course => {
        const selected = element.courses.find(
          eCourse => course.clave_curso === eCourse.clave_curso
        )
        console.log(selected)
        const value =
          selected !== undefined
            ? selected.porcentaje_avance === null
              ? 0
              : selected.porcentaje_avance
            : -1

        return {
          x: `Curso ${course.clave_curso}`,
          y: redondear(value),
        }
      })

      let name = `${element.nombre_usuario} ${element.apellido_usuario}`
      const maxNameLength = 25
      if (name.length > maxNameLength) {
        name = name.substring(0, maxNameLength) + "..."
      }

      series.push({
        name: name,
        data: seriesData,
      })
    })
  }

  const options = {
    title: {
      text: "Gráfica 5: % Avance de cursos por usuario",
    },
    chart: {
      type: "heatmap",
      stacked: !0,
      toolbar: {
        show: 1,
      },
      zoom: {
        enabled: true,
      },
    },
    plotOptions: {
      heatmap: {
        colorScale: {
          ranges: [
            { from: -1, to: -1, color: "#000000", name: "Sin asignar" },
            { from: 0, to: 0, color: "#FF0000", name: "0%" },
            { from: 1, to: 50, color: "#FF9911", name: "De 1% a 50%" },
            { from: 51, to: 99, color: "#FDED00", name: "De 51% a 99%" },
            { from: 100, to: 100, color: "#6E8B3D", name: "100%" },
          ],
        },
      },
    },
    dataLabels: {
      enabled: !1,
    },
    legend: {
      position: "top",
      fontSize: "32px",
    },
    fill: {
      opacity: 1,
    },
    yaxis: {
      labels: {
        style: {
          fontSize: "12px",
        },
      },
    },
    xaxis: {
      labels: {
        style: {
          fontSize: "12px",
        },
      },
    },
  }

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={[...series]}
        type="heatmap"
        height={height}
        className="apex-charts"
      />
    </React.Fragment>
  )
}

Report5Chart.propTypes = {
  data: PropTypes.array,
}
export default Report5Chart
