import {
  REMOVE_SOFTSKILL_ABILITY_USER,
  REMOVE_SOFTSKILL_ABILITY_USER_SUCCESS,
  REMOVE_SOFTSKILL_ABILITY_USER_CLEAN,
  REMOVE_SOFTSKILL_ABILITY_USER_ERROR,
} from "./actionTypes"

export const removeSoftskillAbilityUser = form => ({
  type: REMOVE_SOFTSKILL_ABILITY_USER,
  payload: { form },
})

export const removeSoftskillAbilityUserSuccess = ability => {
  return {
    type: REMOVE_SOFTSKILL_ABILITY_USER_SUCCESS,
    payload: { ability },
  }
}

export const removeSoftskillAbilityUserClean = () => {
  return {
    type: REMOVE_SOFTSKILL_ABILITY_USER_CLEAN,
    payload: {},
  }
}

export const removeSoftskillAbilityUserError = error => {
  return {
    type: REMOVE_SOFTSKILL_ABILITY_USER_ERROR,
    payload: error,
  }
}
