import {
  PUBLISH_SOFTSKILLS_COURSE,
  PUBLISH_SOFTSKILLS_COURSE_SUCCESS,
  PUBLISH_SOFTSKILLS_COURSE_CLEAN,
  PUBLISH_SOFTSKILLS_COURSE_ERROR,
} from "./actionTypes"

export const publishSoftskillsCourse = form => ({
  type: PUBLISH_SOFTSKILLS_COURSE,
  payload: { ...form },
})

export const publishSoftskillsCourseSuccess = course => {
  return {
    type: PUBLISH_SOFTSKILLS_COURSE_SUCCESS,
    payload: { course },
  }
}

export const publishSoftskillsCourseClean = () => {
  return {
    type: PUBLISH_SOFTSKILLS_COURSE_CLEAN,
    payload: {},
  }
}

export const publishSoftskillsCourseError = error => {
  return {
    type: PUBLISH_SOFTSKILLS_COURSE_ERROR,
    payload: error,
  }
}
