/* eslint-disable no-undef */
import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { CREATE_EVENT } from "./actionTypes"
import { createEventSuccess, createEventError } from "./actions"

//Include Both Helper File with needed methods
import { insertEvent } from "../../../helpers/backend_helper"

function* create(action) {
  try {
    const response = yield call(insertEvent, action.payload.event)
    if (response.statusCode) throw new Error(response.message)
    yield put(createEventSuccess(response))
  } catch (error) {
    yield put(createEventError(error))
  }
}

function* createEventSaga() {
  yield takeEvery(CREATE_EVENT, create)
}

export default createEventSaga
