import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Row, Container, Col, Card, CardBody, Label } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { useDispatch, useSelector } from "react-redux"
import moment from "common/moment_i18n"
import ReportBListTable from "components/Reports/ReportBListTable"
import {
  getCourses,
  listReportB,
  listReportBClean,
  getUsers,
  getGroups,
  getCustomers,
} from "store/actions"
import { LoadingIndicator } from "components/Common/LoadingIndicator"
import XlsExporter from "components/Common/XlsExporter"
import ReportFilters from "components/Reports/ReportFilters"

const ReportB = props => {
  const dispatch = useDispatch()

  const [submit, setSubmit] = useState(false)
  const [reportInfo, setReportInfo] = useState([])

  const {
    error,
    loading,
    done,
    report,
    courses,
    users,
    customers,
    groups,
    whoIAm,
  } = useSelector(state => ({
    error: state.ReportBList.error,
    loading: state.ReportBList.loading,
    report: state.ReportBList.report,
    done: state.ReportBList.done,
    courses: state.CoursesList.coursesManagement,
    users: state.UsersList.users,
    customers: state.CustomersList.customers,
    groups: state.GroupsList.groups,
    whoIAm: state.Profile.user,
  }))

  useEffect(() => {
    dispatch(getCourses())
    dispatch(getUsers())
    dispatch(getGroups())
    dispatch(getCustomers())
  }, [])

  const onSubmit = data => {
    dispatch(listReportB({ ...data, status: true, userId: whoIAm.idUsuario }))
  }

  const getXlsData = report => {
    let data = report.map((element, index) => {
      return {
        "ID Curso Participante": element.IdCursoParticipante,
        "Nombre completo": element.nombre_completo,
        Perfil: `N${element.NombreRol}`,
        "Correo electrónico": element.Email,
        "ID Curso": element.clave_curso,
        "Nombre del curso": element.nombre_curso,
        "Nombre del grupo": element.nombre_grupo,
        Aprobado: element.aprobado ? "Sí" : "No",
        Calificación: element.calificacion,
        "Veces cursado": element.veces_cursado,
        "Fecha de terminación": moment(element.fecha_finalizo_curso)
          .locale("es")
          .format("D [de] MMMM [de] YYYY [a las] HH:mm [hrs.]"),
      }
    })

    return {
      Reporte8: data,
    }
  }

  if (done) {
    setSubmit(true)
    setReportInfo(report)
    dispatch(listReportBClean())
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Reporte 8 - Cursos aprobados por usuarios | Capacitación en Línea
            Fester
          </title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Reporte 8 - Cursos aprobados por usuarios</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  {
                    key: "dashboard",
                    title: "Dashboard",
                    link: "/dashboard",
                  },
                  { key: "Reports", title: "Analítica", link: "/reports" },
                  { key: "Report8", title: "Reporte 8" },
                ]}
              />
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {error !== "" && (
                    <div className="alert alert-danger mb-3">
                      Ocurrió un error al solicitar la información.
                    </div>
                  )}
                  <ReportFilters
                    title={"Reporte 8"}
                    onSubmit={values => onSubmit(values)}
                    xlsButton={
                      <XlsExporter
                        classNames={`btn btn-light mt-2 btn-block ${
                          submit ? "" : "disabled"
                        }`}
                        data={getXlsData(reportInfo)}
                        dataName={"Reporte8"}
                        name="Reporte 8"
                        label="Exportar"
                      />
                    }
                    users={users}
                    courses={courses}
                    customers={customers}
                    groups={groups}
                  />
                  {!loading && !submit && <div style={{ height: 260 }}></div>}
                  {loading && (
                    <div className="d-flex justify-content-center">
                      <LoadingIndicator />
                    </div>
                  )}
                  {!loading && submit && (
                    <div className="mt-4">
                      <ReportBListTable loading={loading} report={reportInfo} />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ReportB
